import axios from 'axios'
import {
  ChallengeListResponse,
  ChallengeStateListResponse,
  ChallengeStep1Response,
  ChallengeStep1RequestParams,
  ChallengeStep2Response,
  ChallengeStep2RequestParams,
  ChallengeStep3ProductRequestParams,
  ChallengeStep3ProductResponse,
  ChallengeStep3ManufacturerRequestParams,
  ChallengeStep3ManufacturerResponse,
  ChallengeStep4RequestParams,
  ChallengeStep4Response,
  ChallengeUnitListResponse,
  ChallengeDetailsResponse,
  ChallengeLevelsImportParams,
  ChallengeLevelsImportResponse,
  ChallengeResultsImportParams,
  ChallengeResultsImportResponse,
  ChallengeImportTemplateReponse,
} from '../store/Challenge/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getChallengeDetails = (
  challengeId: number,
): Promise<ChallengeDetailsResponse> => {
  return axios.get(API_URL + `/api/admin/challenge/details/${challengeId}`, {
    headers: authHeader(),
  })
}

const getChallengeList = (): Promise<ChallengeListResponse> => {
  return axios.get(API_URL + '/api/admin/challenge', {
    headers: authHeader(),
  })
}

const getChallengeStateList = (): Promise<ChallengeStateListResponse> => {
  return axios.get(API_URL + '/api/admin/challenge/state', {
    headers: authHeader(),
  })
}

const getChallengeUnitList = (): Promise<ChallengeUnitListResponse> => {
  return axios.get(API_URL + '/api/admin/challenge/unit', {
    headers: authHeader(),
  })
}

const createOrUpdateChallengeStep1 = async (
  params: ChallengeStep1RequestParams,
): Promise<ChallengeStep1Response> => {
  return await axios.post(API_URL + '/api/admin/challenge', params, {
    headers: authHeader(),
  })
}

const updateChallengeStep2 = async (
  params: ChallengeStep2RequestParams,
): Promise<ChallengeStep2Response> => {
  return await axios.post(API_URL + '/api/admin/challenge/details', params, {
    headers: authHeader(),
  })
}

const updateChallengeStep3Product = async (
  params: ChallengeStep3ProductRequestParams,
): Promise<ChallengeStep3ProductResponse> => {
  return await axios.post(
    API_URL + '/api/admin/challenge/unit/product',
    params,
    {
      headers: authHeader(),
    },
  )
}

const updateChallengeStep3Manufacturer = async (
  params: ChallengeStep3ManufacturerRequestParams,
): Promise<ChallengeStep3ManufacturerResponse> => {
  return await axios.post(
    API_URL + '/api/admin/challenge/unit/manufacturer',
    params,
    {
      headers: authHeader(),
    },
  )
}

const updateChallengeStep4 = async (
  params: ChallengeStep4RequestParams,
): Promise<ChallengeStep4Response> => {
  return await axios.post(API_URL + '/api/admin/challenge/points', params, {
    headers: authHeader(),
  })
}

const importUserChallengeLevels = async (
  params: ChallengeLevelsImportParams,
): Promise<ChallengeLevelsImportResponse> => {
  return await axios.post(
    API_URL + '/api/admin/challenge/import/user-challenge-target',
    params,
    {
      headers: authHeader(),
    },
  )
}

const importUserChallengeResults = async (
  params: ChallengeResultsImportParams,
): Promise<ChallengeResultsImportResponse> => {
  return await axios.post(
    API_URL + '/api/admin/challenge/import/user-challenge-result',
    params,
    {
      headers: authHeader(),
    },
  )
}

const getChallengeImportTemplate = (
  challengeId: number,
): Promise<ChallengeImportTemplateReponse> => {
  return axios.get(API_URL + `/api/admin/challenge/template/${challengeId}`, {
    headers: authHeader(),
  })
}

const exportedObject = {
  getChallengeDetails,
  getChallengeList,
  getChallengeStateList,
  getChallengeUnitList,
  createOrUpdateChallengeStep1,
  updateChallengeStep2,
  updateChallengeStep3Product,
  updateChallengeStep3Manufacturer,
  updateChallengeStep4,
  importUserChallengeLevels,
  importUserChallengeResults,
  getChallengeImportTemplate,
}

export default exportedObject
