import React, { FunctionComponent, useEffect } from 'react'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
  List,
  Toolbar,
  Collapse,
  ListItemButton,
} from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import NoteAltIcon from '@mui/icons-material/NoteAlt'
import DashboardIcon from '@mui/icons-material/Dashboard'
import InsertChartIcon from '@mui/icons-material/InsertChart'
import ApartmentIcon from '@mui/icons-material/Apartment'
import { styled } from '@mui/material/styles'
import { handleNavigationClick } from '../../../helpers/utils'
import TcreateLogo from '../../../../src/assets/images/logos/tcreate-logo.png'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { ReactComponent as ContactIcon } from '../../../assets/images/icons/contact_icon.svg'
import PeopleIcon from '@mui/icons-material/People'
import BadgeIcon from '@mui/icons-material/Badge'
import EventNoteIcon from '@mui/icons-material/EventNote'
import SettingsIcon from '@mui/icons-material/Settings'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
// import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import { history } from '../../../helpers/history'

const drawerWidth = 240

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: '#373739',
    color: '#DCDCDC',
    fill: '#DCDCDC',
    position: 'relative',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '.active-path': {
      color: theme.colorsPalette.yellow.main,
      svg: {
        fill: theme.colorsPalette.yellow.main,
      },
    },
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
  '.tcreate-logo': {
    position: 'absolute',
    right: '93px',
  },
  '.hide-icon': {
    fill: theme.colorsPalette.yellow.main,
  },
  '.side-navbar-container': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: '20px',
    overflowX: 'hidden',
  },
  '.nav-footer-container': {
    overflow: 'hidden',
    color: theme.colorsPalette.gray.gray4,
    fontSize: '.875rem',
    '.nav-footer-txt': {
      '&_version': {
        margin: '10px 0px',
      },
      '&_www': {
        a: {
          marginLeft: '5px',
        },
      },
      a: {
        color: theme.colorsPalette.gray.gray4,
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
  '.contact-icon': {
    width: '24px',
    fill: theme.colorsPalette.orange.medium,
  },
  '.prevent-default-btn': {},
}))

type NavigationProps = {
  open: boolean
  handleDrawerClose: () => void
  currentUser: any
}

const Navigation: FunctionComponent<NavigationProps> = ({
  open,
  handleDrawerClose,
  currentUser,
}) => {
  const [currentLocation, setCurrentLocation] = React.useState<string>(
    history.location.pathname,
  )
  const [openHelp, setOpenHelp] = React.useState(false)
  const [openUsers, setOpenUsers] = React.useState(false)
  const [openConfiguration, setOpenConfiguration] = React.useState(false)

  const handleHelpClick = () => {
    setOpenHelp(!openHelp)
  }

  const handleUsersClick = () => {
    setOpenUsers(!openUsers)
  }

  const handleConfigurationClick = () => {
    setOpenConfiguration(!openConfiguration)
  }

  useEffect(() => {
    history.listen(() => {
      setCurrentLocation(history.location.pathname)
    })
  }, [])

  const renderListItems = () => {
    return (
      <div>
        <ListItem
          className={
            currentLocation === '/home' ||
            currentLocation === '/' ||
            currentLocation.startsWith('/home/')
              ? 'active-path'
              : ''
          }
          button
          onClick={() => handleNavigationClick('home')}
        >
          <ListItemIcon>
            <DashboardIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Pulpit" />
        </ListItem>
        <ListItemButton onClick={handleUsersClick}>
          <ListItemIcon>
            <PeopleIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Użytkownicy" />
          {openUsers ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openUsers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              className={
                currentLocation === '/users/ph' ||
                currentLocation.startsWith('/users/ph/')
                  ? 'active-path'
                  : ''
              }
              button
              sx={{ pl: 4 }}
              onClick={() => handleNavigationClick('users/ph')}
            >
              <ListItemIcon>
                <BadgeIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Przedstawiciele" />
            </ListItem>
            {/* <ListItem
              className={
                currentLocation === '/users/company-admins' ||
                currentLocation.startsWith('/users/company-admins/')
                  ? 'active-path'
                  : ''
              }
              button
              sx={{ pl: 4 }}
              onClick={() => handleNavigationClick('users/company-admins')}
            >
              <ListItemIcon>
                <AdminPanelSettingsIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Administracja" />
            </ListItem> */}
          </List>
        </Collapse>
        <ListItem
          className={
            currentLocation === '/challenges' ||
            currentLocation.startsWith('/challenges/')
              ? 'active-path'
              : ''
          }
          button
          onClick={() => handleNavigationClick('challenges')}
        >
          <ListItemIcon>
            <NoteAltIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Wyzwania" />
        </ListItem>
        <ListItem
          className={
            currentLocation === '/period-plans' ||
            currentLocation.startsWith('/period-plans/')
              ? 'active-path'
              : ''
          }
          button
          onClick={() => handleNavigationClick('period-plans')}
        >
          <ListItemIcon>
            <EventNoteIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Plany Periodów" />
        </ListItem>
        <ListItem
          className={
            currentLocation === '/user-plans' ||
            currentLocation.startsWith('/user-plans/') ||
            currentLocation === '/store-plans' ||
            currentLocation.startsWith('/store-plans/')
              ? 'active-path'
              : ''
          }
          button
          onClick={() => handleNavigationClick('user-plans')}
        >
          <ListItemIcon>
            <InsertChartIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Plany Użytkowników" />
        </ListItem>
        <ListItem
          className={
            currentLocation === '/stores' ||
            currentLocation.startsWith('/stores/')
              ? 'active-path'
              : ''
          }
          button
          onClick={() => handleNavigationClick('stores')}
        >
          <ListItemIcon>
            <ApartmentIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Sklepy" />
        </ListItem>
        <ListItemButton onClick={handleConfigurationClick}>
          <ListItemIcon>
            <SettingsIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Konfiguracja" />
          {openConfiguration ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openConfiguration} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              className={currentLocation === '/periods' ? 'active-path' : ''}
              button
              sx={{ pl: 4 }}
              onClick={() => handleNavigationClick('periods')}
            >
              <ListItemIcon>
                <CalendarMonthIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Periody" />
            </ListItem>
          </List>
        </Collapse>
        <ListItemButton onClick={handleHelpClick}>
          <ListItemIcon>
            <ContactSupportIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Pomoc" />
          {openHelp ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openHelp} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              component="a"
              sx={{ pl: 4 }}
              href="https://panel-mpt.salesgame.pl/docs/Panel administracyjny instrukcja v.17.02.pdf"
              target="_blank"
            >
              <ListItemIcon>
                <PictureAsPdfIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Instrukcja (.pdf)" />
            </ListItem>
          </List>
        </Collapse>
      </div>
    )
  }

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={handleDrawerClose}>
          <img className="tcreate-logo" src={TcreateLogo} alt="" />
          <ChevronLeftIcon className="hide-icon" />
        </IconButton>
      </Toolbar>
      <Divider />
      <div className="side-navbar-container">
        <List>{renderListItems()}</List>
      </div>
      <ListItem>
        <ListItemIcon>
          <ContactIcon className="contact-icon" />
        </ListItemIcon>
        <div className="nav-footer-container">
          <div className="nav-footer-txt">
            <div className="nav-footer-txt_www">
              Copyright
              <a
                href="https://tcreate.pl"
                target="_blank"
                rel="noopener noreferrer"
              >
                tcreate.pl
              </a>
            </div>
            <div className="nav-footer-txt_phone">+48 512 802 802</div>
            <div className="nav-footer-txt_mail">
              <a
                href="mailto:support@tcreate.pl"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@tcreate.pl
              </a>
            </div>
          </div>
        </div>
      </ListItem>
    </Drawer>
  )
}

export default Navigation
