import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Theme {
    colorsPalette: {
      gray: {
        gray1: string
        gray2: string
        gray3: string
        gray4: string
        gray5: string
        gray6: string
      }
      orange: {
        light: string
        medium: string
        dark: string
      }
      red: {
        main: string
      }
      yellow: {
        main: string
      }
      actionColors: {
        primary: {
          main: string
        }
      }
    }
    buttons: {
      boxShadow: string
      color: string
      fontSize: string
      borderRadius: string
      border: {
        width: string
        style: string
      }
      textTransform:
        | 'none'
        | 'capitalize'
        | 'uppercase'
        | 'lowercase'
        | 'full-width'
        | 'full-size-kana'
      backgroundColorHover: string
      primary: {
        colorDisabled: string
        backgroundColor: string
        backgroundColorHover: string
        backgroundColorDisabled: string
        preloaderColor: string
        padding: string
        borderDisabled: {
          color: string
        }
        border: {
          color: string
        }
      }
      secondary: {
        backgroundColor: string
        backgroundColorHover: string
        padding: string
        border: {
          color: string
        }
      }
    }
  }

  interface ThemeOptions {
    colorsPalette?: {
      gray?: {
        gray1?: string
        gray2?: string
        gray3?: string
        gray4?: string
        gray5?: string
        gray6?: string
      }
      orange?: {
        light?: string
        medium?: string
        dark?: string
      }
      red?: {
        main?: string
      }
      yellow?: {
        main?: string
      }
      actionColors?: {
        primary?: {
          main?: string
        }
      }
    }
    buttons?: {
      boxShadow?: string
      color?: string
      fontSize?: string
      borderRadius?: string
      border?: {
        width?: string
        style?: string
      }
      textTransform?:
        | 'none'
        | 'capitalize'
        | 'uppercase'
        | 'lowercase'
        | 'full-width'
        | 'full-size-kana'
      primary?: {
        colorDisabled?: string
        backgroundColor?: string
        backgroundColorHover?: string
        backgroundColorDisabled?: string
        preloaderColor?: string
        padding?: string
        borderDisabled?: {
          color?: string
        }
        border?: {
          color?: string
        }
      }
      secondary?: {
        backgroundColor?: string
        backgroundColorHover?: string
        padding?: string
        border?: {
          color?: string
        }
      }
    }
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#373739',
    },
    secondary: {
      main: '#DCDCDC',
    },
  },
  colorsPalette: {
    gray: {
      gray1: '#F1F1F1',
      gray2: '#EAEAEA',
      gray3: '#DCDCDC',
      gray4: '#9E9E9E',
      gray5: '#373739',
      gray6: '#191714',
    },
    orange: {
      light: '#FFEDB8',
      medium: '#FFC20F',
      dark: '#FFAD0F',
    },
    red: {
      main: '#CF0000',
    },
    yellow: {
      main: '#FFC20F',
    },
    actionColors: {
      primary: {
        main: '#33336D',
      },
    },
  },
  buttons: {
    boxShadow: 'none',
    color: '#191714',
    fontSize: '16px',
    borderRadius: '5px',
    textTransform: 'none',
    border: {
      width: '1px',
      style: 'solid',
    },
    primary: {
      colorDisabled: '#9E9E9E',
      backgroundColor: '#FFC20F',
      backgroundColorHover: '#FFAD0F',
      backgroundColorDisabled: '#DCDCDC',
      preloaderColor: '#9E9E9E',
      padding: '5px 30px',
      borderDisabled: {
        color: '#DCDCDC',
      },
      border: {
        color: '#FFC20F',
      },
    },
    secondary: {
      backgroundColor: '#FFF',
      backgroundColorHover: '#FFEDB8',
      padding: '5px 30px',
      border: {
        color: '#FFC20F',
      },
    },
  },
})
export default theme
