import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {
  ChallengeDetails,
  ChallengeStep3ManufacturerRequestParams,
  ChallengeStep3ProductRequestParams,
  ChallengeUnit,
  ManufacturerAmount,
  ProductAmount,
} from '../../../../../store/Challenge/types'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../../helpers/errorHandler'
import LoadingSpinner from '../../../../shared/LoadingSpinner'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ChallengeService from '../../../../../services/challenge.service'
import ManufacturerService from '../../../../../services/manufacturer.service'
import { validateEan } from '../../../../../helpers/utils'
import { Manufacturer } from '../../../../../store/Manufacturer/types'
import { toast } from 'react-toastify'
import { isNumber } from 'lodash'

const ChallengeContainer = styled('div')(({ theme }) => ({
  '.form-style': {
    label: {
      color: theme.colorsPalette.gray.gray6,
      fontWeight: 'bold',
      fontSize: '.75rem',
    },
    '.MuiTextField-root': {
      marginTop: '0px !important',
      marginBottom: 0,
    },
  },
  '.step3-form': {
    '&_item': {
      ':not(:last-of-type)': {
        borderRight: '1px solid',
        borderColor: theme.colorsPalette.gray.gray3,
        paddingRight: 10,
      },
      ':not(:first-of-type)': {
        paddingLeft: 10,
      },
      '&--img': {
        height: 130,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        img: {
          maxHeight: 120,
        },
      },
    },
  },
  '.margin-top-form': {
    marginTop: 20,
  },

  '.buttons-container': {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
  },
}))

type ChallengeStep3FormProps = {
  challengeId: number | null
  challengeDetails?: ChallengeDetails
  setChallengeUnitId: React.Dispatch<React.SetStateAction<number | null>>
  setIsStep3Touched: React.Dispatch<React.SetStateAction<boolean>>
}

const ChallengeStep3Form: React.FunctionComponent<ChallengeStep3FormProps> = ({
  challengeId,
  challengeDetails,
  setChallengeUnitId,
  setIsStep3Touched,
}) => {
  const PRODUCT_CODE_LIMIT = 25

  const { t } = useTranslation()
  const [loadingStep3, setLoadingStep3] = useState<boolean>(true)
  const [productsMinimum, setProductsMinimum] = useState<number>(0)
  const [units, setUnits] = useState<ChallengeUnit[]>([])
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([])
  const [activeChallengeUnit, setActiveChallengeUnit] =
    useState<ChallengeUnit | null>(null)
  const [manufacturersMinimum, setManufacturersMinimum] = useState<number>(0)
  const [savingStep3, setSavingStep3] = useState<boolean>(false)

  const emptyProduct = {
    ean: '',
    name: '',
    qty: 0,
    error: false,
  }

  const emptyManufacturer = {
    manufacturerId: 0,
    requiredAmount: 0,
    error: false,
  }

  const [products, setProducts] = useState<ProductAmount[]>([emptyProduct])
  const [manufacturersAmount, setManufacturersAmount] = useState<
    ManufacturerAmount[]
  >([emptyManufacturer])

  const resetStep3Form = () => {
    setActiveChallengeUnit(null)
    setProducts([emptyProduct])
    setManufacturersAmount([emptyManufacturer])
    setProductsMinimum(0)
    setManufacturersMinimum(0)
    setIsStep3Touched(true)
  }

  const saveStep3Form = async () => {
    let hasErrors = false
    if (
      activeChallengeUnit &&
      ['SP', 'QV'].includes(activeChallengeUnit.code)
    ) {
      const updatedProducts = products.map((product) => {
        if (
          !validateEan(product.ean) ||
          product.name.trim() === '' ||
          product.qty < 0
        ) {
          hasErrors = true
          return {
            ...product,
            error: true,
          }
        }
        return {
          ...product,
          error: false,
        }
      })
      setProducts((prevProduts) => [...updatedProducts])
      const requiredQtySum = products.reduce(function (acc, obj) {
        return acc + obj.qty
      }, 0)
      if (productsMinimum < requiredQtySum || !isNumber(productsMinimum)) {
        toast.error(t('pages.challenge.step3.wrongMinimalTotalAmount'))
      }
      if (!hasErrors && challengeId && productsMinimum >= requiredQtySum) {
        const formParams: ChallengeStep3ProductRequestParams = {
          challengeId,
          challengeUnitId: activeChallengeUnit.id,
          minimalTotalAmount: productsMinimum,
          products: products.map((p) => {
            return {
              productCode: p.ean,
              productName: p.name,
              requiredAmount: p.qty,
            }
          }),
        }

        try {
          setSavingStep3(true)
          const createOrUpdateChallengeStep3ProductResponse =
            await ChallengeService.updateChallengeStep3Product(formParams)

          if (createOrUpdateChallengeStep3ProductResponse.data.success) {
            toast.success(t('messages.success.savedSuccessfully'))
            setIsStep3Touched(false)
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setSavingStep3(false)
        }
      }
    } else if (
      activeChallengeUnit &&
      ['SM', 'AV'].includes(activeChallengeUnit.code)
    ) {
      const updatedManufacturers = manufacturersAmount.map((manufacturer) => {
        if (
          manufacturer.manufacturerId === 0 ||
          manufacturer.requiredAmount < 0
        ) {
          hasErrors = true
          return {
            ...manufacturer,
            error: true,
          }
        }
        return {
          ...manufacturer,
          error: false,
        }
      })
      setManufacturersAmount((prevState) => [...updatedManufacturers])
      const requiredQtySum = manufacturersAmount.reduce(function (acc, obj) {
        return acc + obj.requiredAmount
      }, 0)
      if (
        manufacturersMinimum < requiredQtySum ||
        !isNumber(manufacturersMinimum)
      ) {
        toast.error(t('pages.challenge.step3.wrongMinimalTotalAmount'))
      }
      if (!hasErrors && challengeId && manufacturersMinimum >= requiredQtySum) {
        const formParams: ChallengeStep3ManufacturerRequestParams = {
          challengeId,
          challengeUnitId: activeChallengeUnit.id,
          minimalTotalAmount: manufacturersMinimum,
          manufacturers: manufacturersAmount.map((m) => {
            return {
              manufacturerId: m.manufacturerId,
              requiredAmount: m.requiredAmount,
            }
          }),
        }

        try {
          setSavingStep3(true)
          const createOrUpdateChallengeStep3ManufacturerResponse =
            await ChallengeService.updateChallengeStep3Manufacturer(formParams)

          if (createOrUpdateChallengeStep3ManufacturerResponse.data.success) {
            toast.success(t('messages.success.savedSuccessfully'))
            setIsStep3Touched(false)
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setSavingStep3(false)
        }
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const unitListResponse = await ChallengeService.getChallengeUnitList()

        if (unitListResponse.data.challengeUnits) {
          setUnits(unitListResponse.data.challengeUnits)
        }

        const manufacturerListResponse =
          await ManufacturerService.getManufacturerList()

        if (manufacturerListResponse.data.manufacturers) {
          setManufacturers(manufacturerListResponse.data.manufacturers)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoadingStep3(false)
      }
    }
    fetchData()
  }, [t])

  useEffect(() => {
    // load data if update
    if (challengeDetails) {
      const activeUnit = units.find(
        (unit) => unit.id === challengeDetails.challengeUnitId,
      )
      if (activeUnit) {
        setActiveChallengeUnit(activeUnit)
        if (['SP', 'QV'].includes(activeUnit.code)) {
          setProducts(
            challengeDetails.products.map((product) => {
              return {
                ean: product.productCode,
                name: product.productName,
                qty: product.requiredAmount,
                error: false,
              }
            }),
          )
          setProductsMinimum(challengeDetails.minimalTotalAmount)
        } else if (['SM', 'AV'].includes(activeUnit.code)) {
          setManufacturersAmount(
            challengeDetails.manufacturers.map((manufacturer) => {
              return {
                manufacturerId: manufacturer.manufacturerId,
                requiredAmount: manufacturer.requiredAmount,
                error: false,
              }
            }),
          )
          setManufacturersMinimum(challengeDetails.minimalTotalAmount)
        }
      }
    }
  }, [challengeDetails, units])

  const addNewProductRow = () => {
    if (products.length < PRODUCT_CODE_LIMIT) {
      setProducts((prevProducts) => [...prevProducts, emptyProduct])
      setIsStep3Touched(true)
    }
  }

  const deleteProductRow = (rowId: number) => {
    products.splice(rowId, 1)
    setProducts((prevProducts) => [...products])
    setIsStep3Touched(true)
  }

  const handleProductEANChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowId: number,
  ) => {
    const updatedProducts = [...products]
    updatedProducts[rowId].ean = event.target.value
    setProducts(updatedProducts)
    setIsStep3Touched(true)
  }

  const handleProductQtyChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowId: number,
  ) => {
    const updatedProducts = [...products]
    updatedProducts[rowId].qty = parseInt(event.target.value)
    setProducts(updatedProducts)
    setIsStep3Touched(true)
  }

  const handleProductNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowId: number,
  ) => {
    const updatedProducts = [...products]
    updatedProducts[rowId].name = event.target.value
    setProducts(updatedProducts)
    setIsStep3Touched(true)
  }

  const handleProductsMinimumChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setProductsMinimum(parseInt(event.target.value))
    setIsStep3Touched(true)
  }

  const addNewManufacturerRow = () => {
    if (manufacturersAmount.length < 10) {
      setManufacturersAmount((prevManufacturers) => [
        ...prevManufacturers,
        emptyManufacturer,
      ])
      setIsStep3Touched(true)
    }
  }

  const deleteManufacturerRow = (rowId: number) => {
    manufacturersAmount.splice(rowId, 1)
    setManufacturersAmount((prevManufacturers) => [...manufacturersAmount])
    setIsStep3Touched(true)
  }

  const handleManufacturerNameChange = (
    event: SelectChangeEvent<string>,
    rowId: number,
  ) => {
    const updatedManufacturers = [...manufacturersAmount]
    updatedManufacturers[rowId].manufacturerId = parseInt(event.target.value)
    setManufacturersAmount(updatedManufacturers)
    setIsStep3Touched(true)
  }

  const handleManufacturerTurnoverChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowId: number,
  ) => {
    const updatedManufacturers = [...manufacturersAmount]
    updatedManufacturers[rowId].requiredAmount = parseInt(event.target.value)
    setManufacturersAmount(updatedManufacturers)
    setIsStep3Touched(true)
  }

  const handleManufacturersMinimumChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setManufacturersMinimum(parseInt(event.target.value))
    setIsStep3Touched(true)
  }

  const handleUnitChange = (event: SelectChangeEvent) => {
    const activeChallenge = units.find(
      (unit) => unit.id === parseInt(event.target.value),
    )
    if (activeChallenge) {
      setActiveChallengeUnit(activeChallenge)
      setChallengeUnitId(activeChallenge.id)
    } else {
      setActiveChallengeUnit(null)
      setChallengeUnitId(null)
    }
    setIsStep3Touched(true)
  }

  return (
    <ChallengeContainer>
      {loadingStep3 && <LoadingSpinner />}
      {!loadingStep3 && (
        <Box
          className="form-style"
          component="form"
          sx={{
            '& .MuiTextField-root': { mb: 1, mt: 1 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
          noValidate
          autoComplete="off"
        >
          <Stack flexDirection="row" alignItems="center" mb={2}>
            {t('pages.challenge.step3.selectChallengeUnit')}
            <FormControl style={{ minWidth: 360, marginLeft: 20 }}>
              <Select
                labelId="unit-select-label"
                id="unit-select"
                value={
                  activeChallengeUnit ? activeChallengeUnit.id.toString() : '0'
                }
                onChange={handleUnitChange}
                size="small"
              >
                <MenuItem value={0} disabled>
                  {t('pages.challenge.step3.select')}
                </MenuItem>
                {units.map((unit) => (
                  <MenuItem key={unit.id} value={unit.id}>
                    {unit.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Typography variant="body2" gutterBottom>
            <span
              dangerouslySetInnerHTML={{
                __html:
                  units.find((unit) => unit.id === activeChallengeUnit?.id)
                    ?.description || '',
              }}
            />
          </Typography>
          {activeChallengeUnit && (
            <Grid container spacing={2} px={4} mt={1}>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  display: ['SM', 'AV'].includes(activeChallengeUnit.code)
                    ? 'none'
                    : 'block',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="caption" fontWeight="bold">
                      {t('pages.challenge.step3.products.productsChallenge', {
                        length: PRODUCT_CODE_LIMIT,
                      })}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="caption" fontWeight="bold">
                      {t('pages.challenge.step3.products.productName')}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="caption" fontWeight="bold">
                      {t('pages.challenge.step3.products.requiredValue')}
                    </Typography>
                  </Grid>
                </Grid>
                {products.map((product, i) => (
                  <Grid container spacing={2} key={`product-${i}`} mb={1}>
                    <Grid item xs={4}>
                      <Stack direction="row" alignItems="flex-start">
                        {products.length > 1 && (
                          <IconButton
                            aria-label="delete"
                            color="error"
                            onClick={() => deleteProductRow(i)}
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        )}
                        <TextField
                          placeholder={t(
                            'pages.challenge.step3.products.formPlaceholder',
                          )}
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={product.ean}
                          onChange={(e) => handleProductEANChange(e, i)}
                          error={product.error}
                          helperText={
                            product.error &&
                            t('pages.challenge.step3.products.error')
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={product.name}
                        error={product.error}
                        onChange={(e) => handleProductNameChange(e, i)}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="number"
                        value={product.qty}
                        error={product.error}
                        onChange={(e) => handleProductQtyChange(e, i)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      {products.length - 1 === i &&
                        products.length !== PRODUCT_CODE_LIMIT && (
                          <IconButton
                            aria-label="delete"
                            color="success"
                            onClick={addNewProductRow}
                          >
                            <AddCircleOutlineIcon />
                          </IconButton>
                        )}
                    </Grid>
                  </Grid>
                ))}
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="end"
                  my={2}
                >
                  <Typography
                    display="inline-block"
                    marginRight={2}
                    fontWeight="bold"
                    variant="body2"
                  >
                    {t('pages.challenge.step3.products.minimum')}
                  </Typography>
                  <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    value={productsMinimum}
                    onChange={handleProductsMinimumChange}
                    style={{
                      marginRight: '70px',
                      marginLeft: '15px',
                      width: '180px',
                    }}
                    error={productsMinimum < 0}
                  />
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  display: ['SP', 'QV'].includes(activeChallengeUnit.code)
                    ? 'none'
                    : 'block',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    <Typography variant="caption" fontWeight="bold">
                      {t(
                        'pages.challenge.step3.manufacturers.manufacturersChallenge',
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="caption" fontWeight="bold">
                      {t('pages.challenge.step3.manufacturers.requiredValue')}
                    </Typography>
                  </Grid>
                </Grid>
                {manufacturersAmount.map((manufacturer, i) => (
                  <Grid container spacing={2} key={`manufacturer-${i}`} mb={1}>
                    <Grid item xs={7}>
                      <Stack direction="row">
                        {manufacturersAmount.length > 1 && (
                          <IconButton
                            aria-label="delete"
                            color="error"
                            onClick={() => deleteManufacturerRow(i)}
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        )}
                        <FormControl fullWidth error={manufacturer.error}>
                          <Select
                            id="period-select"
                            value={manufacturer.manufacturerId.toString()}
                            onChange={(e) => handleManufacturerNameChange(e, i)}
                            size="small"
                            fullWidth
                          >
                            <MenuItem value="0">
                              {t(
                                'pages.challenge.step3.manufacturers.formPlaceholder',
                              )}
                            </MenuItem>
                            {manufacturers.map((m) => (
                              <MenuItem value={m.id.toString()} key={m.id}>
                                {m.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {manufacturer.error && (
                            <FormHelperText>
                              {t('pages.challenge.step3.manufacturers.error')}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Stack>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="number"
                        value={manufacturer.requiredAmount}
                        error={manufacturer.error}
                        onChange={(e) => handleManufacturerTurnoverChange(e, i)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      {manufacturersAmount.length - 1 === i &&
                        manufacturersAmount.length !== 5 && (
                          <IconButton
                            aria-label="delete"
                            color="success"
                            onClick={addNewManufacturerRow}
                          >
                            <AddCircleOutlineIcon />
                          </IconButton>
                        )}
                    </Grid>
                  </Grid>
                ))}
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="end"
                  my={2}
                >
                  <Typography
                    display="inline-block"
                    marginRight={2}
                    fontWeight="bold"
                    variant="body2"
                  >
                    {t('pages.challenge.step3.manufacturers.minimum')}
                  </Typography>
                  <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    value={manufacturersMinimum}
                    onChange={handleManufacturersMinimumChange}
                    style={{
                      marginRight: '70px',
                      marginLeft: '15px',
                      width: '160px',
                    }}
                    error={manufacturersMinimum < 0}
                  />
                </Stack>
              </Grid>
            </Grid>
          )}
          <Stack
            className="buttons-container"
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            width="100%"
          >
            <SecondaryButton onClick={resetStep3Form} disabled={savingStep3}>
              {t('common.reset')}
            </SecondaryButton>
            <PrimaryButton
              onClick={saveStep3Form}
              disabled={
                !activeChallengeUnit ||
                savingStep3 ||
                !challengeId ||
                (challengeDetails && !challengeDetails?.isEditable)
              }
            >
              {t('common.save')}
            </PrimaryButton>
          </Stack>
        </Box>
      )}
    </ChallengeContainer>
  )
}

export default ChallengeStep3Form
