import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import {
  FormControl,
  Select,
  MenuItem,
  Stack,
  SelectChangeEvent,
} from '@mui/material'
import { Period } from '../../../../store/Period/types'
import { MultiSelect } from 'react-multi-select-component'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import { UserPlanResult } from '../../../../store/UserPlan/types'
import { isCompanyAdmin } from '../../../../helpers/checkRole'
import { User } from '../../../../store/Auth/types'
import { Option } from '../../../../store/types'

interface Legend extends UserPlanResult {
  visible: boolean
}

interface UserPlansToolbarProps {
  clearSearch: () => void
  onChange: (event: { target: { value: string } }) => void
  filterPeriod: (event: SelectChangeEvent) => void
  setSelectedCompanies: (companies: Option[]) => void
  togglePlanVisibility: (planId: number) => void
  legend: Legend[]
  value: string
  periodValue: string
  periods: Period[]
  companies: Option[]
  selectedCompanies: Option[]
  user: User
}

export default function UserPlansToolbar(props: UserPlansToolbarProps) {
  const { t } = useTranslation()

  const customValueRenderer = (selected: { label: any }[], _options: any) => {
    return selected.length
      ? t('multiSelect.selectedItems', { length: selected.length })
      : t('multiSelect.select')
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 15,
        marginTop: 10,
        alignItems: 'center',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormControl size="small" sx={{ marginRight: 1, minWidth: 120 }}>
          <label>{t('pages.challenge.toolbar.filters.period')}</label>
          <Select
            id="period-select"
            onChange={props.filterPeriod}
            value={props.periodValue}
            defaultValue={props.periodValue}
          >
            {props.periods.map((period) => (
              <MenuItem value={period.id} key={period.id}>
                {period.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {!isCompanyAdmin(props.user) && (
          <FormControl
            size="small"
            sx={{ minWidth: 180, marginRight: 1, zIndex: 6 }}
          >
            <label>{t('pages.challenge.step1.companies')}</label>
            <MultiSelect
              options={props.companies}
              value={props.selectedCompanies}
              onChange={props.setSelectedCompanies}
              labelledBy={t('multiSelect.labelledBy')}
              overrideStrings={{
                allItemsAreSelected: t('multiSelect.allItemsAreSelected'),
                clearSearch: t('multiSelect.clearSearch'),
                noOptions: t('multiSelect.noOptions'),
                search: t('multiSelect.search'),
                selectAll: t('multiSelect.selectAll'),
                selectAllFiltered: t('multiSelect.selectAllFiltered'),
                selectSomeItems: t('multiSelect.selectSomeItems'),
              }}
              valueRenderer={customValueRenderer}
            />
          </FormControl>
        )}
        <Stack flexDirection="row" mt={2} flexWrap="wrap">
          {props.legend.length > 0 &&
            props.legend.map((el) => (
              <Stack key={el.id} flexDirection="row" alignItems="center" mr={2}>
                <IconButton
                  onClick={(e) => {
                    localStorage.setItem(
                      `plans-tooltips-${el.id}`,
                      JSON.stringify(!el.visible),
                    )
                    props.togglePlanVisibility(el.id)
                  }}
                  size="small"
                >
                  {el.visible ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
                <div
                  style={{
                    width: '12px',
                    height: '12px',
                    backgroundColor: `${el.colorHexValue}`,
                    borderRadius: 2,
                    marginRight: 5,
                  }}
                />
                {el.name}
              </Stack>
            ))}
        </Stack>
      </div>
      <TextField
        variant="outlined"
        size="small"
        value={props.value}
        onChange={props.onChange}
        placeholder={t('pages.challenge.table.search')}
        style={{ minWidth: 180 }}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  )
}
