import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  CircularProgress,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import { errorHandler } from '../../../../helpers/errorHandler'
import { browserWarning, beforeUnloadListener } from '../../../../helpers/utils'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import PeriodService from '../../../../services/period.service'
import { toast } from 'react-toastify'
import theme from '../../../../theme'

type PeriodActivateDialogProps = {
  open: boolean
  handleClose: (refresh: boolean) => void
  name: string
}

const PeriodActivateDialog: React.FunctionComponent<
  PeriodActivateDialogProps
> = ({ open, handleClose, name }) => {
  const { t } = useTranslation()
  const [enableActivation, setEnableActivation] = useState<boolean>(false)
  const [activate, setActivate] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const handleActivateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActivate(event.target.checked)
    setEnableActivation(event.target.checked)
  }

  const resetForm = () => {
    setActivate(false)
  }

  const onSave = async () => {
    setLoading(true)
    try {
      const activatePeriodResponse = await PeriodService.activatePeriod()
      browserWarning('add', beforeUnloadListener)
      if (activatePeriodResponse.data.success) {
        toast.success(t('messages.success.savedSuccessfully'))
        resetForm()
        handleClose(true)
      } else {
        toast.error(t('messages.error.generalError'))
      }
    } catch (error) {
      errorHandler(error, t)
      setLoading(false)
    } finally {
      setEnableActivation(false)
      browserWarning('remove', beforeUnloadListener)
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return
        handleClose(false)
      }}
    >
      <DialogTitle>
        <Typography component={'span'} variant="body1" fontWeight="bold">
          {t('pages.periods.periodActivateDialog.title')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} sx={{ whiteSpace: 'pre-line', my: 2 }}>
            {t('pages.periods.periodActivateDialog.activateDescription')}
          </Grid>
          <Grid item xs={12} sx={{ whiteSpace: 'pre-line', my: 2 }}>
            <strong>
              {t('pages.periods.periodActivateDialog.activateWarning')}
            </strong>
          </Grid>
        </Grid>
        <DialogContentText>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={activate} onChange={handleActivateChange} />
              }
              label={
                t('pages.periods.periodActivateDialog.activateLabel') + name
              }
            />
          </FormGroup>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack flexDirection="row" justifyContent="space-between" width="100%">
          <SecondaryButton
            onClick={() => {
              resetForm()
              handleClose(false)
            }}
          >
            {t('common.cancel')}
          </SecondaryButton>
          <PrimaryButton
            onClick={onSave}
            disabled={!enableActivation || loading}
          >
            {t('common.activate')}
            {loading && (
              <CircularProgress
                size={18}
                style={{
                  marginLeft: '1rem',
                  color: theme.buttons.primary.preloaderColor,
                }}
              />
            )}
          </PrimaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default PeriodActivateDialog
