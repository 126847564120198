import axios from 'axios'
import {
  AccountOperationListResponse,
} from '../store/AccountOperation/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getAccountOperationList = (): Promise<AccountOperationListResponse> => {
  return axios.get(API_URL + '/api/admin/account-operation', {
    headers: authHeader(),
  })
}

const exportedObject = {
  getAccountOperationList,
}

export default exportedObject
