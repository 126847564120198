import axios from 'axios'

const apiUrl = process.env.REACT_APP_API_URL

const login = async (username: string, password: string) => {
  const response = await axios.post(apiUrl + '/game/login', {
    username,
    password,
    appCode: 'app2',
  })
  if (response.data.token) {
    localStorage.setItem('user', JSON.stringify(response.data))
  }
  return response.data
}

const logout = () => {
  localStorage.removeItem('user')
}

const refreshToken = async (token: string) => {
  const response = await axios.post(apiUrl + '/game/refresh', {
    token,
  })
  if (response.data.token) {
    localStorage.setItem('user', JSON.stringify(response.data))
  }
  return response.data
}

const exportedObject = {
  login,
  logout,
  refreshToken,
}

export default exportedObject
