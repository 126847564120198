import axios from 'axios'
import {
  ConfirmStorePlanValuesParams,
  ConfirmStorePlanValuesResponse,
  ImportAcceptPlanResponse,
  ImportPreparePlanParams,
  ImportPreparePlanResponse,
  StorePlanEditResponse,
  StorePlansResponse,
} from '../store/StorePlan/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const importPreparePlan = async (
  params: ImportPreparePlanParams,
): Promise<ImportPreparePlanResponse> => {
  return await axios.post(
    API_URL + '/api/admin/store-plan/import/prepare-plan',
    params,
    {
      headers: authHeader(),
    },
  )
}

const importAcceptPlan = async (
  planId: number,
  companyId: number | null,
): Promise<ImportAcceptPlanResponse> => {
  return await axios.post(
    API_URL + '/api/admin/store-plan/import/accept-plan',
    {
      planId,
      companyId,
    },
    {
      headers: authHeader(),
    },
  )
}

const getStorePlans = async (
  periodId: number,
  userId: number,
): Promise<StorePlansResponse> => {
  return await axios.post(
    API_URL + '/api/admin/store-plan/summary/overall',
    {
      periodId,
      userId,
    },
    {
      headers: authHeader(),
    },
  )
}

const getPlanValuesByStoreIdAndPeriodId = async (
  periodId: number,
  storeId: number,
): Promise<StorePlanEditResponse> => {
  return await axios.post(
    API_URL + '/api/admin/store-plan/plan/edit',
    {
      periodId,
      storeId,
    },
    {
      headers: authHeader(),
    },
  )
}

const confirmPlanValuesByStoreIdAndPeriodId = async (
  params: ConfirmStorePlanValuesParams,
): Promise<ConfirmStorePlanValuesResponse> => {
  return await axios.post(
    API_URL + '/api/admin/store-plan/plan/confirm',
    params,
    {
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  importPreparePlan,
  importAcceptPlan,
  getStorePlans,
  getPlanValuesByStoreIdAndPeriodId,
  confirmPlanValuesByStoreIdAndPeriodId,
}

export default exportedObject
