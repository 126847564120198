import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Typography,
  Grid,
  IconButton,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { errorHandler } from '../../../../helpers/errorHandler'
import ClearIcon from '@mui/icons-material/Clear'
import PeriodPlanService from '../../../../services/periodPlan.service'
import { PeriodPlan } from '../../../../store/PeriodPlan/types'

type PeriodPlanDetailsDialogProps = {
  planId: number
  open: boolean
  handleClose: (refresh: boolean) => void
}

const PeriodPlanDetailsDialog: React.FunctionComponent<PeriodPlanDetailsDialogProps> = ({
  planId,
  open,
  handleClose,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [periodPlanDetails, setPeriodPlanDetails] = useState<PeriodPlan | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const periodPlanDetailsResponse = await PeriodPlanService.getPeriodPlanDetails(planId)
        setPeriodPlanDetails(periodPlanDetailsResponse.data)
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (open) {
      fetchData()
    }
  }, [open, t, planId])

  const renderUserDetailsRow = (
    label: string,
    value: JSX.Element | JSX.Element[] | string,
  ) => {
    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={4} textAlign="right">
          <Typography
            variant="body2"
            gutterBottom
            fontWeight="bold"
            style={{ wordBreak: 'break-all' }}
          >
            {label}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2" gutterBottom>
            {value}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleClose(false)}
    >
      {loading && <LoadingSpinner />}
      {!loading && periodPlanDetails && (
        <>
          <DialogTitle style={{ height: '90px' }}>
            <Stack flexDirection="row">
              <Stack justifyContent="center" style={{ marginLeft: '15px' }}>
                <Typography variant="caption">
                  {t('pages.periodPlans.periodPlanDetailsDialog.planId')}: {planId}
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  component="div"
                >
                  {periodPlanDetails.planName}
                </Typography>
              </Stack>
              <IconButton
                style={{ marginLeft: 'auto', height: '50px', width: '50px' }}
                onClick={() => handleClose(false)}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {renderUserDetailsRow(
                t('pages.periodPlans.periodPlanDetailsDialog.periodName'),
                periodPlanDetails.periodName,
              )}
              {renderUserDetailsRow(
                t('pages.periodPlans.periodPlanDetailsDialog.validFrom'),
                periodPlanDetails.validFrom,
              )}
              {renderUserDetailsRow(
                t('pages.periodPlans.periodPlanDetailsDialog.validTo'),
                periodPlanDetails.validTo,
              )}
              {renderUserDetailsRow(
                t('pages.periodPlans.periodPlanDetailsDialog.xpStrategyDescription'),
                periodPlanDetails.xpStrategyDescription ? periodPlanDetails.xpStrategyDescription : '',
              )}
              {renderUserDetailsRow(
                t('pages.periodPlans.periodPlanDetailsDialog.xpStrategyDescription'),
                periodPlanDetails.vpStrategyDescription ? periodPlanDetails.vpStrategyDescription : '',
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack flexDirection="row" justifyContent="flex-end" width="100%">
              <SecondaryButton onClick={() => handleClose(false)}>
                {t('common.close')}
              </SecondaryButton>
            </Stack>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default PeriodPlanDetailsDialog
