import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { Alert, Stack, AlertColor, Typography } from '@mui/material'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'
import { errorHandler } from '../../../../../helpers/errorHandler'
import * as XLSX from 'xlsx'
import { toast } from 'react-toastify'
import {
  ImportPrepareResultParams,
  UserPlanImportData,
} from '../../../../../store/UserPlan/types'
import PeriodPlanService from '../../../../../services/userPlan.service'

const { read, utils } = XLSX

const Input = styled('input')({
  display: 'none',
})

type PeriodPlanImportResultStep1Props = {
  plan: string
  handleSetAcceptMessage: (acceptMessage: {
    type: AlertColor
    message: string
  }) => void
  handleSetSkippedStores: (skippedStores: { storeCentralId: string }[]) => void
  handleSetAddedStores: (addedStores: { storeCentralId: string }[]) => void
}

const PeriodPlanImportResultStep1: React.FunctionComponent<PeriodPlanImportResultStep1Props> =
  ({
    plan,
    handleSetAcceptMessage,
    handleSetSkippedStores,
    handleSetAddedStores,
  }) => {
    const { t } = useTranslation()
    const [savingImport, setSavingImport] = useState<boolean>(false)
    const [importPrepareResultParams, setImportPrepareResultParams] =
      useState<ImportPrepareResultParams | null>(null)
    const [alertOpen, setAlertOpen] = useState<boolean>(true)
    const [alertData, setAlertData] = useState({
      type: 'error' as AlertColor,
      message: '',
    })
    const [realizationSum, setRealizationSum] = useState<number>(0)
    const [dataObj, setDataObj] = useState<UserPlanImportData[]>([])
    const [file, setFile] = useState<File | null>(null)

    const uploadInputRef = useRef<HTMLInputElement>(null)

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()

      if (e && e.target && e.target.files && e.target.files.length > 0) {
        var files = e.target.files,
          f = files[0]
        setFile(f)
        var reader = new FileReader()
        reader.onload = function (e) {
          if (e && e.target) {
            var data = e.target.result
            let readedData = read(data, { type: 'binary' })
            const wsname = readedData.SheetNames[0]
            const ws = readedData.Sheets[wsname]

            /* Convert array to json*/
            const dataParse = utils.sheet_to_json<UserPlanImportData>(ws, {
              blankrows: false,
              defval: null,
              header: ['storeCentralId', 'realization'],
            })
            setDataObj(dataParse)
          }
        }
        reader.readAsBinaryString(f)
        e.target.value = ''
      }
    }

    const saveImport = async () => {
      if (importPrepareResultParams) {
        try {
          setSavingImport(true)
          const importResponse = await PeriodPlanService.importPrepareResult(
            importPrepareResultParams,
          )

          if (importResponse.data.success) {
            if (
              (importResponse.data.addedStoresAmount ||
                importResponse.data.addedStoresAmount === 0) &&
              (importResponse.data.skippedStoresAmount ||
                importResponse.data.skippedStoresAmount === 0) &&
              (importResponse.data.planRealizationSum ||
                importResponse.data.planRealizationSum === 0)
            ) {
              const addedStoresAmount = importResponse.data.addedStoresAmount
              const skippedStoresAmount =
                importResponse.data.skippedStoresAmount
              const storesSum = addedStoresAmount + skippedStoresAmount
              const skippedStoresPercent =
                Math.round(
                  ((skippedStoresAmount * 100) / storesSum + Number.EPSILON) *
                    100,
                ) / 100

              const addedRealizationAmount =
                importResponse.data.planRealizationSum
              const skippedRealizationAmount =
                realizationSum - addedRealizationAmount
              const skippedRealizationPercent =
                Math.round(
                  ((skippedRealizationAmount * 100) / realizationSum +
                    Number.EPSILON) *
                    100,
                ) / 100

              handleSetAcceptMessage({
                type: 'warning',
                message: t(
                  'pages.userPlanImport.messages.beforeAcceptMessage',
                  {
                    addedStoresAmount: addedStoresAmount.toLocaleString(),
                    storesSum: storesSum.toLocaleString(),
                    skippedStoresPercent: skippedStoresPercent,
                    addedRealizationAmount:
                      addedRealizationAmount.toLocaleString(),
                    realizationSum: realizationSum.toLocaleString(),
                    skippedRealizationPercent: skippedRealizationPercent,
                  },
                ),
              })
            }
            if (importResponse.data.skippedStores) {
              handleSetSkippedStores(
                importResponse.data.skippedStores.map((store) => {
                  return {
                    storeCentralId: store,
                  }
                }),
              )
            }
            if (importResponse.data.addedStores) {
              handleSetAddedStores(
                importResponse.data.addedStores.map((store) => {
                  return {
                    storeCentralId: store,
                  }
                }),
              )
            }
          } else {
            toast.error(t('messages.error.generalError'))
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setImportPrepareResultParams(null)
          setSavingImport(false)
        }
      }
    }

    useEffect(() => {
      setAlertOpen(false)
      setFile(null)
      setDataObj([])
    }, [plan])

    useEffect(() => {
      let userPlanImportData: UserPlanImportData[] = []
      var BreakException = {}
      let fileError = {
        type: 'info' as AlertColor,
        message: '',
      }

      setAlertOpen(false)
      setAlertData(fileError)
      setImportPrepareResultParams(null)

      try {
        let sum = 0
        dataObj.forEach((element) => {
          sum += Math.round(element.realization)
          userPlanImportData.push({
            storeCentralId: element.storeCentralId.toString(),
            realization: element.realization,
          })
        })
        setRealizationSum(sum)
      } catch (e) {
        if (e !== BreakException) {
          throw e
        } else {
          userPlanImportData = []
        }
      } finally {
        if (userPlanImportData.length > 0 && plan) {
          setAlertData({
            type: 'info' as AlertColor,
            message: t('pages.userPlanImport.messages.fileReadyToImport', {
              length: userPlanImportData.length,
              realization: realizationSum.toLocaleString(),
            }),
          })
          setImportPrepareResultParams({
            planId: parseInt(plan),
            data: userPlanImportData,
          })
          setAlertOpen(true)
        }
      }
    }, [dataObj, plan, t, realizationSum, file])

    return (
      <>
        {plan && (
          <>
            <Input
              ref={uploadInputRef}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
              id="contained-button-file"
              type="file"
              onChange={onFileChange}
            />
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-start"
            >
              <label htmlFor="contained-button-file">
                <SecondaryButton
                  onClick={() =>
                    uploadInputRef.current && uploadInputRef.current.click()
                  }
                  size="small"
                  style={{ padding: '5px 10px' }}
                >
                  {file
                    ? t('pages.challenge.import.changeFile')
                    : t('pages.challenge.import.selectFile')}
                </SecondaryButton>
              </label>
              {file && (
                <Typography variant="caption" ml={1}>
                  {file.name}
                </Typography>
              )}
            </Stack>
            {alertOpen && (
              <Alert
                severity={alertData.type}
                sx={{ my: 2 }}
                style={{ whiteSpace: 'pre-line' }}
              >
                {alertData.message}
              </Alert>
            )}
            {importPrepareResultParams && (
              <Stack
                className="buttons-container"
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                width="100%"
              >
                <PrimaryButton
                  onClick={saveImport}
                  disabled={savingImport || !plan}
                >
                  {t('common.import')}
                </PrimaryButton>
              </Stack>
            )}
          </>
        )}
      </>
    )
  }

export default PeriodPlanImportResultStep1
