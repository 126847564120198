import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
  FormHelperText,
} from '@mui/material'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { errorHandler } from '../../../../helpers/errorHandler'
import {
  User,
  UserChangePointsErrors,
  UserChangePointsParams,
} from '../../../../store/User/types'
import { Period } from '../../../../store/Period/types'
import PeriodService from '../../../../services/period.service'
import AccountOperationService from '../../../../services/accountOperation.service'
import { AccountOperation } from '../../../../store/AccountOperation/types'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { FormError } from '../../../../store/types'
import { thousandsSeparator } from '../../../../helpers/utils'
import UserService from '../../../../services/user.service'
import { green, red } from '@mui/material/colors'
import { toast } from 'react-toastify'

type UserChangePointsDialogProps = {
  users: Pick<User, 'userId'>[]
  open: boolean
  handleClose: (refresh: boolean) => void
}

const UserChangePointsDialog: React.FunctionComponent<
  UserChangePointsDialogProps
> = ({ users, open, handleClose }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [saving, setSaving] = useState<boolean>(false)
  const [periodList, setPeriodList] = useState<Period[]>([])
  const [period, setPeriod] = useState<string>('')
  const [accountOperationList, setAccountOperationList] = useState<
    AccountOperation[]
  >([])
  const [accountOperation, setAccountOperation] = useState<string>('')
  const [value, setValue] = useState<number | null>(null)
  const [name, setName] = useState<string | null>(null)
  const [currentStep, setCurrentStep] = useState<number>(1)

  // errors
  const defaultError: FormError = {
    error: false,
    message: '',
  }
  const defaultFormErrors: UserChangePointsErrors = {
    accountOperation: defaultError,
    period: defaultError,
    value: defaultError,
    name: defaultError,
  }
  const [formErrors, setFormErrors] =
    useState<UserChangePointsErrors>(defaultFormErrors)

  const handlePeriodChange = async (event: SelectChangeEvent) => {
    setPeriod(event.target.value as string)
  }

  const handleAccountOperationChange = async (event: SelectChangeEvent) => {
    setAccountOperation(event.target.value as string)
  }

  const handleValueChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setValue(parseInt(event.target.value))
  }

  const handleNameChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const resetForm = () => {
    setCurrentStep(1)
    setFormErrors(defaultFormErrors)
    setPeriod('')
    setAccountOperation('')
    setValue(null)
    setName(null)
  }

  const onNext = () => {
    setFormErrors(defaultFormErrors)
    const changePointsErrors = defaultFormErrors

    if (accountOperation === '') {
      setFormErrors({
        ...changePointsErrors,
        accountOperation: {
          error: true,
          message: t(
            'pages.users.userChangePointsDialog.errors.accountOperationRequired',
          ),
        },
      })
      return
    } else if (period === '') {
      setFormErrors({
        ...changePointsErrors,
        period: {
          error: true,
          message: t(
            'pages.users.userChangePointsDialog.errors.periodRequired',
          ),
        },
      })
      return
    } else if (value === null || (value && value < 0) || value === 0) {
      setFormErrors({
        ...changePointsErrors,
        value: {
          error: true,
          message: t('pages.users.userChangePointsDialog.errors.valueRequired'),
        },
      })
      return
    } else if (name === null || (name && name.trim() === '')) {
      setFormErrors({
        ...changePointsErrors,
        name: {
          error: true,
          message: t('pages.users.userChangePointsDialog.errors.nameRequired'),
        },
      })
      return
    }
    setCurrentStep(2)
  }

  const onConfirm = async () => {
    if (name && value) {
      const formParams: UserChangePointsParams = {
        users,
        name,
        accountOperationTypeId: parseInt(accountOperation),
        operationValue: value,
        periodId: parseInt(period),
      }

      try {
        setSaving(true)
        const changePointsResponse = await UserService.userChangePoints(
          formParams,
        )

        if (changePointsResponse.data.success) {
          toast.success(t('messages.success.savedSuccessfully'))
          resetForm()
          handleClose(true)
        } else {
          toast.error(t('messages.error.generalError'))
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setSaving(false)
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const periodListResponse = await PeriodService.getPeriodList()

        if (periodListResponse.data.periodList) {
          setPeriodList(periodListResponse.data.periodList)
        }

        const accountOperationListResponse =
          await AccountOperationService.getAccountOperationList()

        if (accountOperationListResponse.data.accountOperationList) {
          setAccountOperationList(
            accountOperationListResponse.data.accountOperationList,
          )
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (open) {
      fetchData()
    }
  }, [open, t])

  const renderUserIds = (users: Pick<User, 'userId'>[]) =>
    users.map((user) => user.userId).join(', ')

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return
        handleClose(false)
      }}
    >
      {loading && <LoadingSpinner />}
      {!loading && currentStep === 1 && (
        <>
          <DialogTitle>
            <Typography variant="body1" fontWeight="bold">
              {t('pages.users.userChangePointsDialog.changePoints')}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormControl
                size="small"
                fullWidth
                margin="dense"
                error={formErrors.accountOperation.error}
              >
                <label>
                  {t('pages.users.userChangePointsDialog.selectOperationType')}
                </label>
                <Select
                  id="account-operation-select"
                  value={accountOperation}
                  onChange={handleAccountOperationChange}
                  style={{ background: '#fff' }}
                >
                  {accountOperationList.map((accountOperation) => (
                    <MenuItem
                      value={accountOperation.id}
                      key={accountOperation.id}
                    >
                      {accountOperation.name}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.accountOperation.error && (
                  <FormHelperText>
                    {formErrors.accountOperation.message}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl
                size="small"
                fullWidth
                margin="dense"
                error={formErrors.period.error}
              >
                <label>
                  {t('pages.users.userChangePointsDialog.selectPeriod')}
                </label>
                <Select
                  id="period-select"
                  value={period}
                  onChange={handlePeriodChange}
                  style={{ background: '#fff' }}
                >
                  {periodList.map((period) => (
                    <MenuItem value={period.id} key={period.id}>
                      {period.name}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.period.error && (
                  <FormHelperText>{formErrors.period.message}</FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth margin="dense">
                <label>
                  {t('pages.users.userChangePointsDialog.pointsAmount')}
                </label>
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  name="value"
                  value={value || 0}
                  onChange={handleValueChange}
                  error={formErrors.value.error}
                  helperText={
                    formErrors.value.error && formErrors.value.message
                  }
                />
              </FormControl>
              <FormControl fullWidth margin="dense">
                <label>{t('pages.users.userChangePointsDialog.title')}</label>
                <TextField
                  type="text"
                  variant="outlined"
                  size="small"
                  name="title"
                  value={name || ''}
                  onChange={handleNameChange}
                  inputProps={{ maxLength: 50 }}
                  error={formErrors.name.error}
                  helperText={formErrors.name.error && formErrors.name.message}
                />
              </FormControl>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              <SecondaryButton
                onClick={() => {
                  resetForm()
                  handleClose(false)
                }}
              >
                {t('common.cancel')}
              </SecondaryButton>
              <PrimaryButton onClick={onNext} disabled={saving || loading}>
                {t('pages.users.userChangePointsDialog.next')}
              </PrimaryButton>
            </Stack>
          </DialogActions>
        </>
      )}
      {!loading && currentStep === 2 && (
        <>
          <DialogTitle>
            <Typography variant="body1" fontWeight="bold">
              {t('pages.users.userChangePointsDialog.summary')}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant="body1" gutterBottom>
                {[1, 3].includes(parseInt(accountOperation)) ? (
                  <span style={{ color: green[500] }}>
                    {t('pages.users.userChangePointsDialog.youAdd')}:{' '}
                  </span>
                ) : (
                  <span style={{ color: red[500] }}>
                    {t('pages.users.userChangePointsDialog.youSubtract')}:{' '}
                  </span>
                )}
                <strong>
                  {thousandsSeparator(value || 0)}{' '}
                  {[1, 2].includes(parseInt(accountOperation))
                    ? t('pages.users.userChangePointsDialog.xp')
                    : t('pages.users.userChangePointsDialog.vp')}
                </strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                {t('pages.users.userChangePointsDialog.forPeriod')}:{' '}
                <strong>
                  {periodList.find((p) => p.id === parseInt(period))?.name}
                </strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                {t('pages.users.userChangePointsDialog.withTitle')}:{' '}
                <strong>{name}</strong>
              </Typography>
              <Typography variant="body1">
                {t('pages.users.userChangePointsDialog.forUsers', {
                  length: users.length,
                })}
                : {renderUserIds(users)}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              <SecondaryButton onClick={() => setCurrentStep(1)}>
                {t('pages.users.userChangePointsDialog.back')}
              </SecondaryButton>
              <PrimaryButton onClick={onConfirm} disabled={saving || loading}>
                {t('pages.users.userChangePointsDialog.confirm')}
              </PrimaryButton>
            </Stack>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default UserChangePointsDialog
