import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Button,
  CircularProgress,
  IconButton,
  SelectChangeEvent,
  Stack,
} from '@mui/material'
import CompanyService from '../../../../services/company.service'
import UserService from '../../../../services/user.service'
import { useTranslation } from 'react-i18next'
import UsersToolbar from '../partials/UsersToolbar'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import * as XLSX from 'xlsx'
import { errorHandler } from '../../../../helpers/errorHandler'
import { User } from '../../../../store/Auth/types'
import { User as UserDetails } from '../../../../store/User/types'
import {
  CellProps,
  Column,
  ColumnInstance,
  HeaderProps,
  Row,
} from 'react-table'
import TableControlled from '../../../Table/TableControlled'
import { pick } from 'lodash'
import {
  getHiddenColumns,
  getTableState,
  setTableState,
} from '../../../../helpers/utils'
import { Option } from '../../../../store/types'
import { green, red } from '@mui/material/colors'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import UserAddDialog from '../partials/UserAddDialog'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import UserEditDialog from '../partials/UserEditDialog'
import UserDetailsDialog from '../partials/UserDetailsDialog'
import IndeterminateCheckbox from '../../../Table/IndeterminateCheckbox'
// import LockIcon from '@mui/icons-material/Lock'
// import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import moment from 'moment'
import UserChangePointsDialog from '../partials/UserChangePointsDialog'
import { isCentralAdmin, isSuperAdmin } from '../../../../helpers/checkRole'

type UsersListProps = {
  user: User
  type: string
}

const UsersList: FunctionComponent<UsersListProps> = ({ user, type }) => {
  const tableName = `users${type.toLowerCase()}`

  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [filteredUsersList, setFilteredUsersList] = useState<UserDetails[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchText, setSearchText] = useState<string>('')

  const [statusValue, setStatusValue] = useState<string>('all')
  const [companies, setCompanies] = useState<Option[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<Option[]>([])
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])

  const [pageCount, setPageCount] = useState(0)
  const [controlledPageIndex, setControlledPageIndex] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [skipPageReset, setSkipPageReset] = useState(true)
  const [isDownloading, setIsDownloading] = useState(false)
  const fetchIdRef = useRef(0)

  const [downloadSortBy, setDownloadSortBy] = useState<string>('')
  const [downloadSortOrder, setDownloadSortOrder] = useState<string>('')
  const [columnsVisibility, setColumnsVisibility] = useState<
    ColumnInstance<object>[]
  >([])
  const [selectedRows, setSelectedRows] = useState<Row<object>[]>([])
  const [selectedUserIds, setSelectedUserIds] = useState<
    Pick<UserDetails, 'userId'>[]
  >([])

  const [openUserAddDialog, setUserAddDialogOpen] = useState(false)
  const [openUserEditDialog, setUserEditDialogOpen] = useState(false)
  const [openUserDetailsDialog, setUserDetailsDialogOpen] = useState(false)
  const [openUserChangePointsDialog, setUserChangePointsDialogOpen] =
    useState(false)
  const [userId, setUserId] = useState<number | null>(null)
  const [refresh, setRefresh] = useState(false)

  const [lastLoginDateFrom, setLastLoginDateFrom] = useState<Date | null>(null)
  const [lastLoginTimeFrom, setLastLoginTimeFrom] = useState<Date | null>(
    new Date(new Date().setHours(0, 0, 0, 0)),
  )
  const [lastLoginDateTo, setLastLoginDateTo] = useState<Date | null>(null)
  const [lastLoginTimeTo, setLastLoginTimeTo] = useState<Date | null>(
    new Date(new Date().setHours(23, 59, 59, 0)),
  )

  const generateTableColumns = useCallback(
    (users: UserDetails[]) => {
      const columns = []
      columns.push(
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<any>) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          width: 40,
          Cell: ({ row }: CellProps<any>) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          sticky: 'left',
        },
        {
          Header: t('pages.users.table.userId').toString(),
          accessor: 'userId',
          width: 80,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.users.table.userCentralId').toString(),
          accessor: 'userCentralId',
          width: 110,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'userFirstname',
          Header: t('pages.users.table.userFirstname').toString(),
          width: 130,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'userLastname',
          Header: t('pages.users.table.userLastname').toString(),
          width: 140,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'userEmail',
          Header: t('pages.users.table.userEmail').toString(),
          width: 270,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'userPhoneNumber',
          Header: t('pages.users.table.userPhoneNumber').toString(),
          width: 140,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'userNameCompanies',
          Header: t('pages.users.table.userNameCompanies').toString(),
          width: 170,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'userIsActive',
          Header: t('pages.users.table.userIsActive').toString(),
          width: 90,
          Cell: (params: any) =>
            params.value ? (
              <span style={{ color: green[500] }}>
                {t('pages.users.status.active')}
              </span>
            ) : (
              <span style={{ color: red[500] }}>
                {t('pages.users.status.inactive')}
              </span>
            ),
        },

        {
          accessor: 'userLastLoginDate',
          Header: t('pages.users.table.userLastLoginDate').toString(),
          width: 170,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'edit',
          Header: t('pages.users.table.actions').toString(),
          width: 70,
          disableSortBy: true,
          sticky: 'right',
          Cell: (params: any) => (
            <>
              {type === 'PH' && (
                <>
                  <IconButton
                    onClick={() =>
                      handleUserDetailsDialogClickOpen(
                        params.row.original.userId,
                      )
                    }
                    size="small"
                    style={{ padding: 0, marginRight: '5px' }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      handleUserEditDialogClickOpen(params.row.original.userId)
                    }
                    size="small"
                    style={{ padding: 0 }}
                  >
                    <EditIcon />
                  </IconButton>
                </>
              )}
            </>
          ),
        },
      )
      if (type === 'PH') {
        columns.splice(2, 0, {
          Header: t('pages.users.table.userInternalId').toString(),
          accessor: 'userInternalId',
          width: 140,
          Cell: (params: any) => params.value,
        })
        columns.splice(9, 0, {
          accessor: 'userRegionName',
          Header: t('pages.users.table.userRegionName').toString(),
          width: 180,
          Cell: (params: any) => params.value,
        })
        columns.splice(10, 0, {
          accessor: 'userBankAccount',
          Header: t('pages.users.table.userBankAccount').toString(),
          width: 230,
          Cell: (params: any) => params.value,
        })
      }
      return columns
    },
    [t, type],
  )

  const handleUserAddDialogClickOpen = () => {
    setUserAddDialogOpen(true)
  }

  const handleUserAddDialogClose = (refreshTable: boolean = false) => {
    setUserAddDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const handleUserEditDialogClickOpen = (userId: number) => {
    setUserId(userId)
    setUserEditDialogOpen(true)
  }

  const handleUserEditDialogClose = (refreshTable: boolean = false) => {
    setUserEditDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const handleUserDetailsDialogClickOpen = (userId: number) => {
    setUserId(userId)
    setUserDetailsDialogOpen(true)
  }

  const handleUserDetailsDialogClose = (refreshTable: boolean = false) => {
    setUserDetailsDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const handleUserChangePointsDialogClickOpen = (selectedRows: Row<any>[]) => {
    setSelectedUserIds(
      selectedRows.map((row) => ({ userId: row.original.userId })),
    )
    setUserChangePointsDialogOpen(true)
  }

  const handleUserChangePointsDialogClose = (refreshTable: boolean = false) => {
    setUserChangePointsDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  useEffect(() => {
    const fetchCompaniesData = async () => {
      try {
        const companyListResponse = await CompanyService.getCompanyList()

        if (companyListResponse.data.companies) {
          const multiSelectOptions: Option[] = []
          companyListResponse.data.companies.forEach((company) =>
            multiSelectOptions.push({
              value: company.id,
              label: company.name,
            }),
          )
          setCompanies(multiSelectOptions)
          const companiesState = getTableState(tableName, 'companies')
          if (companiesState) {
            setSelectedCompanies(JSON.parse(companiesState))
          } else {
            setSelectedCompanies(multiSelectOptions)
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchCompaniesData()

    const getFiltersData = async () => {
      const searchState = getTableState(tableName, 'search')
      const statusState = getTableState(tableName, 'status')
      const lastLoginDateFromState = getTableState(
        tableName,
        'last-login-date-from',
      )
      const lastLoginTimeFromState = getTableState(
        tableName,
        'last-login-time-from',
      )
      const lastLoginDateToState = getTableState(
        tableName,
        'last-login-date-to',
      )
      const lastLoginTimeToState = getTableState(
        tableName,
        'last-login-time-to',
      )

      if (searchState) {
        setSearchText(searchState)
        setSearchValue(searchState)
      } else {
        setSearchText('')
        setSearchValue('')
      }

      if (statusState) {
        setStatusValue(statusState)
      } else {
        setStatusValue('all')
      }

      if (lastLoginDateFromState && lastLoginDateFromState !== 'null') {
        setLastLoginDateFrom(new Date(JSON.parse(lastLoginDateFromState)))
      } else {
        setLastLoginDateFrom(null)
      }

      if (lastLoginTimeFromState) {
        setLastLoginTimeFrom(new Date(JSON.parse(lastLoginTimeFromState)))
      } else {
        setLastLoginTimeFrom(new Date(new Date().setHours(0, 0, 0, 0)))
      }

      if (lastLoginDateToState && lastLoginDateToState !== 'null') {
        setLastLoginDateTo(new Date(JSON.parse(lastLoginDateToState)))
      } else {
        setLastLoginDateTo(null)
      }

      if (lastLoginTimeToState) {
        setLastLoginTimeTo(new Date(JSON.parse(lastLoginTimeToState)))
      } else {
        setLastLoginTimeTo(new Date(new Date().setHours(0, 0, 0, 0)))
      }
    }
    getFiltersData()
  }, [t, tableName])

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      if (refresh) {
      }
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        if (selectedCompanies.length) {
          setTableLoading(true)
          try {
            let sortColumn = ''
            let sortDirection = ''
            if (sortBy.length) {
              sortColumn = sortBy[0].id
              sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
            }

            setDownloadSortBy(sortColumn)
            setDownloadSortOrder(sortDirection)

            const validFrom = lastLoginDateFrom
              ? moment(lastLoginDateFrom).format('YYYY-MM-DD') +
                ' ' +
                moment(lastLoginTimeFrom).format('HH:mm') +
                ':00'
              : null

            const validTo = lastLoginDateTo
              ? moment(lastLoginDateTo).format('YYYY-MM-DD') +
                ' ' +
                moment(lastLoginTimeTo).format('HH:mm') +
                ':59'
              : null

            const page = ++pageIndex
            const usersResponse = await UserService.getUsers(
              selectedCompanies.map((company) => {
                return {
                  id: company.value,
                }
              }),
              statusValue === 'all' ? null : statusValue === '1' ? true : false,
              type,
              searchValue,
              sortColumn,
              sortDirection,
              pageSize,
              page,
              validFrom,
              validTo,
            )

            if (usersResponse.data.userDetailsList) {
              setTableColumns(
                generateTableColumns(usersResponse.data.userDetailsList),
              )

              setFilteredUsersList(usersResponse.data.userDetailsList)

              setTotalCount(usersResponse.data.totalCount)
              setPageCount(Math.ceil(usersResponse.data.totalCount / pageSize))
            }
          } catch (error) {
            errorHandler(error, t)
          } finally {
            setSkipPageReset(true)
            setTableLoading(false)
          }
        } else {
          setTableColumns(generateTableColumns([]))

          setFilteredUsersList([])

          setTotalCount(0)
          setPageCount(0)
        }
      }
    },
    [
      t,
      selectedCompanies,
      statusValue,
      searchValue,
      generateTableColumns,
      type,
      refresh,
      lastLoginDateFrom,
      lastLoginTimeFrom,
      lastLoginDateTo,
      lastLoginTimeTo,
    ],
  )

  const handleSelectedCompaniesChange = (companies: Option[]) => {
    setSkipPageReset(false)
    setControlledPageIndex(0)
    setTableState(tableName, 'companies', JSON.stringify(companies))
    setSelectedCompanies(companies)
  }

  const downloadXLSX = async (name: string) => {
    const fileName = `${name}.xlsx`
    try {
      setIsDownloading(true)

      const validFrom = lastLoginDateFrom
        ? moment(lastLoginDateFrom).format('YYYY-MM-DD') +
          ' ' +
          moment(lastLoginTimeFrom).format('HH:mm') +
          ':00'
        : null

      const validTo = lastLoginDateTo
        ? moment(lastLoginDateTo).format('YYYY-MM-DD') +
          ' ' +
          moment(lastLoginTimeTo).format('HH:mm') +
          ':59'
        : null

      const usersResponse = await UserService.getUsers(
        selectedCompanies.map((company) => {
          return {
            id: company.value,
          }
        }),
        statusValue === 'all' ? null : statusValue === '1' ? true : false,
        type,
        searchValue,
        downloadSortBy,
        downloadSortOrder,
        100000,
        1,
        validFrom,
        validTo,
      )

      const dataUsers = usersResponse.data.userDetailsList
      if (dataUsers) {
        // remove hidden columns for xlsx
        let visibleColumns = columnsVisibility
          .filter((col) => col.isVisible)
          .map((col2) => col2.id)

        if (visibleColumns.length === 0) {
          visibleColumns = [
            'userId',
            'userCentralId',
            'userInternalId',
            'userFirstname',
            'userLastname',
            'userEmail',
            'userPhoneNumber',
            'userNameCompanies',
            'userRegionName',
            'userIsActive',
            'userBankAccount',
            'userLastLoginDate',
          ]
        }

        const hiddenColumns = getHiddenColumns(tableName, visibleColumns)
        visibleColumns = visibleColumns.filter(
          (c) => !hiddenColumns.includes(c),
        )

        const filteredUsersData = dataUsers.map((user) => {
          user.userIsActive = user.userIsActive
            ? t('pages.users.status.active').toString()
            : t('pages.users.status.inactive').toString()
          return pick(user, visibleColumns)
        })

        const translatedHeaders = {
          userId: t('excel.users.userId'),
          userInternalId: t('excel.users.userInternalId'),
          userTypeId: t('excel.users.userTypeId'),
          userCentralId: t('excel.users.userCentralId'),
          userFirstname: t('excel.users.userFirstname'),
          userLastname: t('excel.users.userLastname'),
          userEmail: t('excel.users.userEmail'),
          userPhoneNumber: t('excel.users.userPhoneNumber'),
          userCompanyId: t('excel.users.userCompanyId'),
          userNameCompanies: t('excel.users.userNameCompanies'),
          userRegionId: t('excel.users.userRegionId'),
          userRegionName: t('excel.users.userRegionName'),
          userTypeName: t('excel.users.userTypeName'),
          userLastLoginDate: t('excel.users.userLastLoginDate'),
          userBankAccount: t('excel.users.userBankAccount'),
          userIsActive: t('excel.users.userIsActive'),
        }

        const headers = [
          Object.keys(filteredUsersData[0]).map(
            (key) => (translatedHeaders as any)[key],
          ),
        ]

        //Had to create a new workbook and then add the header
        const ws: XLSX.WorkSheet = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(ws, headers)

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, filteredUsersData, {
          origin: 'A2',
          skipHeader: true,
        })

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredUsersData)
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, name)

        XLSX.writeFile(wb, fileName)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Stack
            display="flex"
            alignContent="space-between"
            flexDirection="row"
            marginBottom={1}
          >
            {type === 'PH' && (
              <PrimaryButton
                variant="contained"
                onClick={handleUserAddDialogClickOpen}
              >
                {t('common.create')}
              </PrimaryButton>
            )}

            <SecondaryButton
              variant="contained"
              onClick={() => downloadXLSX('users')}
              sx={{ marginLeft: 'auto' }}
              disabled={isDownloading}
            >
              {isDownloading && (
                <CircularProgress
                  style={{ height: 12, width: 12, marginRight: 10 }}
                />
              )}
              {isDownloading
                ? t('common.generatingFile')
                : t('common.downloadTableAsXLSX')}
            </SecondaryButton>
          </Stack>
          <UsersToolbar
            user={user}
            companies={companies}
            selectedCompanies={selectedCompanies}
            value={searchText}
            onChange={(event: { target: { value: string } }) => {
              setSearchText(event.target.value)
            }}
            submitSearch={(searchValue) => {
              setSkipPageReset(false)
              setSearchValue(searchValue)
              setTableState(tableName, 'search', searchValue)
            }}
            setSelectedCompanies={(companies) =>
              handleSelectedCompaniesChange(companies)
            }
            clearSearch={() => {
              setSkipPageReset(false)
              setSearchText('')
              setSearchValue('')
              setTableState(tableName, 'search', '')
            }}
            statusValue={statusValue}
            filterStatus={(event: SelectChangeEvent) => {
              setStatusValue(event.target.value)
              setTableState(tableName, 'status', event.target.value)
            }}
            lastLoginDateFrom={lastLoginDateFrom}
            lastLoginTimeFrom={lastLoginTimeFrom}
            lastLoginDateTo={lastLoginDateTo}
            lastLoginTimeTo={lastLoginTimeTo}
            setLastLoginDateFrom={(date) => {
              setTableState(
                tableName,
                'last-login-date-from',
                JSON.stringify(date),
              )
              setLastLoginDateFrom(date)
            }}
            setLastLoginTimeFrom={(time) => {
              setTableState(
                tableName,
                'last-login-time-from',
                JSON.stringify(time),
              )
              setLastLoginTimeFrom(time)
            }}
            setLastLoginDateTo={(date) => {
              setTableState(
                tableName,
                'last-login-date-to',
                JSON.stringify(date),
              )
              setLastLoginDateTo(date)
            }}
            setLastLoginTimeTo={(time) => {
              setTableState(
                tableName,
                'last-login-time-to',
                JSON.stringify(time),
              )
              setLastLoginTimeTo(time)
            }}
          />
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="end"
            style={{ marginBottom: '-22px' }}
          >
            {(isCentralAdmin(user) || isSuperAdmin(user)) && (
              <Button
                size="small"
                endIcon={<ChangeCircleIcon />}
                style={{ textTransform: 'none' }}
                disabled={selectedRows.length === 0}
                onClick={() =>
                  handleUserChangePointsDialogClickOpen(selectedRows)
                }
              >
                {t('pages.users.changePoints')}
              </Button>
            )}
            {/* <Button
              size="small"
              endIcon={<LockIcon />}
              style={{ textTransform: 'none', marginRight: '15px' }}
              disabled={selectedRows.length === 0}
            >
              {t('pages.users.changePassword')}
            </Button>
            <Button
              size="small"
              endIcon={<HighlightOffIcon />}
              style={{ textTransform: 'none' }}
              disabled={selectedRows.length === 0}
            >
              {t('pages.users.deactivation')}
            </Button> */}
          </Stack>
          <TableControlled
            name={tableName}
            columns={tableColumns}
            data={filteredUsersList}
            height="calc(100vh - 300px)"
            fetchData={fetchData}
            loading={tableLoading}
            pageIndex={controlledPageIndex}
            pageCount={pageCount}
            totalCount={totalCount}
            skipPageReset={skipPageReset}
            columnsVisibility={[
              'userCentralId',
              'userCompanyId',
              'userPhoneNumber',
              'userBankAccount',
              'userLastLoginDate',
            ]}
            toggleVisibility={setColumnsVisibility}
            handleSelectedRows={setSelectedRows}
          />
          <UserAddDialog
            open={openUserAddDialog}
            handleClose={handleUserAddDialogClose}
            type={type}
          />
          {userId && (
            <UserEditDialog
              open={openUserEditDialog}
              handleClose={handleUserEditDialogClose}
              type={type}
              userId={userId}
            />
          )}
          {userId && (
            <UserDetailsDialog
              open={openUserDetailsDialog}
              handleClose={handleUserDetailsDialogClose}
              type={type}
              userId={userId}
            />
          )}
          <UserChangePointsDialog
            users={selectedUserIds}
            open={openUserChangePointsDialog}
            handleClose={handleUserChangePointsDialogClose}
          />
        </>
      )}
    </>
  )
}

export default UsersList
