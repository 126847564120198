import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import {
  Alert,
  Stack,
  AlertColor,
  Typography,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'
import { errorHandler } from '../../../../../helpers/errorHandler'
import * as XLSX from 'xlsx'
import { toast } from 'react-toastify'
import {
  ImportPreparePlanParams,
  StorePlanImportPlanData,
} from '../../../../../store/StorePlan/types'
import StorePlanService from '../../../../../services/storePlan.service'
import CompanyService from '../../../../../services/company.service'
import { Company } from '../../../../../store/Company/types'
import LoadingSpinner from '../../../../shared/LoadingSpinner'
import {
  isCentralAdmin,
  isCompanyAdmin,
  isSuperAdmin,
} from '../../../../../helpers/checkRole'
import { User } from '../../../../../store/Auth/types'
import { findDuplicates } from '../../../../../helpers/utils'

const { read, utils } = XLSX

const Input = styled('input')({
  display: 'none',
})

type PeriodPlanImportStorePlanStep1Props = {
  plan: string
  user: User
  handleSetAcceptMessage: (acceptMessage: {
    type: AlertColor
    message: string
  }) => void
  handleSetSkippedResults: (skippedResults: { storeId: string }[]) => void
  handleSetAddedResults: (addedResults: { storeId: string }[]) => void
  handleSetCompany: React.Dispatch<React.SetStateAction<string>>
}

const PeriodPlanImportStorePlanStep1: React.FunctionComponent<
  PeriodPlanImportStorePlanStep1Props
> = ({
  plan,
  user,
  handleSetAcceptMessage,
  handleSetSkippedResults,
  handleSetAddedResults,
  handleSetCompany,
}) => {
  const { t } = useTranslation()
  const [savingImport, setSavingImport] = useState<boolean>(false)
  const [importPreparePlanParams, setImportPreparePlanParams] =
    useState<ImportPreparePlanParams | null>(null)
  const [alertOpen, setAlertOpen] = useState<boolean>(true)
  const [alertData, setAlertData] = useState({
    type: 'error' as AlertColor,
    message: '',
  })
  const [realizationSum, setRealizationSum] = useState<number>(0)
  const [dataObj, setDataObj] = useState<StorePlanImportPlanData[]>([])
  const [file, setFile] = useState<File | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [companies, setCompanies] = useState<Company[]>([])
  const [company, setCompany] = useState<string>('default')
  const [firstStructureParam, setFirstStructureParam] =
    useState<string>('centralId')

  const uploadInputRef = useRef<HTMLInputElement>(null)

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    if (e && e.target && e.target.files && e.target.files.length > 0) {
      var files = e.target.files,
        f = files[0]
      setFile(f)
      var reader = new FileReader()
      reader.onload = function (e) {
        if (e && e.target) {
          var data = e.target.result
          let readedData = read(data, { type: 'binary' })
          const wsname = readedData.SheetNames[0]
          const ws = readedData.Sheets[wsname]

          /* Convert array to json*/
          const dataParse = utils.sheet_to_json<StorePlanImportPlanData>(ws, {
            blankrows: false,
            defval: null,
          })
          setDataObj(dataParse)
        }
      }
      reader.readAsBinaryString(f)
      e.target.value = ''
    }
  }

  const saveImport = async () => {
    if (importPreparePlanParams) {
      try {
        setSavingImport(true)
        const importResponse = await StorePlanService.importPreparePlan(
          importPreparePlanParams,
        )

        if (importResponse.data.success) {
          if (
            (importResponse.data.addedResultsAmount ||
              importResponse.data.addedResultsAmount === 0) &&
            (importResponse.data.skippedResultsAmount ||
              importResponse.data.skippedResultsAmount === 0) &&
            (importResponse.data.skippedResultsPlanValueSum ||
              importResponse.data.skippedResultsPlanValueSum === 0)
          ) {
            const addedResultsAmount = importResponse.data.addedResultsAmount
            const skippedResultsAmount =
              importResponse.data.skippedResultsAmount
            const skippedResultsPlanValueSum =
              importResponse.data.skippedResultsPlanValueSum
            const resultsSum = addedResultsAmount + skippedResultsAmount
            const skippedResultsPercent =
              Math.round(
                ((skippedResultsAmount * 100) / resultsSum + Number.EPSILON) *
                  100,
              ) / 100

            const skippedRealizationPercent =
              Math.round(
                ((skippedResultsPlanValueSum * 100) / realizationSum +
                  Number.EPSILON) *
                  100,
              ) / 100

            handleSetAcceptMessage({
              type: 'warning',
              message: t(
                'pages.storeImportPlan.messages.beforeAcceptMessage',
                {
                  addedResultsAmount: addedResultsAmount.toLocaleString(),
                  resultsSum: resultsSum.toLocaleString(),
                  skippedResultsAmount: skippedResultsAmount.toLocaleString(),
                  skippedResultsPercent: skippedResultsPercent,
                  skippedResultsPlanValueSum:
                    skippedResultsPlanValueSum.toLocaleString(),
                  skippedRealizationPercent: skippedRealizationPercent,
                },
              ),
            })
          }
          if (importResponse.data.skippedResults) {
            handleSetSkippedResults(
              importResponse.data.skippedResults.map((store) => {
                return {
                  storeId: store,
                }
              }),
            )
          }
          if (importResponse.data.addedResults) {
            handleSetAddedResults(
              importResponse.data.addedResults.map((store) => {
                return {
                  storeId: store,
                }
              }),
            )
          }
        } else {
          toast.error(t('messages.error.generalError'))
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setImportPreparePlanParams(null)
        setSavingImport(false)
      }
    }
  }

  const handleCompanyChange = async (event: SelectChangeEvent) => {
    setCompany(event.target.value as string)
    handleSetCompany(event.target.value as string)
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const companyListResponse = await CompanyService.getCompanyList()
        setCompanies(companyListResponse.data.companies)
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (!isCompanyAdmin(user)) {
      fetchData()
    } else {
      setLoading(false)
    }
  }, [user, t])

  useEffect(() => {
    setAlertOpen(false)
    setFile(null)
    setDataObj([])
  }, [plan])

  useEffect(() => {
    if (isSuperAdmin(user) && company === 'default') {
      setFirstStructureParam('centralId')
    } else if (isSuperAdmin(user) && company !== 'default') {
      setFirstStructureParam('internalId')
    } else if (isCentralAdmin(user) && company === 'default') {
      setFirstStructureParam('centralId')
    } else if (isCentralAdmin(user) && company !== 'default') {
      setFirstStructureParam('internalId')
    } else if (isCompanyAdmin(user)) {
      setCompany('default')
      handleSetCompany('default')
      setFirstStructureParam('internalId')
    }
  }, [user, company, handleSetCompany])

  useEffect(() => {
    let userPlanImportData: StorePlanImportPlanData[] = []
    var BreakException = {}
    let fileError = {
      type: 'info' as AlertColor,
      message: '',
    }

    setAlertOpen(false)
    setAlertData(fileError)
    setImportPreparePlanParams(null)

    try {
      const storeIds: string[] = []
      dataObj.forEach((element, i) => {
        if (!element.hasOwnProperty(firstStructureParam)) {
          fileError = {
            type: 'error',
            message: t('pages.storeImportPlan.messages.columnMissing', {
              column: firstStructureParam,
              line: i + 1,
            }),
          }
          throw BreakException
        } else if (!element.hasOwnProperty('value')) {
          fileError = {
            type: 'error',
            message: t('pages.storeImportPlan.messages.columnMissing', {
              column: 'value',
              line: i + 1,
            }),
          }
          throw BreakException
        }
        storeIds.push((element as any)[firstStructureParam].toString())
      })

      const hasDuplicatedIds = findDuplicates(storeIds)
      if (hasDuplicatedIds.length > 0) {
        fileError = {
          type: 'error',
          message: t('pages.storeImportPlan.messages.duplicatedIds', {
            column: firstStructureParam,
            values: hasDuplicatedIds.join(', ')
          }),
        }
        throw BreakException
      }

      let sum = 0
      dataObj.forEach((element) => {
        sum += Math.round((element as any).value)
        userPlanImportData.push({
          storeId: (element as any)[firstStructureParam].toString(),
          planValue: (element as any).value,
        })
      })
      setRealizationSum(sum)
    } catch (e) {
      if (e !== BreakException) {
        throw e
      } else {
        userPlanImportData = []
        setAlertOpen(true)
        setAlertData(fileError)
      }
    } finally {
      if (userPlanImportData.length > 0 && plan) {
        setAlertData({
          type: 'info' as AlertColor,
          message: t('pages.storeImportPlan.messages.fileReadyToImport', {
            length: userPlanImportData.length,
            realization: realizationSum.toLocaleString(),
          }),
        })
        setImportPreparePlanParams({
          planId: parseInt(plan),
          companyId: company === 'default' ? null : parseInt(company),
          data: userPlanImportData,
        })
        setAlertOpen(true)
      }
    }
  }, [dataObj, plan, t, realizationSum, file, firstStructureParam, company])

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && plan && (
        <>
          {!isCompanyAdmin(user) && (
            <FormControl size="small" fullWidth>
              <label>{t('pages.storeImportPlan.step1.dataLevel')}</label>
              <Select
                id="company-select"
                onChange={handleCompanyChange}
                value={company}
                defaultValue={company}
              >
                <MenuItem value="default">
                  {t('pages.storeImportPlan.step1.central')}
                </MenuItem>
                {companies.map((company) => (
                  <MenuItem value={company.id} key={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Alert severity="warning" style={{ marginTop: 15 }}>
            {t('pages.storeImportPlan.step1.requiredFileStructure')}
            <br />
            <strong>{firstStructureParam}, value</strong>
          </Alert>
          <Input
            ref={uploadInputRef}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
            id="contained-button-file"
            type="file"
            onChange={onFileChange}
          />
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            mt={2}
          >
            <label htmlFor="contained-button-file">
              <SecondaryButton
                onClick={() =>
                  uploadInputRef.current && uploadInputRef.current.click()
                }
                size="small"
                style={{ padding: '5px 10px' }}
              >
                {file
                  ? t('pages.challenge.import.changeFile')
                  : t('pages.challenge.import.selectFile')}
              </SecondaryButton>
            </label>
            {file && (
              <Typography variant="caption" ml={1}>
                {file.name}
              </Typography>
            )}
          </Stack>
          {alertOpen && (
            <Alert
              severity={alertData.type}
              sx={{ my: 2 }}
              style={{ whiteSpace: 'pre-line' }}
            >
              {alertData.message}
            </Alert>
          )}
          {importPreparePlanParams && (
            <Stack
              className="buttons-container"
              spacing={2}
              direction="row"
              justifyContent="flex-end"
              width="100%"
            >
              <PrimaryButton
                onClick={saveImport}
                disabled={savingImport || !plan}
              >
                {t('common.import')}
              </PrimaryButton>
            </Stack>
          )}
        </>
      )}
    </>
  )
}

export default PeriodPlanImportStorePlanStep1
