import { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { RootStateOrAny, useSelector } from 'react-redux'
import { checkAuth } from '../../../../helpers/checkAuth'

import { AlertColor, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'

import PeriodPlanImportResultStep1 from './partials/PeriodPlanImportResultStep1'
import PeriodPlanImportResultStep2 from './partials/PeriodPlanImportResultStep2'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import { handleNavigationClick } from '../../../../helpers/utils'
import { errorHandler } from '../../../../helpers/errorHandler'
import { PeriodPlan } from '../../../../store/PeriodPlan/types'
import PeriodPlanService from '../../../../services/periodPlan.service'
import LoadingSpinner from '../../../shared/LoadingSpinner'

const PeriodPlanImportResultContainer = styled('div')(({ theme }) => ({
  'width-100': {
    width: '100%',
  },
  '.header': {
    fontSize: '1.25rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 20,
    '&_number': {
      backgroundColor: theme.colorsPalette.gray.gray5,
      color: '#FFF',
      width: 46,
      height: 46,
      borderRadius: 23,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '&_txt': {
      color: theme.colorsPalette.gray.gray5,
      fontWeight: 'bold',
      marginLeft: 10,
    },
  },
  '.challenges-main-grid_item': {
    paddingRight: 16,
    '&:not(:first-of-type)': {
      borderLeft: '1px solid',
      borderColor: theme.colorsPalette.gray.gray3,
    },
  },
}))

type PeriodPlanParams = {
  id: string
}

const PeriodPlanImportResult = () => {
  let { id } = useParams<PeriodPlanParams>()
  const { t } = useTranslation()
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )

  const [acceptMessage, setAcceptMessage] = useState<{
    type: AlertColor
    message: string
  } | null>(null)
  const [skippedStores, setSkippedStores] = useState<
    { storeCentralId: string }[]
  >([])
  const [addedStores, setAddedStores] = useState<{ storeCentralId: string }[]>(
    [],
  )
  const [loading, setLoading] = useState<boolean>(true)
  const [periodPlanDetails, setPeriodPlanDetails] = useState<PeriodPlan | null>(
    null,
  )

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const periodPlanDetailsResponse =
          await PeriodPlanService.getPeriodPlanDetails(parseInt(id))
        setPeriodPlanDetails(periodPlanDetailsResponse.data)
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [id, t])

  useEffect(() => {
    if (currentUser) {
      checkAuth(currentUser.token)
    }
  }, [currentUser])

  if (!currentUser) {
    return <Redirect to="/login" />
  }

  return (
    <div style={{ padding: '0 20px' }}>
      <PeriodPlanImportResultContainer>
        <Grid className="challenges-main-grid" container spacing={2}>
          <Grid className="challenges-main-grid_item" item md={4} xs={12}>
            <SecondaryButton
              variant="contained"
              onClick={() => handleNavigationClick('period-plans')}
            >
              {t('common.back')}
            </SecondaryButton>
            <Typography variant="h6" component="div" mt={2} gutterBottom>
              {t('pages.userPlanImport.title')}
            </Typography>
            {loading && <LoadingSpinner />}
            {!loading && periodPlanDetails && (
              <>
                <Typography variant="caption">
                  {t('pages.userPlanImport.periodId')}:{' '}
                  {periodPlanDetails.periodId}
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  fontWeight="bold"
                  gutterBottom
                >
                  {periodPlanDetails.planName}
                </Typography>
                <Typography variant="caption">
                  {t('pages.userPlanImport.planId')}: {periodPlanDetails.planId}
                </Typography>
                <Typography variant="body2" fontWeight="bold" component="div">
                  {periodPlanDetails.periodName}
                </Typography>
            </>
            )}
          </Grid>
          <Grid className="challenges-main-grid_item" item md={4} xs={12}>
            <div className="header">
              <div className="header_number">1</div>
              <div className="header_txt">
                {t('pages.userPlanImport.step1.title')}
              </div>
            </div>
            <PeriodPlanImportResultStep1
              plan={id}
              handleSetAcceptMessage={setAcceptMessage}
              handleSetAddedStores={setAddedStores}
              handleSetSkippedStores={setSkippedStores}
            />
          </Grid>
          <Grid className="challenges-main-grid_item" item md={4} xs={12}>
            <div className="header">
              <div className="header_number">2</div>
              <div className="header_txt">
                {t('pages.userPlanImport.step2.title')}
              </div>
            </div>
            <PeriodPlanImportResultStep2
              plan={id}
              acceptMessage={acceptMessage}
              addedStores={addedStores}
              skippedStores={skippedStores}
              handleSetAcceptMessage={setAcceptMessage}
              handleSetAddedStores={setAddedStores}
              handleSetSkippedStores={setSkippedStores}
            />
          </Grid>
        </Grid>
      </PeriodPlanImportResultContainer>
    </div>
  )
}

export default PeriodPlanImportResult
