import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { IconButton, SelectChangeEvent, Stack, Tooltip } from '@mui/material'
import PeriodService from '../../../../services/period.service'
import ChallengeService from '../../../../services/challenge.service'
import { Challenge, ChallengeState } from '../../../../store/Challenge/types'
import { useTranslation } from 'react-i18next'
import ChallengesToolbar from '../partials/ChallengesToolbar'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import {
  escapeRegExp,
  getTableState,
  handleNavigationClick,
  setTableState,
} from '../../../../helpers/utils'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { Period } from '../../../../store/Period/types'
import moment from 'moment'
import ChallengeResultsImportDialog from '../partials/ChallengeResultsImportDialog'
import Table from '../../../Table/Table'
import { Column } from 'react-table'
import { ReactComponent as PreviewIcon } from '../../../../assets/images/icons/preview.svg'
import { ReactComponent as PreviewPhoneIcon } from '../../../../assets/images/icons/preview_phone.svg'
import { ReactComponent as UserImportResult } from '../../../../assets/images/icons/user_import_result.svg'
import ChallengePreviewOnPhoneDialog from '../partials/ChallengePreviewOnPhoneDialog'

type ChallengeListProps = {
  path: string
}

const ChallengeList: FunctionComponent<ChallengeListProps> = ({ path }) => {
  const tableName = 'challenges'
  const searchState = getTableState(tableName, 'search')
  const periodState = getTableState(tableName, 'period')
  const statusState = getTableState(tableName, 'status')

  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [challengeList, setChallengeList] = useState<Challenge[]>([])
  const [filteredChallengeList, setFilteredChallengeList] = useState<
    Challenge[]
  >([])
  const [searchText, setSearchText] = useState<string>(
    searchState ? searchState : '',
  )
  const [periodValue, setPeriodValue] = useState<string>(
    periodState ? periodState : 'all',
  )
  const [periods, setPeriods] = useState<Period[]>([])
  const [statusValue, setStatusValue] = useState<string>(
    statusState ? statusState : 'all',
  )
  const [statuses, setStatuses] = useState<ChallengeState[]>([])
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])
  const [challengeId, setChallengeId] = useState<number | null>(null)
  const [
    challengeResultsImportDialogOpen,
    setChallengeResultsImportDialogOpen,
  ] = useState<boolean>(false)

  const [openPreviewOnPhoneDialog, setPreviewOnPhoneDialogOpen] =
    useState(false)

  const handlePreviewOnPhoneDialogClickOpen = (challengeId: number) => {
    setChallengeId(challengeId)
    setPreviewOnPhoneDialogOpen(true)
  }

  const handlePreviewOnPhoneDialogClose = () => {
    setPreviewOnPhoneDialogOpen(false)
  }

  const handleChallengeResultsImportDialogClickOpen = (challengeId: number) => {
    setChallengeId(challengeId)
    setChallengeResultsImportDialogOpen(true)
  }

  const handleChallengeResultsImportDialogClose = () => {
    setChallengeResultsImportDialogOpen(false)
  }

  const generateTableColumns = useCallback(
    (challange: Challenge[]) => {
      const columns = []
      columns.push(
        {
          accessor: 'id',
          Header: t('pages.challenge.table.id').toString(),
          width: 60,
        },
        {
          accessor: 'periodName',
          Header: t('pages.challenge.table.period').toString(),
          width: 140,
        },
        {
          accessor: 'name',
          Header: t('pages.challenge.table.name').toString(),
          width: 200,
        },
        {
          accessor: 'availableFrom',
          Header: t('pages.challenge.table.availableFrom').toString(),
          width: 120,
        },
        {
          accessor: 'availableTo',
          Header: t('pages.challenge.table.availableTo').toString(),
          width: 120,
        },
        {
          accessor: 'validFrom',
          Header: t('pages.challenge.table.validFrom').toString(),
          width: 120,
        },
        {
          accessor: 'validTo',
          Header: t('pages.challenge.table.validTo').toString(),
          width: 120,
        },
        {
          accessor: 'amateur',
          Header: t('pages.challenge.table.amateur').toString(),
        },
        {
          accessor: 'expert',
          Header: t('pages.challenge.table.expert').toString(),
        },
        {
          accessor: 'master',
          Header: t('pages.challenge.table.master').toString(),
        },
        {
          accessor: 'overall',
          Header: t('pages.challenge.table.overall').toString(),
          width: 140,
        },
        {
          accessor: 'statusName',
          Header: t('pages.challenge.table.status').toString(),
          width: 140,
        },
        {
          accessor: 'actions',
          Header: t('pages.challenge.table.actions').toString(),
          disableSortBy: true,
          sticky: 'right',
          Cell: (params: any) => (
            <Stack display={'flex'} alignItems={'center'} direction={'row'}>
              {params.row.original.isEditable && (
                <Tooltip title={`${t('pages.challenge.table.edit')}`}>
                  <IconButton
                    aria-label="edit"
                    size="small"
                    style={{ padding: 0, marginRight: '5px' }}
                    onClick={() =>
                      handleNavigationClick(
                        `${path}/update/${params.row.values.id}`,
                      )
                    }
                  >
                    <BorderColorIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
              {!params.row.original.isEditable && (
                <Tooltip title={`${t('pages.challenge.table.show')}`}>
                  <IconButton
                    aria-label="show"
                    size="small"
                    style={{ padding: 0, marginRight: '5px' }}
                    onClick={() =>
                      handleNavigationClick(
                        `${path}/show/${params.row.values.id}`,
                      )
                    }
                  >
                    <PreviewIcon />
                  </IconButton>
                </Tooltip>
              )}
              {params.row.original.status !== 'completed' &&
                params.row.original.isImportAllowed && (
                  <Tooltip
                    title={`${t('pages.challenge.table.importResults')}`}
                  >
                    <IconButton
                      aria-label="show"
                      size="small"
                      style={{ padding: 0, marginRight: '5px' }}
                      onClick={() => {
                        handleChallengeResultsImportDialogClickOpen(
                          params.row.values.id,
                        )
                      }}
                    >
                      <UserImportResult />
                    </IconButton>
                  </Tooltip>
                )}
              <Tooltip title={`${t('pages.challenge.table.showOnPhone')}`}>
                <IconButton
                  aria-label="show"
                  size="small"
                  style={{ padding: 0, marginRight: '5px' }}
                  onClick={() =>
                    handlePreviewOnPhoneDialogClickOpen(params.row.values.id)
                  }
                >
                  <PreviewPhoneIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          ),
        },
      )
      return columns
    },
    [t, path],
  )

  const requestSearch = (
    listToFilter: Challenge[],
    searchValue: string,
    filters: { column: string; value: string }[],
  ) => {
    let filteredRows: Challenge[] = listToFilter
    if (filters.length > 0) {
      filters.forEach((filter) => {
        filteredRows = filteredRows.filter((challenge) => {
          if (filter.value === 'all') {
            return true
          }
          if (
            (challenge as any)[filter.column].toString() ===
            filter.value.toString()
          ) {
            return true
          }
          return false
        })
      })
    }

    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')

    filteredRows = filteredRows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return row[field] && searchRegex.test(row[field].toString())
      })
    })
    setFilteredChallengeList(filteredRows)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const challengeStateListResponse =
          await ChallengeService.getChallengeStateList()
        if (challengeStateListResponse.data.challengeStates) {
          setStatuses(challengeStateListResponse.data.challengeStates)
          const periodListResponse = await PeriodService.getPeriodList()

          if (periodListResponse.data.periodList) {
            setPeriods(periodListResponse.data.periodList)

            const response = await ChallengeService.getChallengeList()
            const challengesFormatted = response.data.challenges.map((el) => {
              const statusName =
                challengeStateListResponse.data.challengeStates.find(
                  (ch) => ch.status === el.status,
                )?.name
              const periodName = periodListResponse.data.periodList.find(
                (p) => p.id === el.periodId,
              )?.name

              return {
                ...el,
                availableFrom: moment(el.availableFrom).format(
                  'YY-MM-DD HH:mm',
                ),
                availableTo: moment(el.availableTo).format('YY-MM-DD HH:mm'),
                validFrom: moment(el.validFrom).format('YY-MM-DD HH:mm'),
                validTo: moment(el.validTo).format('YY-MM-DD HH:mm'),
                statusName,
                periodName,
              }
            })

            if (response.data.challenges) {
              setChallengeList(challengesFormatted)
              setTableColumns(generateTableColumns(challengesFormatted))
              requestSearch(challengesFormatted, searchText, [
                {
                  column: 'periodId',
                  value: periodValue,
                },
                {
                  column: 'status',
                  value: statusValue,
                },
              ])
            }
          }
        }
      } catch (error) {
        const _content =
          // (error.response && error.response.data) ||
          (error as Error).message || (error as Error).toString()

        console.warn(_content)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [generateTableColumns, periodValue, statusValue, searchText])

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <PrimaryButton
            variant="contained"
            onClick={() => handleNavigationClick(`${path}/create`)}
          >
            {t('common.create')}
          </PrimaryButton>
          <ChallengesToolbar
            periods={periods}
            statuses={statuses}
            value={searchText}
            onChange={(event: { target: { value: string } }) => {
              setSearchText(event.target.value)
              setTableState(tableName, 'search', event.target.value)
              requestSearch(challengeList, event.target.value, [
                {
                  column: 'periodId',
                  value: periodValue,
                },
                {
                  column: 'status',
                  value: statusValue,
                },
              ])
            }}
            periodValue={periodValue}
            filterPeriod={(event: SelectChangeEvent) => {
              setPeriodValue(event.target.value)
              setTableState(tableName, 'period', event.target.value)
              requestSearch(challengeList, searchText, [
                {
                  column: 'periodId',
                  value: event.target.value,
                },
                {
                  column: 'status',
                  value: statusValue,
                },
              ])
            }}
            statusValue={statusValue}
            filterStatus={(event: SelectChangeEvent) => {
              setStatusValue(event.target.value)
              setTableState(tableName, 'status', event.target.value)
              requestSearch(challengeList, searchText, [
                {
                  column: 'periodId',
                  value: periodValue,
                },
                {
                  column: 'status',
                  value: event.target.value,
                },
              ])
            }}
            clearSearch={() => {
              setSearchText('')
              setTableState(tableName, 'search', '')
              requestSearch(challengeList, '', [
                {
                  column: 'periodId',
                  value: periodValue,
                },
                {
                  column: 'status',
                  value: statusValue,
                },
              ])
            }}
          />
          <Table
            name={tableName}
            columns={tableColumns}
            data={filteredChallengeList}
            height="calc(100vh - 300px)"
          />
          {challengeId && (
            <ChallengeResultsImportDialog
              open={challengeResultsImportDialogOpen}
              handleClose={handleChallengeResultsImportDialogClose}
              challengeId={challengeId}
            />
          )}
          {challengeId && (
            <ChallengePreviewOnPhoneDialog
              open={openPreviewOnPhoneDialog}
              handleClose={handlePreviewOnPhoneDialogClose}
              challengeId={challengeId}
            />
          )}
        </>
      )}
    </>
  )
}

export default ChallengeList
