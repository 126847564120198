import React, { FunctionComponent, useState } from 'react'
import { Grid } from '@mui/material'
import ChallengeStep1Form from '../partials/steps/ChallengeStep1Form'
import { styled } from '@mui/material/styles'
import ChallengeStep2Form from '../partials/steps/ChallengeStep2Form'
import ChallengeStep3Form from '../partials/steps/ChallengeStep3Form'
import ChallengeStep4Form from '../partials/steps/ChallengeStep4Form'
import { useTranslation } from 'react-i18next'
import { green, red } from '@mui/material/colors'

const ChallengeContainer = styled('div')(({ theme }) => ({
  'width-100': {
    width: '100%',
  },
  '.header': {
    fontSize: '1.25rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 20,
    '&_number': {
      backgroundColor: theme.colorsPalette.gray.gray5,
      color: '#FFF',
      width: 46,
      height: 46,
      borderRadius: 23,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '&_txt': {
      color: theme.colorsPalette.gray.gray5,
      fontWeight: 'bold',
      marginLeft: 10,
    },
  },
  '.challenges-main-grid_item': {
    paddingBottom: 20,
    paddingRight: 16,
    '&:not(:first-of-type)': {
      borderTop: '1px solid',
      borderColor: theme.colorsPalette.gray.gray3,
    },
  },
}))

type ChallengeCreateProps = {}

const ChallengeCreate: FunctionComponent<ChallengeCreateProps> = () => {
  const { t } = useTranslation()
  const [challengeId, setChallengeId] = useState<number | null>(null)
  const [challengeUnitId, setChallengeUnitId] = useState<number | null>(null)
  const [isStep1Touched, setIsStep1Touched] = useState<boolean>(false)
  const [isStep3Touched, setIsStep3Touched] = useState<boolean>(false)

  return (
    <ChallengeContainer>
      <Grid className="challenges-main-grid" container spacing={2}>
        <Grid className="challenges-main-grid_item" item md={12}>
          <div className="header">
            <div className="header_number">1</div>
            <div className="header_txt">{t('pages.challenge.step1.title')}</div>
          </div>
          <ChallengeStep1Form
            challengeId={challengeId}
            onSetChallengeId={setChallengeId}
            setIsStep1Touched={setIsStep1Touched}
          />
        </Grid>
        <Grid className="challenges-main-grid_item" item md={12}>
          <div className="header">
            <div className="header_number">2</div>
            <div className="header_txt">{t('pages.challenge.step2.title')}</div>
          </div>
          <ChallengeStep2Form challengeId={challengeId} />
        </Grid>
        <Grid className="challenges-main-grid_item" item md={12}>
          <div className="header">
            <div className="header_number">3</div>
            <div className="header_txt">
              {t('pages.challenge.step3.title')}{' '}
              <small>
                (
                {challengeUnitId && !isStep3Touched ? (
                  <span style={{ color: green[500] }}>
                    {t('pages.challenge.saved')}
                  </span>
                ) : (
                  <span style={{ color: red[500] }}>
                    {t('pages.challenge.unsaved')}
                  </span>
                )}
                )
              </small>
            </div>
          </div>
          <ChallengeStep3Form
            challengeId={challengeId}
            setChallengeUnitId={setChallengeUnitId}
            setIsStep3Touched={setIsStep3Touched}
          />
        </Grid>
        <Grid className="challenges-main-grid_item" item md={12}>
          <div className="header">
            <div className="header_number">4</div>
            <div className="header_txt">{t('pages.challenge.step4.title')}</div>
          </div>
          <ChallengeStep4Form
            challengeId={challengeId}
            challengeUnitId={challengeUnitId}
            isStep1Touched={isStep1Touched}
          />
        </Grid>
      </Grid>
    </ChallengeContainer>
  )
}

export default ChallengeCreate
