import axios from 'axios'
import {
  PeriodListReponse,
  PeriodActivateResponse,
  PeriodEditResponse,
  PeriodEditParams,
  PeriodDetailsResponse,
} from '../store/Period/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getPeriodList = async (): Promise<PeriodListReponse> => {
  return await axios.get(API_URL + '/api/admin/period', {
    headers: authHeader(),
  })
}

const activatePeriod = async (): Promise<PeriodActivateResponse> => {
  return await axios.post(
    API_URL + '/api/admin/period/activate',
    {},
    {
      headers: authHeader(),
    },
  )
}

const editPeriod = async (
  params: PeriodEditParams,
): Promise<PeriodEditResponse> => {
  return await axios.post(
    API_URL + '/api/admin/period/edit',
    {
      ...params,
    },
    {
      headers: authHeader(),
    },
  )
}

const getPeriodDetails = async (
  periodId: number,
): Promise<PeriodDetailsResponse> => {
  return await axios.get(API_URL + `/api/admin/period/${periodId}`, {
    headers: authHeader(),
  })
}

const exportedObject = {
  getPeriodList,
  activatePeriod,
  editPeriod,
  getPeriodDetails,
}

export default exportedObject
