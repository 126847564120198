import React, { useEffect, useState } from 'react'
import { Box, FormControl, FormHelperText, Grid, Stack } from '@mui/material'
import { FormError } from '../../../../../store/types'
import {
  ChallengeDetails,
  ChallengeLevels,
  ChallengeStep3Errors,
  ChallengeStep3LevelPoints,
  ChallengeStep4RequestParams,
} from '../../../../../store/Challenge/types'
import ChallengeService from '../../../../../services/challenge.service'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'
import { styled } from '@mui/material/styles'
import ChallengeLevelForm from '../ChallengeLevelForm'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../../helpers/errorHandler'
import ChallengeImportForm from './ChallengeImportForm'
import { toast } from 'react-toastify'
import AmateurImg from '../../../../../assets/images/other/amateur.png'
import ExpertImg from '../../../../../assets/images/other/expert.png'
import MasterImg from '../../../../../assets/images/other/master.png'

const ChallengeContainer = styled('div')(({ theme }) => ({
  '.form-style': {
    label: {
      color: theme.colorsPalette.gray.gray6,
      fontWeight: 'bold',
      fontSize: '.75rem',
    },
    '.MuiTextField-root': {
      marginTop: '0px !important',
      marginBottom: 0,
    },
  },
  '.step4-form': {
    '&_item': {
      ':not(:last-of-type)': {
        borderRight: '1px solid',
        borderColor: theme.colorsPalette.gray.gray3,
        paddingRight: 10,
      },
      ':not(:first-of-type)': {
        paddingLeft: 10,
      },
      '&--img': {
        height: 130,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        img: {
          maxHeight: 120,
        },
      },
    },
  },
  '.margin-top-form': {
    marginTop: 20,
  },

  '.buttons-container': {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
  },
}))

type ChallengeStep4FormProps = {
  challengeId: number | null
  challengeUnitId: number | null
  challengeDetails?: ChallengeDetails
  isStep1Touched: boolean
}

const ChallengeStep4Form: React.FunctionComponent<ChallengeStep4FormProps> = ({
  challengeId,
  challengeUnitId,
  challengeDetails,
  isStep1Touched,
}) => {
  const { t } = useTranslation()
  const [savingStep4, setSavingStep4] = useState<boolean>(false)
  const [isStep4Saved, setIsStep4Saved] = useState<boolean>(false)
  const [isStep4Touched, setIsStep4Touched] = useState<boolean>(false)
  const [levels, setLevels] = useState<ChallengeLevels>({
    amateur: false,
    expert: false,
    master: false,
  })

  const defaultPoints = {
    xpMinus: 0,
    vpMinus: 0,
    xpPlus: 0,
    vpPlus: 0,
  }
  const [points, setPoints] = useState<ChallengeStep3LevelPoints>({
    amateur: defaultPoints,
    expert: defaultPoints,
    master: defaultPoints,
  })

  // errors
  const defaultError: FormError = {
    error: false,
    message: '',
  }
  const defaultStep4FormErrors: ChallengeStep3Errors = {
    level: defaultError,
    unit: defaultError,
  }
  const [step4FormErrors, setStep4FormErrors] = useState<ChallengeStep3Errors>(
    defaultStep4FormErrors,
  )

  const handleLevelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsStep4Touched(true)
    setLevels({
      ...levels,
      [event.target.name]: event.target.checked,
    })
  }

  const resetStep4Form = () => {
    setIsStep4Touched(true)
    setStep4FormErrors(defaultStep4FormErrors)
    setPoints({
      amateur: defaultPoints,
      expert: defaultPoints,
      master: defaultPoints,
    })
    setLevels({
      amateur: false,
      expert: false,
      master: false,
    })
  }

  const saveStep4Form = async () => {
    setStep4FormErrors(defaultStep4FormErrors)
    const step4Errors = defaultStep4FormErrors

    if (!levels.amateur && !levels.expert && !levels.master) {
      setStep4FormErrors({
        ...step4Errors,
        level: {
          error: true,
          message: t('pages.challenge.step4.errors.levelRequired'),
        },
      })
      return
    }
    // else if (
    //   levels.amateur &&
    //   (points.amateur.vpPlus === null || points.amateur.xpPlus === null)
    // ) {
    //   setStep4FormErrors({
    //     ...step4Errors,
    //     level: {
    //       error: true,
    //       message: t('pages.challenge.step4.errors.amateurPointsRequired'),
    //     },
    //   })
    //   return
    // } else if (
    //   levels.expert &&
    //   (points.expert.vpPlus === null || points.expert.xpPlus === null)
    // ) {
    //   setStep4FormErrors({
    //     ...step4Errors,
    //     level: {
    //       error: true,
    //       message: t('pages.challenge.step4.errors.expertPointsRequired'),
    //     },
    //   })
    //   return
    // } else if (
    //   levels.master &&
    //   (points.master.vpPlus === null || points.master.xpPlus === null)
    // ) {
    //   setStep4FormErrors({
    //     ...step4Errors,
    //     level: {
    //       error: true,
    //       message: t('pages.challenge.step4.errors.masterPointsRequired'),
    //     },
    //   })
    //   return
    // }

    if (challengeId) {
      const formParams: ChallengeStep4RequestParams = {
        challengeId,
        amateur: levels.amateur,
        amateurXpPlus: levels.amateur ? points.amateur.xpPlus : null,
        amateurVpPlus: levels.amateur ? points.amateur.vpPlus : null,
        amateurXpMinus: levels.amateur ? points.amateur.xpMinus : null,
        amateurVpMinus: levels.amateur ? points.amateur.vpMinus : null,
        expert: levels.expert,
        expertXpPlus: levels.expert ? points.expert.xpPlus : null,
        expertVpPlus: levels.expert ? points.expert.vpPlus : null,
        expertXpMinus: levels.expert ? points.expert.xpMinus : null,
        expertVpMinus: levels.expert ? points.expert.vpMinus : null,
        master: levels.master,
        masterXpPlus: levels.master ? points.master.xpPlus : null,
        masterVpPlus: levels.master ? points.master.vpPlus : null,
        masterXpMinus: levels.master ? points.master.xpMinus : null,
        masterVpMinus: levels.master ? points.master.vpMinus : null,
      }

      try {
        setSavingStep4(true)
        const createOrUpdateChallengeStep4Response =
          await ChallengeService.updateChallengeStep4(formParams)

        if (createOrUpdateChallengeStep4Response.data.success) {
          toast.success(t('messages.success.savedSuccessfully'))
          setIsStep4Saved(true)
          setIsStep4Touched(false)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setSavingStep4(false)
      }
    }
  }

  useEffect(() => {
    // load data if update
    if (challengeDetails) {
      setPoints({
        amateur: {
          xpMinus: challengeDetails.amateurXpMinus,
          vpMinus: challengeDetails.amateurVpMinus,
          xpPlus: challengeDetails.amateurXpPlus,
          vpPlus: challengeDetails.amateurVpPlus,
        },
        expert: {
          xpMinus: challengeDetails.expertXpMinus,
          vpMinus: challengeDetails.expertVpMinus,
          xpPlus: challengeDetails.expertXpPlus,
          vpPlus: challengeDetails.expertVpPlus,
        },
        master: {
          xpMinus: challengeDetails.masterXpMinus,
          vpMinus: challengeDetails.masterVpMinus,
          xpPlus: challengeDetails.masterXpPlus,
          vpPlus: challengeDetails.masterVpPlus,
        },
      })
      setLevels({
        amateur: challengeDetails.amateur,
        expert: challengeDetails.expert,
        master: challengeDetails.master,
      })
    }
  }, [challengeDetails])

  const isImportPossible = (): boolean => {
    if (isStep4Saved) {
      return (
        (levels.amateur || levels.expert || levels.master) &&
        challengeUnitId !== null
      )
    } else if (challengeDetails) {
      return (
        challengeDetails.isEditable &&
        (challengeDetails.amateur ||
          challengeDetails.expert ||
          challengeDetails.master) &&
        challengeDetails.challengeUnitId !== null
      )
    }
    return false
  }

  return (
    <ChallengeContainer>
      <Box
        className="form-style"
        component="form"
        sx={{
          '& .MuiTextField-root': { mb: 1, mt: 1 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
        }}
        noValidate
        autoComplete="off"
      >
        <FormControl error={step4FormErrors.level.error}>
          <Grid className="step4-form" container>
            {Object.entries(levels).map((level) => (
              <Grid key={level[0]} className="step4-form_item" item xs>
                <div className="step4-form_item--img">
                  {level[0] === 'amateur' && (
                    <img style={{ height: 90 }} src={AmateurImg} alt="" />
                  )}
                  {level[0] === 'expert' && (
                    <img style={{ height: 100 }} src={ExpertImg} alt="" />
                  )}
                  {level[0] === 'master' && (
                    <img style={{ height: 120 }} src={MasterImg} alt="" />
                  )}
                </div>
                <div className="step4-form_item--input">
                  <ChallengeLevelForm
                    key={level[0]}
                    level={level}
                    points={(points as any)[level[0]]}
                    handleLevelChange={handleLevelChange}
                    handleLevelPointsChange={(levelName, levelPoints) => {
                      setIsStep4Touched(true)
                      setPoints({
                        ...points,
                        [levelName]: levelPoints,
                      })
                    }}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
          {step4FormErrors.level.error && (
            <FormHelperText>{step4FormErrors.level.message}</FormHelperText>
          )}
        </FormControl>
        <Stack
          className="buttons-container"
          spacing={2}
          direction="row"
          justifyContent="flex-end"
          width="100%"
        >
          <SecondaryButton onClick={resetStep4Form} disabled={savingStep4}>
            {t('common.reset')}
          </SecondaryButton>
          <PrimaryButton
            onClick={saveStep4Form}
            disabled={
              savingStep4 ||
              !challengeId ||
              !challengeUnitId ||
              (challengeDetails && !challengeDetails?.isEditable)
            }
          >
            {t('common.save')}
          </PrimaryButton>
        </Stack>
      </Box>
      <ChallengeImportForm
        challengeId={challengeId}
        challengeLevels={levels}
        isImportPossible={isImportPossible()}
        isStep1Touched={isStep1Touched}
        isStep4Touched={isStep4Touched}
      />
    </ChallengeContainer>
  )
}

export default ChallengeStep4Form
