import React, { FunctionComponent, useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Pagination,
  Stack,
} from '@mui/material'
import ImageService from '../../../../services/image.service'
import { Image } from '../../../../store/Image/types'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '../../../shared/LoadingSpinner'

type ChallengeImagePickerProps = {
  open: boolean
  handleClose: () => void
  handleImageSelect: (id: number) => void
}

const ChallengeImagePicker: FunctionComponent<ChallengeImagePickerProps> = ({
  open,
  handleClose,
  handleImageSelect,
}) => {
  const { t } = useTranslation()
  const [loadingImages, setLoadingImages] = useState(true)
  const [images, setImages] = useState<Image[]>([])
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 12

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setPage(page)
    fetchImages('CD', limit, limit * (page - 1))
  }

  const fetchImages = async (code: string, limit: number, offset: number) => {
    setLoadingImages(true)

    try {
      const imageListResponse = await ImageService.getImageList(
        code,
        limit,
        offset,
      )

      if (imageListResponse.data.images) {
        setImages(imageListResponse.data.images)
        setTotalCount(imageListResponse.data.totalCount)
      }
    } catch (error) {
      const _content =
        // (error.response && error.response.data) ||
        (error as Error).message || (error as Error).toString()

      console.warn(_content)
    } finally {
      setLoadingImages(false)
    }
  }

  useEffect(() => {
    if (open) {
      setPage(1)
      fetchImages('CD', limit, 0)
    }
  }, [open])

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle>{t('pages.challenge.step2.selectImage')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {loadingImages && <LoadingSpinner />}
          {!loadingImages && images.length === 0 && (
            <div>{t('pages.challenge.step2.noImagesToDisplay')}</div>
          )}
          {!loadingImages && images.length > 0 && (
            <>
              <ImageList
                sx={{ width: '100%', height: 310 }}
                cols={6}
                rowHeight={150}
              >
                {images.map((image) => (
                  <ImageListItem
                    key={image.id}
                    onClick={() => handleImageSelect(image.id)}
                  >
                    <img
                      src={`data:${image.imageMimeType};base64,${image.imageContent}`}
                      alt={image.name}
                      loading="lazy"
                      style={{ objectFit: 'contain' }}
                    />
                    <ImageListItemBar
                      title={image.name}
                      subtitle={<span>{image.description}</span>}
                      position="below"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
              <Stack spacing={2}>
                <Pagination
                  count={Math.ceil(totalCount / limit)}
                  page={page}
                  onChange={handlePageChange}
                />
              </Stack>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('common.close')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChallengeImagePicker
