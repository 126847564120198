import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Typography,
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid,
  FormHelperText,
  FormGroup,
  CircularProgress,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { errorHandler } from '../../../../helpers/errorHandler'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { FormError } from '../../../../store/types'
import { UserAddErrors, UserAddParams } from '../../../../store/User/types'
import CompanyService from '../../../../services/company.service'
import RegionService from '../../../../services/region.service'
import UserService from '../../../../services/user.service'
import { Company } from '../../../../store/Company/types'
import { Region } from '../../../../store/Region/types'
import { validateEmail } from '../../../../helpers/utils'
import { toast } from 'react-toastify'
import theme from '../../../../theme'

type UserAddDialogProps = {
  open: boolean
  handleClose: (refresh: boolean) => void
  type: string
}

const UserAddDialog: React.FunctionComponent<UserAddDialogProps> = ({
  open,
  handleClose,
  type,
}) => {
  const passwordRegex: RegExp = /^(?=.*\d)(?=.*[a-z])(?=.*)[0-9A-Za-z]{8,15}$/g
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [saving, setSaving] = useState<boolean>(false)
  const [centralId, setCentralId] = useState<string>('')
  const [internalId, setInternalId] = useState<string>('')
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordVerify, setPasswordVerify] = useState<string>('')
  const [firstname, setFirstname] = useState<string>('')
  const [lastname, setLastname] = useState<string>('')
  const [regionId, setRegionId] = useState<string>('')
  const [companyId, setCompanyId] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null)
  const [sendActivationEmail, setSendActivationEmail] = useState<boolean>(false)
  const [chatBoxAllowed, setChatBoxAllowed] = useState<boolean>(false)
  const [eduBoxAllowed, setEduBoxAllowed] = useState<boolean>(false)
  const [companies, setCompanies] = useState<Company[]>([])
  const [regions, setRegions] = useState<Region[]>([])

  // errors
  const defaultError: FormError = {
    error: false,
    message: '',
  }
  const defaultFormErrors: UserAddErrors = {
    centralId: defaultError,
    internalId: defaultError,
    username: defaultError,
    password: defaultError,
    passwordVerify: defaultError,
    firstname: defaultError,
    lastname: defaultError,
    regionId: defaultError,
    companyId: defaultError,
    phoneNumber: defaultError,
    sendActivationEmail: defaultError,
    chatBoxAllowed: defaultError,
    eduBoxAllowed: defaultError,
  }
  const [formErrors, setFormErrors] = useState<UserAddErrors>(defaultFormErrors)

  const handleCompanyChange = (event: SelectChangeEvent) => {
    setCompanyId(event.target.value as string)
  }

  const handleRegionChange = (event: SelectChangeEvent) => {
    setRegionId(event.target.value as string)
  }

  const handleFirstnameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFirstname(event.target.value)
  }

  const handleLastnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastname(event.target.value)
  }

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value)
  }

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPhoneNumber(event.target.value)
  }

  const handleCentralIdChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCentralId(event.target.value)
  }

  const handleInternalIdChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setInternalId(event.target.value)
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const handlePasswordVerifyChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPasswordVerify(event.target.value)
  }

  const handleSendActivationEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSendActivationEmail(event.target.checked)
  }

  const handleEduBoxAllowedChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEduBoxAllowed(event.target.checked)
  }

  const handleChatBoxAllowedChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setChatBoxAllowed(event.target.checked)
  }

  const resetForm = () => {
    setFormErrors(defaultFormErrors)
    setCompanyId('')
    setRegionId('')
    setFirstname('')
    setLastname('')
    setUsername('')
    setPhoneNumber('')
    setCentralId('')
    setInternalId('')
    setPassword('')
    setPasswordVerify('')
    setSendActivationEmail(false)
    setEduBoxAllowed(false)
    setChatBoxAllowed(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        // get companies
        const companiesResponse = await CompanyService.getCompanyList()

        if (companiesResponse.data.companies) {
          setCompanies(companiesResponse.data.companies)
        }
        // get regions
        const regionsResponse = await RegionService.getRegionList()

        if (regionsResponse.data.regions) {
          setRegions(regionsResponse.data.regions)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [open, t])

  const onSave = async () => {
    setFormErrors(defaultFormErrors)
    const step1Errors = defaultFormErrors

    if (companyId === '') {
      setFormErrors({
        ...step1Errors,
        companyId: {
          error: true,
          message: t('pages.users.userAddDialog.errors.companyIdRequired'),
        },
      })
      return
    } else if (regionId === '') {
      setFormErrors({
        ...step1Errors,
        regionId: {
          error: true,
          message: t('pages.users.userAddDialog.errors.regionIdRequired'),
        },
      })
      return
    } else if (firstname.trim() === '') {
      setFormErrors({
        ...step1Errors,
        firstname: {
          error: true,
          message: t('pages.users.userAddDialog.errors.firstnameRequired'),
        },
      })
      return
    } else if (lastname.trim() === '') {
      setFormErrors({
        ...step1Errors,
        lastname: {
          error: true,
          message: t('pages.users.userAddDialog.errors.lastnameRequired'),
        },
      })
      return
    } else if (username.trim() === '') {
      setFormErrors({
        ...step1Errors,
        username: {
          error: true,
          message: t('pages.users.userAddDialog.errors.usernameRequired'),
        },
      })
      return
    } else if (!validateEmail(username)) {
      setFormErrors({
        ...step1Errors,
        username: {
          error: true,
          message: t('pages.users.userAddDialog.errors.usernameInvalid'),
        },
      })
      return
    } else if (centralId.trim() === '') {
      setFormErrors({
        ...step1Errors,
        centralId: {
          error: true,
          message: t('pages.users.userAddDialog.errors.centralIdRequired'),
        },
      })
      return
    } else if (internalId.trim() === '') {
      setFormErrors({
        ...step1Errors,
        internalId: {
          error: true,
          message: t('pages.users.userAddDialog.errors.internalIdRequired'),
        },
      })
      return
    } else if (password !== passwordVerify) {
      setFormErrors({
        ...step1Errors,
        password: {
          error: true,
          message: t('pages.users.userAddDialog.errors.passwordMatch'),
        },
        passwordVerify: {
          error: true,
          message: t('pages.users.userAddDialog.errors.passwordMatch'),
        },
      })
      return
    } else if (password.trim() === '' || passwordVerify.trim() === '') {
      setFormErrors({
        ...step1Errors,
        password: {
          error: true,
          message: t('pages.users.userAddDialog.errors.passwordRequired'),
        },
        passwordVerify: {
          error: true,
          message: t('pages.users.userAddDialog.errors.passwordRequired'),
        },
      })
      return
    } else if (!password.match(passwordRegex)) {
      setFormErrors({
        ...step1Errors,
        password: {
          error: true,
          message: t('pages.users.userAddDialog.errors.passwordInvalid'),
        },
        passwordVerify: {
          error: true,
          message: t('pages.users.userAddDialog.errors.passwordInvalid'),
        },
      })
      return
    }

    const formParams: UserAddParams = {
      centralId,
      internalId,
      username,
      password,
      firstname,
      lastname,
      regionId: parseInt(regionId),
      companyId: parseInt(companyId),
      phoneNumber,
      sendActivationEmail,
      chatBoxAllowed: false,
      eduBoxAllowed: false,
      userTypeName: 'PH',
    }

    try {
      setSaving(true)
      const addUserResponse = await UserService.addUser(formParams)

      if (addUserResponse.data.success) {
        toast.success(t('messages.success.savedSuccessfully'))
        resetForm()
        handleClose(true)
      } else {
        toast.error(t('messages.error.generalError'))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setSaving(false)
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return
        handleClose(false)
      }}
    >
      <DialogTitle>
        <Typography variant="body1" fontWeight="bold">
          {t('pages.users.userAddDialog.title', {
            userTypeName: type,
          })}
        </Typography>
      </DialogTitle>
      {loading && <LoadingSpinner />}
      {!loading && (
        <DialogContent>
          <DialogContentText>
            <FormControl
              fullWidth
              margin="dense"
              error={formErrors.companyId.error}
            >
              <label>{t('pages.users.userAddDialog.company')} *</label>
              <Select
                id="company-select"
                value={companyId}
                onChange={handleCompanyChange}
                size="small"
              >
                {companies.map((company) => (
                  <MenuItem value={company.id} key={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
              {formErrors.companyId.error && (
                <FormHelperText>
                  {formErrors.companyId.error && formErrors.companyId.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              fullWidth
              margin="dense"
              error={formErrors.regionId.error}
            >
              <label>{t('pages.users.userAddDialog.region')} *</label>
              <Select
                id="region-select"
                value={regionId}
                onChange={handleRegionChange}
                size="small"
              >
                {regions.map((region) => (
                  <MenuItem value={region.id} key={region.id}>
                    {region.name}
                  </MenuItem>
                ))}
              </Select>
              {formErrors.regionId.error && (
                <FormHelperText>
                  {formErrors.regionId.error && formErrors.regionId.message}
                </FormHelperText>
              )}
            </FormControl>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>{t('pages.users.userAddDialog.firstname')} *</label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={firstname}
                    onChange={handleFirstnameChange}
                    error={formErrors.firstname.error}
                    helperText={
                      formErrors.firstname.error && formErrors.firstname.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>{t('pages.users.userAddDialog.lastname')} *</label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    value={lastname}
                    onChange={handleLastnameChange}
                    error={formErrors.lastname.error}
                    helperText={
                      formErrors.lastname.error && formErrors.lastname.message
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>{t('pages.users.userAddDialog.username')} *</label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    value={username}
                    onChange={handleUsernameChange}
                    error={formErrors.username.error}
                    helperText={
                      formErrors.username.error && formErrors.username.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>{t('pages.users.userAddDialog.phoneNumber')}</label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    error={formErrors.phoneNumber.error}
                    helperText={
                      formErrors.phoneNumber.error &&
                      formErrors.phoneNumber.message
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>{t('pages.users.userAddDialog.centralId')} *</label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    value={centralId}
                    onChange={handleCentralIdChange}
                    error={formErrors.centralId.error}
                    helperText={
                      formErrors.centralId.error && formErrors.centralId.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>{t('pages.users.userAddDialog.internalId')} *</label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={internalId}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    onChange={handleInternalIdChange}
                    error={formErrors.internalId.error}
                    helperText={
                      formErrors.internalId.error &&
                      formErrors.internalId.message
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={eduBoxAllowed}
                    onChange={handleEduBoxAllowedChange}
                  />
                }
                label={t('pages.users.userAddDialog.eduBoxAllowed')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chatBoxAllowed}
                    onChange={handleChatBoxAllowedChange}
                  />
                }
                label={t('pages.users.userAddDialog.chatBoxAllowed')}
              />
            </FormGroup>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>{t('pages.users.userAddDialog.password')} *</label>
                  <TextField
                    variant="outlined"
                    type="password"
                    fullWidth
                    size="small"
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                    value={password}
                    onChange={handlePasswordChange}
                    error={formErrors.password.error}
                    helperText={
                      formErrors.password.error && formErrors.password.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t('pages.users.userAddDialog.passwordVerify')} *
                  </label>
                  <TextField
                    variant="outlined"
                    type="password"
                    fullWidth
                    size="small"
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                    value={passwordVerify}
                    onChange={handlePasswordVerifyChange}
                    error={formErrors.password.error}
                    helperText={
                      formErrors.password.error && formErrors.password.message
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendActivationEmail}
                  onChange={handleSendActivationEmailChange}
                />
              }
              label={t('pages.users.userAddDialog.sendActivationEmail')}
            />
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Stack flexDirection="row" justifyContent="space-between" width="100%">
          <SecondaryButton
            onClick={() => {
              resetForm()
              handleClose(false)
            }}
          >
            {t('common.cancel')}
          </SecondaryButton>
          <PrimaryButton onClick={onSave} disabled={saving || loading}>
            {t('common.save')}
            {saving && (
              <CircularProgress
                size={18}
                style={{
                  marginLeft: '1rem',
                  color: theme.buttons.primary.preloaderColor,
                }}
              />
            )}
          </PrimaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default UserAddDialog
