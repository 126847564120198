import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { IconButton, SelectChangeEvent, Stack } from '@mui/material'
import PeriodService from '../../../../services/period.service'
import CompanyService from '../../../../services/company.service'
import UserPlanService from '../../../../services/userPlan.service'
import { useTranslation } from 'react-i18next'
import UserPlansToolbar from '../partials/UserPlansToolbar'
import {
  escapeRegExp,
  getHiddenColumns,
  getTableState,
  setTableState,
  thousandsSeparator,
} from '../../../../helpers/utils'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { Period } from '../../../../store/Period/types'
import { UserPlan, UserPlanResult } from '../../../../store/UserPlan/types'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import * as XLSX from 'xlsx'
import UserPlanDialog from '../partials/UserPlanDialog'
import { errorHandler } from '../../../../helpers/errorHandler'
import { Link } from 'react-router-dom'
import { ReactComponent as SetPlansIcon } from '../../../../assets/images/icons/set_plans.svg'
import { User } from '../../../../store/Auth/types'
import { Column } from 'react-table'
import Table from '../../../Table/Table'
import { Option } from '../../../../store/types'
import { styled } from '@mui/material/styles'

const StyledContainer = styled('div')(({ theme }) => ({
  tbody: {
    tr: {
      td: {
        '&:first-of-type': {
          padding: '0px',
          display: 'flex !important',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    },
  },
}))

type UserPlansListProps = {
  path: string
  user: User
}

interface Legend extends UserPlanResult {
  visible: boolean
}

const UserPlansList: FunctionComponent<UserPlansListProps> = ({
  path,
  user,
}) => {
  const tableName = 'user-plans'
  const searchState = getTableState(tableName, 'search')
  const periodState = getTableState(tableName, 'period')
  const companiesState = getTableState(tableName, 'companies')

  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
  const [legend, setLegend] = useState<Legend[]>([])
  const [userPlansList, setUserPlansList] = useState<UserPlan[]>([])
  const [filteredUserPlansList, setFilteredUserPlansList] = useState<
    UserPlan[]
  >([])
  const [searchText, setSearchText] = useState<string>(
    searchState ? searchState : '',
  )
  const [periodValue, setPeriodValue] = useState<string>(
    periodState ? periodState : '',
  )
  const [userId, setUserId] = useState<number | null>(null)
  const [periods, setPeriods] = useState<Period[]>([])
  const [companies, setCompanies] = useState<Option[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<Option[]>(
    companiesState ? JSON.parse(companiesState) : [],
  )
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])
  const [openUserPlanDialog, setUserPlanDialogOpen] = useState(false)
  const [refresh, setRefresh] = useState(false)

  const handleUserPlanDialogClickOpen = (userId: number) => {
    setUserId(userId)
    setUserPlanDialogOpen(true)
  }

  const handleUserPlanDialogClose = (refreshTable: boolean = false) => {
    setUserPlanDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const requestSearch = (listToFilter: UserPlan[], searchValue: string) => {
    let filteredRows: UserPlan[] = listToFilter

    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')
    filteredRows = filteredRows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return row[field] && searchRegex.test(row[field].toString())
      })
    })
    const filteredUserPlansListWithVisibility = filteredRows.map((userPlan) => {
      return {
        ...userPlan,
        planResults: userPlan.planResults.filter((planResult) =>
          legend
            .filter((l) => l.visible)
            .map((l2) => l2.id)
            .includes(planResult.id),
        ),
      }
    })
    setFilteredUserPlansList(filteredUserPlansListWithVisibility)
    setTableColumns(
      generateTableColumns(
        filteredUserPlansListWithVisibility,
        parseInt(periodValue),
      ),
    )
  }

  const generateTableColumns = useCallback(
    (userPlans: UserPlan[], periodId: number) => {
      const columns = []
      columns.push({
        Header: ' ',
        sticky: 'left',
        columns: [
          {
            accessor: 'edit',
            Header: '',
            sticky: 'left',
            width: 55,
            disableSortBy: true,
            Cell: (params: any) => (
              <IconButton
                onClick={() =>
                  handleUserPlanDialogClickOpen(params.row.values.id)
                }
                size="small"
                style={{
                  padding: 0,
                  opacity:
                    params.row.original.planResults.length === 0 ? 0.5 : 1,
                }}
                disabled={params.row.original.planResults.length === 0}
              >
                <SetPlansIcon />
              </IconButton>
            ),
          },
          {
            Header: t('pages.userPlans.table.id').toString(),
            accessor: 'id',
            sticky: 'left',
            width: 90,
            Cell: (params: any) => (
              <div style={{ width: '100%', textAlign: 'right' }}>
                <Link
                  to={`/store-plans/${params.row.values.id}/${periodId}`}
                  style={{ color: 'rgba(0,0,0,.87)' }}
                >
                  {params.value}
                </Link>
              </div>
            ),
          },
          {
            accessor: 'username',
            Header: t('pages.userPlans.table.username').toString(),
            sticky: 'left',
            width: 260,
            Cell: (params: any) => (
              <Link
                to={`/store-plans/${params.row.values.id}/${periodId}`}
                style={{ color: 'rgba(0,0,0,.87)' }}
              >
                {params.value}
              </Link>
            ),
          },
          {
            accessor: 'userTypeName',
            Header: t('pages.userPlans.table.userTypeName').toString(),
            sticky: 'left',
            width: 80,
            Cell: (params: any) => (
              <Link
                to={`/store-plans/${params.row.values.id}/${periodId}`}
                style={{ color: 'rgba(0,0,0,.87)' }}
              >
                {params.value}
              </Link>
            ),
          },
          {
            accessor: 'regionName',
            Header: t('pages.userPlans.table.regionName').toString(),
            sticky: 'left',
            width: 170,
            Cell: (params: any) => (
              <Link
                to={`/store-plans/${params.row.values.id}/${periodId}`}
                style={{ color: 'rgba(0,0,0,.87)' }}
              >
                {params.value}
              </Link>
            ),
          },
          {
            accessor: 'storesAmount',
            Header: t('pages.userPlans.table.storesAmount').toString(),
            sticky: 'left',
            width: 85,
            Cell: (params: any) => (
              <div style={{ width: '100%', textAlign: 'right' }}>
                <Link
                  to={`/store-plans/${params.row.values.id}/${periodId}`}
                  style={{ color: 'rgba(0,0,0,.87)' }}
                >
                  {params.value}
                </Link>
              </div>
            ),
          },
        ],
      })

      userPlans.forEach((userPlan, j) =>
        userPlan.planResults.forEach((planResult, i) => {
          if (j === 0) {
            columns.push({
              Header: planResult.name,
              columns: [
                {
                  id: `${t('pages.userPlans.table.planValue')}--${i}`,
                  Header: t('pages.userPlans.table.planValue').toString(),
                  width: 90,
                  accessor: (data: any) => {
                    return data.planResults[i]
                      ? data.planResults[i].planValue
                      : 0
                  },
                  Cell: (params: any) => (
                    <div style={{ width: '100%', textAlign: 'right' }}>
                      {params.value !== null
                        ? thousandsSeparator(params.value)
                        : t('common.notSet')}
                    </div>
                  ),
                },
                {
                  id: `${t('pages.userPlans.table.resultValue')}--${i}`,
                  Header: t('pages.userPlans.table.resultValue').toString(),
                  width: 90,
                  accessor: (data: any) => {
                    return data.planResults[i]
                      ? data.planResults[i].resultValue
                      : 0
                  },
                  Cell: (params: any) => (
                    <div style={{ width: '100%', textAlign: 'right' }}>
                      {params.value !== null
                        ? thousandsSeparator(params.value)
                        : t('common.notSet')}
                    </div>
                  ),
                },
                {
                  id: `${t('pages.userPlans.table.resultPercentValue')}--${i}`,
                  Header: t(
                    'pages.userPlans.table.resultPercentValue',
                  ).toString(),
                  width: 100,
                  accessor: (data: any) => {
                    return data.planResults[i]
                      ? data.planResults[i].resultPercentValue
                      : 0
                  },
                  Cell: (params: any) => (
                    <div style={{ width: '100%', textAlign: 'right' }}>
                      {params.value !== null
                        ? `${thousandsSeparator(params.value)}%`
                        : t('common.notSet')}
                    </div>
                  ),
                },
              ],
            })
          }
        }),
      )
      return columns
    },
    [t],
  )

  useEffect(() => {
    const fetchData = async () => {
      setLoadingTableData(true)
      try {
        const periodListResponse = await PeriodService.getPeriodList()

        if (periodListResponse.data.periodList) {
          setPeriods(periodListResponse.data.periodList)

          const companyListResponse = await CompanyService.getCompanyList()

          if (companyListResponse.data.companies) {
            const multiSelectOptions: Option[] = []
            companyListResponse.data.companies.forEach((company) =>
              multiSelectOptions.push({
                value: company.id,
                label: company.name,
              }),
            )
            setCompanies(multiSelectOptions)
            let tmpSelectedCompanies: Option[]
            if (companiesState) {
              tmpSelectedCompanies = JSON.parse(companiesState)
            } else {
              tmpSelectedCompanies = multiSelectOptions
            }
            setSelectedCompanies(tmpSelectedCompanies)

            let activeNewestOrSavedPeriodId = ''

            if (periodState) {
              activeNewestOrSavedPeriodId = periodState
            } else {
              activeNewestOrSavedPeriodId =
                periodListResponse.data.periodList
                  .find((period) => period.isActive)
                  ?.id.toString() ||
                periodListResponse.data.periodList[
                  periodListResponse.data.periodList.length - 1
                ].id.toString()
            }

            setPeriodValue(activeNewestOrSavedPeriodId)
            const userPlansResponse = await UserPlanService.getUserPlans(
              parseInt(activeNewestOrSavedPeriodId),
              tmpSelectedCompanies.map((company) => company.value),
            )

            if (userPlansResponse.data.userPlans) {
              setUserPlansList(userPlansResponse.data.userPlans)

              if (userPlansResponse.data.userPlans.length > 0) {
                const hiddenColumns = getHiddenColumns(
                  'plans-tooltips',
                  userPlansResponse.data.userPlans[0].planResults.map((plan) =>
                    plan.id.toString(),
                  ),
                )
                const legendWithHiddenColumns =
                  userPlansResponse.data.userPlans[0].planResults.map(
                    (plan) => {
                      return {
                        ...plan,
                        visible: !hiddenColumns.includes(plan.id.toString()),
                      }
                    },
                  )
                setLegend(legendWithHiddenColumns)
                const filteredUserPlansListWithVisibility =
                  userPlansResponse.data.userPlans.map((userPlan) => {
                    return {
                      ...userPlan,
                      planResults: userPlan.planResults.filter((planResult) =>
                        legendWithHiddenColumns
                          .filter((l) => l.visible)
                          .map((l2) => l2.id)
                          .includes(planResult.id),
                      ),
                    }
                  })
                setFilteredUserPlansList(filteredUserPlansListWithVisibility)
                setTableColumns(
                  generateTableColumns(
                    filteredUserPlansListWithVisibility,
                    parseInt(activeNewestOrSavedPeriodId),
                  ),
                )
              } else {
                setLegend([])
                setFilteredUserPlansList(userPlansResponse.data.userPlans)
                setTableColumns(
                  generateTableColumns(
                    userPlansResponse.data.userPlans,
                    parseInt(activeNewestOrSavedPeriodId),
                  ),
                )
              }
            }
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
        setLoadingTableData(false)
      }
    }
    setSearchText('')
    fetchData()
  }, [path, t, generateTableColumns, companiesState, periodState, refresh])

  const downloadXLSX = (data: UserPlan[], name: string) => {
    // prepare data for export
    const preparedData = data.map((d) => {
      const dataToReturn: any = {
        id: d.id,
        username: d.username,
        userTypeName: d.userTypeName,
        regionName: d.regionName,
        storesAmount: d.storesAmount,
      }
      d.planResults.forEach((plan) => {
        dataToReturn[`${plan.name} planValue`] = plan.planValue
        dataToReturn[`${plan.name} resultValue`] = plan.resultValue
        dataToReturn[`${plan.name} resultPercentValue`] =
          plan.resultPercentValue
      })
      return dataToReturn
    })

    const fileName = `${name}.xlsx`
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(preparedData)
    const wb: XLSX.WorkBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, name)

    XLSX.writeFile(wb, fileName)
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <StyledContainer>
          <Stack display="flex" alignContent="end">
            <SecondaryButton
              variant="contained"
              onClick={() => downloadXLSX(filteredUserPlansList, tableName)}
              sx={{ marginLeft: 'auto' }}
            >
              {t('common.downloadTableAsXLSX')}
            </SecondaryButton>
          </Stack>
          <UserPlansToolbar
            user={user}
            periods={periods}
            companies={companies}
            selectedCompanies={selectedCompanies}
            value={searchText}
            legend={legend}
            onChange={(event: { target: { value: string } }) => {
              setSearchText(event.target.value)
              setTableState(tableName, 'search', event.target.value)
              requestSearch(userPlansList, event.target.value)
            }}
            periodValue={periodValue}
            filterPeriod={(event: SelectChangeEvent) => {
              setPeriodValue(event.target.value)
              setTableState(tableName, 'period', event.target.value)
              requestSearch(userPlansList, searchText)
            }}
            setSelectedCompanies={(companies: Option[]) => {
              setTableState(tableName, 'companies', JSON.stringify(companies))
              setSelectedCompanies(companies)
            }}
            clearSearch={() => {
              setSearchText('')
              setTableState(tableName, 'search', '')
              requestSearch(userPlansList, '')
            }}
            togglePlanVisibility={(planId: number) => {
              let updatedLegend = legend.map((el) => {
                if (el.id === planId) {
                  el.visible = !el.visible
                }
                return el
              })
              setLegend(updatedLegend)
              requestSearch(userPlansList, searchText)
            }}
          />
          <Table
            name={tableName}
            columns={tableColumns}
            data={filteredUserPlansList}
            height="calc(100vh - 300px)"
            loading={loadingTableData}
          />
          {userId && (
            <UserPlanDialog
              open={openUserPlanDialog}
              handleClose={handleUserPlanDialogClose}
              periodId={parseInt(periodValue)}
              userId={userId}
            />
          )}
        </StyledContainer>
      )}
    </>
  )
}

export default UserPlansList
