import axios from 'axios'
import { ManufacturerListReponse } from '../store/Manufacturer/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getManufacturerList = (): Promise<ManufacturerListReponse> => {
  return axios.get(API_URL + '/api/admin/manufacturer', {
    headers: authHeader(),
  })
}

const exportedObject = {
  getManufacturerList,
}

export default exportedObject
