import { styled } from '@mui/material/styles'
import { Button, ButtonProps } from '@mui/material'

const PrimaryButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.buttons.color,
  backgroundColor: theme.buttons.primary.backgroundColor,
  borderColor: theme.buttons.primary.border.color,
  borderWidth: theme.buttons.border.width,
  borderStyle: theme.buttons.border.style,
  padding: theme.buttons.primary.padding,
  textTransform: theme.buttons.textTransform,
  borderRadius: theme.buttons.borderRadius,
  boxShadow: theme.buttons.boxShadow,
  '&:hover': {
    backgroundColor: theme.buttons.primary.backgroundColorHover,
    boxShadow: theme.buttons.boxShadow,
  },
  '&:disabled': {
    backgroundColor: theme.buttons.primary.backgroundColorDisabled,
    borderColor: theme.buttons.primary.borderDisabled.color,
    color: theme.buttons.primary.colorDisabled,
  },
}))

export default PrimaryButton
