import axios from 'axios'
import {
  ConfirmPlanValuesParams,
  ConfirmPlanValuesResponse,
  ImportAcceptPlanResponse,
  ImportAcceptResultResponse,
  ImportPreparePlanParams,
  ImportPreparePlanResponse,
  ImportPrepareResultParams,
  ImportPrepareResultResponse,
  UserPlanEditResponse,
  UserPlansResponse,
} from '../store/UserPlan/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const importPreparePlan = async (
  params: ImportPreparePlanParams,
): Promise<ImportPreparePlanResponse> => {
  return await axios.post(
    API_URL + '/api/admin/user-plan/import/prepare-plan',
    params,
    {
      headers: authHeader(),
    },
  )
}

const importAcceptPlan = async (
  planId: number,
  companyId: number | null,
): Promise<ImportAcceptPlanResponse> => {
  return await axios.post(
    API_URL + '/api/admin/user-plan/import/accept-plan',
    {
      planId,
      companyId,
    },
    {
      headers: authHeader(),
    },
  )
}

const importPrepareResult = async (
  params: ImportPrepareResultParams,
): Promise<ImportPrepareResultResponse> => {
  return await axios.post(
    API_URL + '/api/admin/user-plan/import/prepare-result',
    params,
    {
      headers: authHeader(),
    },
  )
}

const importAcceptResult = async (
  planId: number,
): Promise<ImportAcceptResultResponse> => {
  return await axios.post(
    API_URL + '/api/admin/user-plan/import/accept-result',
    {
      planId,
    },
    {
      headers: authHeader(),
    },
  )
}

const getUserPlans = async (
  periodId: number,
  companies: number[],
): Promise<UserPlansResponse> => {
  return await axios.post(
    API_URL + '/api/admin/user-plan/summary/overall',
    {
      periodId,
      companies,
    },
    {
      headers: authHeader(),
    },
  )
}

const getPlanValuesByUserIdAndPeriodId = async (
  periodId: number,
  userId: number,
): Promise<UserPlanEditResponse> => {
  return await axios.post(
    API_URL + '/api/admin/user-plan/plan/edit',
    {
      periodId,
      userId,
    },
    {
      headers: authHeader(),
    },
  )
}

const confirmPlanValuesByUserIdAndPeriodId = async (
  params: ConfirmPlanValuesParams,
): Promise<ConfirmPlanValuesResponse> => {
  return await axios.post(
    API_URL + '/api/admin/user-plan/plan/confirm',
    params,
    {
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  importPreparePlan,
  importPrepareResult,
  importAcceptPlan,
  importAcceptResult,
  getUserPlans,
  getPlanValuesByUserIdAndPeriodId,
  confirmPlanValuesByUserIdAndPeriodId,
}

export default exportedObject
