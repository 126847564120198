import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import HomeService from '../../../../services/home.service'
import RegionService from '../../../../services/region.service'
import { UserRanking } from '../../../../store/Home/types'
import {
  Card,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import Carousel from 'react-elastic-carousel'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import UserProfileImage from '../../../shared/UserProfileImage'
import { escapeRegExp } from '../../../../helpers/utils'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import * as XLSX from 'xlsx'

import './UsersRanking.css'
import { Region } from '../../../../store/Region/types'
import { ReactComponent as XpIcon } from '../../../../assets/images/icons/xp_icon.svg'
import { ReactComponent as VpIcon } from '../../../../assets/images/icons/vp_icon.svg'
import { ReactComponent as GoldStar } from '../../../../assets/images/icons/golden_star.svg'
import { ReactComponent as SilverStar } from '../../../../assets/images/icons/silver_star.svg'
import { ReactComponent as BronzeStar } from '../../../../assets/images/icons/bronze_star.svg'
import { ReactComponent as BlueStar } from '../../../../assets/images/icons/blue_star.svg'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'

type UsersRankingProps = {}

const UsersRanking: React.FunctionComponent<UsersRankingProps> = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [users, setUsers] = useState<UserRanking[]>([])
  const [filteredUsers, setFilteredUsers] = useState<UserRanking[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [regions, setRegions] = useState<Region[]>([])
  const [regionValue, setRegionValue] = useState<string>('all')

  const breakPoints: {
    width: number
    itemsToShow: number
    itemsToScroll: number
  }[] = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1200, itemsToShow: 6, itemsToScroll: 6 },
  ]

  const requestSearch = (
    searchValue: string,
    filters: { column: string; value: string }[],
  ) => {
    let filteredRows: UserRanking[] = users

    if (filters.length > 0) {
      filters.forEach((filter) => {
        filteredRows = filteredRows.filter((user) => {
          if (filter.value === 'all') {
            return true
          }
          if (
            (user as any)[filter.column].toString() === filter.value.toString()
          ) {
            return true
          }
          return false
        })
      })
    }

    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')
    filteredRows = filteredRows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return row[field] && searchRegex.test(row[field].toString())
      })
    })
    setFilteredUsers(filteredRows)
  }

  const handleSearch = (event: { target: { value: string } }) => {
    setSearchValue(event.target.value)
    requestSearch(event.target.value, [
      {
        column: 'regionId',
        value: regionValue,
      },
    ])
  }

  const clearSearch = () => {
    setSearchValue('')
    requestSearch('', [
      {
        column: 'regionId',
        value: regionValue,
      },
    ])
  }

  const filterRegion = (event: SelectChangeEvent) => {
    setRegionValue(event.target.value)
    requestSearch(searchValue, [
      {
        column: 'regionId',
        value: event.target.value,
      },
    ])
  }

  const downloadXLSX = (data: any, name: string) => {
    const fileName = `${name}.xlsx`
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data)
    const wb: XLSX.WorkBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, name)

    XLSX.writeFile(wb, fileName)
  }

  const renderStar = (awardGroup: number) => {
    switch (awardGroup) {
      case 1:
        return (
          <GoldStar
            style={{
              width: '26px',
              height: '26px',
            }}
          />
        )
      case 2:
        return (
          <SilverStar
            style={{
              width: '26px',
              height: '26px',
            }}
          />
        )
      case 3:
        return (
          <BronzeStar
            style={{
              width: '26px',
              height: '26px',
            }}
          />
        )
      case 4:
        return (
          <BlueStar
            style={{
              width: '26px',
              height: '26px',
            }}
          />
        )
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersRankingResponse = await HomeService.getUsersRanking()

        if (usersRankingResponse.data.users) {
          setUsers(usersRankingResponse.data.users)
          setFilteredUsers(usersRankingResponse.data.users)
        }

        const regionListResponse = await RegionService.getRegionList()

        if (regionListResponse.data.regions) {
          setRegions(regionListResponse.data.regions)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t])

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <div
          style={{
            background: '#f1f1f1',
            borderRadius: '3px',
            margin: '0 20px',
            padding: '15px 20px',
            height: '100%',
          }}
        >
          <Stack
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="space-between"
            mb={1}
          >
            <Stack flexDirection="row" alignItems="flex-end">
              <FormControl size="small" style={{ marginRight: '10px' }}>
                <label style={{ fontSize: '12px', fontWeight: 'bold' }}>
                  Region
                </label>
                <Select
                  id="region-select"
                  onChange={filterRegion}
                  value={regionValue}
                  defaultValue={regionValue}
                  size="small"
                  style={{ backgroundColor: '#fff' }}
                >
                  <MenuItem value="all">Wszystkie</MenuItem>
                  {regions.map((region) => (
                    <MenuItem value={region.id} key={region.id}>
                      {region.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <SecondaryButton
                onClick={() => downloadXLSX(users, 'ranking')}
                style={{ padding: '7px 20px' }}
              >
                .xlsx
              </SecondaryButton>
            </Stack>
            <FormControl size="small">
              <label style={{ fontSize: '12px', fontWeight: 'bold' }}>
                Wyszukiwarka
              </label>
              <TextField
                variant="outlined"
                size="small"
                value={searchValue}
                onChange={handleSearch}
                placeholder={t('pages.challenge.table.search')}
                style={{ backgroundColor: '#fff' }}
                InputProps={{
                  startAdornment: <SearchIcon fontSize="small" />,
                  endAdornment: (
                    <IconButton
                      title="Clear"
                      aria-label="Clear"
                      size="small"
                      style={{ visibility: searchValue ? 'visible' : 'hidden' }}
                      onClick={clearSearch}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
              />
            </FormControl>
          </Stack>
          <Carousel
            isRTL={false}
            enableAutoPlay={false}
            enableMouseSwipe={false}
            enableSwipe={false}
            transitionMs={700}
            breakPoints={breakPoints}
            pagination={false}
          >
            {filteredUsers.length === 0 && (
              <div
                style={{
                  height: '210px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Brak danych...
              </div>
            )}
            {filteredUsers.map((user) => (
              <Card
                key={user.id}
                elevation={0}
                style={{
                  maxWidth: '300px',
                  width: '100%',
                  height: '100%',
                  padding: '15px',
                  textAlign: 'center',
                  marginTop: '30px',
                  marginLeft: '10px',
                  marginRight: '10px',
                  overflow: 'visible',
                  position: 'relative',
                }}
              >
                <div
                  style={{ position: 'absolute', top: '-10px', left: '-5px' }}
                >
                  <EmojiEventsIcon color="primary" fontSize="large" />
                  <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                    {user.rankPlace}
                  </span>
                </div>
                {!user.awardGroup && (
                  <div
                    style={{ position: 'absolute', top: '0px', right: '10px' }}
                  >
                    <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                      L{user.currentLevel}
                    </span>
                  </div>
                )}
                {user.awardGroup && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '-10px',
                      right: '10px',
                    }}
                  >
                    {renderStar(user.awardGroup)}
                  </div>
                )}
                <UserProfileImage
                  userId={user.id}
                  firstname={user.firstname}
                  lastname={user.lastname}
                  style={{
                    width: 70,
                    height: 70,
                    border: '7px solid #fff',
                    marginTop: '-40px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                />
                <Typography
                  variant="body2"
                  display="block"
                  gutterBottom
                  fontWeight="bold"
                  minHeight={40}
                >
                  {user.firstname} {user.lastname}
                </Typography>
                <Typography
                  variant="caption"
                  gutterBottom
                  textAlign="center"
                  noWrap
                  letterSpacing="0"
                  style={{
                    backgroundColor: '#f1f1f1',
                    padding: '2px 7px',
                    fontSize: '14px',
                  }}
                >
                  {user.regionName}
                </Typography>
                <Grid container spacing={1} mt={1}>
                  <Grid item xs={6}>
                    <Typography
                      variant="caption"
                      display="flex"
                      fontWeight="bold"
                      textAlign="left"
                      alignItems="center"
                    >
                      <VpIcon
                        style={{
                          width: '20px',
                          height: '20px',
                          marginRight: '5px',
                        }}
                      />
                      {user.vpAmount.toLocaleString()}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="caption"
                      display="flex"
                      fontWeight="bold"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <XpIcon
                        style={{
                          width: '20px',
                          height: '20px',
                          marginRight: '5px',
                        }}
                      />
                      {user.xpAmount.toLocaleString()}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            ))}
          </Carousel>
        </div>
      )}
    </>
  )
}

export default UsersRanking
