import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
  Alert,
  AlertColor,
  Collapse,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import { styled } from '@mui/material/styles'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import * as XLSX from 'xlsx'
import { errorHandler } from '../../../../helpers/errorHandler'
import {
  ChallengeResultsImport,
  ChallengeResultsImportData,
  ChallengeResultsImportParams,
} from '../../../../store/Challenge/types'
import ChallengeService from '../../../../services/challenge.service'

const { read, utils } = XLSX

type ChallengeResultsImportDialogProps = {
  open: boolean
  handleClose: (refresh: boolean) => void
  challengeId: number
}

const Input = styled('input')({
  display: 'none',
})

const ChallengeResultsImportDialog: React.FunctionComponent<ChallengeResultsImportDialogProps> =
  ({ open, handleClose, challengeId }) => {
    const { t } = useTranslation()
    const [dataObj, setDataObj] = useState<ChallengeResultsImportData[]>([])
    const [savingImport, setSavingImport] = useState<boolean>(false)
    const [challengeResultsImport, setChallengeResultsImport] =
      useState<ChallengeResultsImportParams | null>(null)
    const [alertOpen, setAlertOpen] = useState<boolean>(true)
    const [alertData, setAlertData] = useState({
      type: 'error' as AlertColor,
      message: '',
    })

    const uploadInputRef = useRef<HTMLInputElement>(null)

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()

      if (e && e.target && e.target.files && e.target.files.length > 0) {
        var files = e.target.files,
          f = files[0]
        var reader = new FileReader()
        reader.onload = function (e) {
          if (e && e.target) {
            var data = e.target.result
            let readedData = read(data, { type: 'binary' })
            const wsname = readedData.SheetNames[0]
            const ws = readedData.Sheets[wsname]

            /* Convert array to json*/
            const dataParse = utils.sheet_to_json<ChallengeResultsImportData>(
              ws,
              {
                blankrows: false,
                defval: null,
              },
            )
            setDataObj(dataParse)
          }
        }
        reader.readAsBinaryString(f)
        e.target.value = ''
      }
    }

    const saveImport = async () => {
      if (challengeResultsImport) {
        try {
          setSavingImport(true)
          const importResponse =
            await ChallengeService.importUserChallengeResults(
              challengeResultsImport,
            )

          if (importResponse.data.success) {
            setAlertData({
              type: 'success' as AlertColor,
              message: t('messages.success.challengeLevelsImportSuccessful', {
                added: importResponse.data.addedUsers?.length,
                skipped: importResponse.data.skippedUsers?.length,
              }),
            })
            setAlertOpen(true)
          } else {
            setAlertData({
              type: 'error' as AlertColor,
              message: t('pages.challenge.import.messages.importError'),
            })
            setAlertOpen(true)
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setChallengeResultsImport(null)
          setSavingImport(false)
        }
      }
    }

    useEffect(() => {
      let userChallengeResultsData: ChallengeResultsImport[] = []
      var BreakException = {}
      let fileError = {
        type: 'error' as AlertColor,
        message: '',
      }

      setAlertOpen(false)
      setAlertData(fileError)
      setChallengeResultsImport(null)

      try {
        dataObj.forEach((element, i) => {
          if (!element.hasOwnProperty('user_id')) {
            fileError = {
              type: 'error',
              message: t('pages.challenge.import.messages.columnMissing', {
                column: 'user_id',
                line: i + 1,
              }),
            }
            throw BreakException
          } else if (!element.hasOwnProperty('value')) {
            fileError = {
              type: 'error',
              message: t('pages.challenge.import.messages.columnMissing', {
                column: 'value',
                line: i + 1,
              }),
            }
            throw BreakException
          } else {
            userChallengeResultsData.push({
              userId: element.user_id,
              value: element.value,
            })
          }
        })
      } catch (e) {
        if (e !== BreakException) {
          throw e
        } else {
          userChallengeResultsData = []
          setAlertOpen(true)
          setAlertData(fileError)
        }
      } finally {
        if (userChallengeResultsData.length > 0 && challengeId) {
          setAlertOpen(true)
          setAlertData({
            type: 'info' as AlertColor,
            message: t('pages.challenge.import.messages.fileReadyToImport', {
              length: userChallengeResultsData.length,
            }),
          })
          setChallengeResultsImport({
            challengeId,
            data: userChallengeResultsData,
          })
        }
      }
    }, [dataObj, challengeId, t])

    useEffect(() => {
      if (open) {
        setAlertData({
          type: 'error' as AlertColor,
          message: '',
        })
        setAlertOpen(false)
        setDataObj([])
      }
    }, [open])

    return (
      <>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={() => handleClose(false)}
        >
          <DialogTitle>
            <Typography variant="body1" fontWeight="bold">
              {t('pages.challenge.dialog.importTitle', {
                challengeId,
              })}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Input
              ref={uploadInputRef}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
              id="contained-button-file"
              multiple
              type="file"
              onChange={onFileChange}
            />
            <label htmlFor="contained-button-file">
              <SecondaryButton
                onClick={() => {
                  if (challengeId) {
                    uploadInputRef.current && uploadInputRef.current.click()
                  }
                }}
              >
                {t('pages.challenge.import.selectFile')}
              </SecondaryButton>
            </label>
            <Collapse in={alertOpen}>
              <Alert
                severity={alertData.type}
                sx={{ mt: 2 }}
                style={{ whiteSpace: 'pre-line' }}
              >
                {alertData.message}
              </Alert>
            </Collapse>
          </DialogContent>
          <DialogActions>
            <Stack flexDirection="row" justifyContent="flex-end" width="100%">
              <SecondaryButton onClick={() => handleClose(false)}>
                {t('common.close')}
              </SecondaryButton>
              {challengeResultsImport && (
                <PrimaryButton
                  onClick={saveImport}
                  disabled={savingImport || !challengeId}
                  style={{ marginLeft: '10px' }}
                >
                  {t('common.import')}
                </PrimaryButton>
              )}
            </Stack>
          </DialogActions>
        </Dialog>
      </>
    )
  }

export default ChallengeResultsImportDialog
