import axios from 'axios'
import {
  HomePlanResultChartReponse,
  HomePlanResultListReponse,
  UsersRankingResponse,
} from '../store/Home/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getHomePlanResult = (
  regions: number[],
): Promise<HomePlanResultListReponse> => {
  return axios.post(
    API_URL + '/api/admin/home/plan/result',
    {
      regions,
    },
    {
      headers: authHeader(),
    },
  )
}

const getHomePlanResultChart = (
  periodId: number,
): Promise<HomePlanResultChartReponse> => {
  return axios.get(API_URL + `/api/admin/home/plan/result/chart/${periodId}`, {
    headers: authHeader(),
  })
}

const getUsersRanking = (): Promise<UsersRankingResponse> => {
  return axios.get(API_URL + '/api/admin/home/user', {
    headers: authHeader(),
  })
}

const exportedObject = {
  getHomePlanResult,
  getHomePlanResultChart,
  getUsersRanking,
}

export default exportedObject
