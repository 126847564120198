import axios from 'axios'
import {
  PasswordResetCheckResponse,
  PasswordResetConfirmResponse,
  PasswordResetResponse,
} from '../store/Password/types'

const API_URL = process.env.REACT_APP_API_URL

const passwordReset = (username: string): Promise<PasswordResetResponse> => {
  return axios.post(API_URL + '/api/password/reset', {
    username,
  })
}

const passwordResetCheck = (
  requestUuid: string,
): Promise<PasswordResetCheckResponse> => {
  return axios.post(API_URL + '/api/password/reset/check', {
    requestUuid,
  })
}

const passwordResetConfirm = (
  newPassword: string,
  newPasswordConfirm: string,
  requestUuid: string,
): Promise<PasswordResetConfirmResponse> => {
  return axios.post(API_URL + '/api/password/reset/confirm', {
    newPassword,
    newPasswordConfirm,
    requestUuid,
  })
}

const exportedObject = {
  passwordReset,
  passwordResetCheck,
  passwordResetConfirm,
}

export default exportedObject
