import axios from 'axios'
import {
  ImageListReponse,
  ImageTypesReponse,
  ImageContentResponse,
  UserImageResponse,
} from '../store/Image/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getImageTypeList = (): Promise<ImageTypesReponse> => {
  return axios.get(API_URL + '/api/admin/image/type', {
    headers: authHeader(),
  })
}

const getImageList = (
  imageTypeCode: string,
  limit: number,
  offset: number,
): Promise<ImageListReponse> => {
  return axios.get(
    API_URL +
      `/api/admin/image/type/${imageTypeCode}?limit=${limit}&offset=${offset}`,
    {
      headers: authHeader(),
    },
  )
}

const getImageContent = (imageId: number): Promise<ImageContentResponse> => {
  return axios.get(API_URL + `/api/admin/image/content/${imageId}`, {
    headers: authHeader(),
  })
}

const getUserProfileImage = (userId: number): Promise<UserImageResponse> => {
  return axios.get(API_URL + `/api/admin/image/user/${userId}`, {
    headers: authHeader(),
  }) 
}

const exportedObject = {
  getImageTypeList,
  getImageList,
  getImageContent,
  getUserProfileImage,
}

export default exportedObject
