import React, { FunctionComponent, useEffect, useState } from 'react'
import { Grid, List, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ChallengeService from '../../../../services/challenge.service'
import CompanyService from '../../../../services/company.service'
import ImageService from '../../../../services/image.service'
import ManufacturerService from '../../../../services/manufacturer.service'
import {
  ChallengeDetails,
  ChallengeUnit,
} from '../../../../store/Challenge/types'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { handleNavigationClick } from '../../../../helpers/utils'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import { isSuperAdmin, isCentralAdmin } from '../../../../helpers/checkRole'
import { useSelector, RootStateOrAny } from 'react-redux'
import { Image } from '../../../../store/Image/types'
import { Company } from '../../../../store/Company/types'
import { Manufacturer } from '../../../../store/Manufacturer/types'

const ChallengeContainer = styled('div')(({ theme }) => ({
  'width-100': {
    width: '100%',
  },
  '.header': {
    fontSize: '1.25rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 20,
    '&_number': {
      backgroundColor: theme.colorsPalette.gray.gray5,
      color: '#FFF',
      width: 46,
      height: 46,
      borderRadius: 23,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '&_txt': {
      color: theme.colorsPalette.gray.gray5,
      fontWeight: 'bold',
      marginLeft: 10,
    },
  },
  '.challenges-main-grid_item': {
    paddingBottom: 20,
    paddingRight: 16,
    '&:not(:first-of-type)': {
      borderTop: '1px solid',
      borderColor: theme.colorsPalette.gray.gray3,
    },
  },
}))

type ChallengeShowProps = {}
type ChallengeParams = {
  id: string
}

const ChallengeShow: FunctionComponent<ChallengeShowProps> = () => {
  let { id } = useParams<ChallengeParams>()
  const { t } = useTranslation()
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )
  const [challengeDetails, setChallengeDetails] =
    useState<ChallengeDetails | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [logo, setLogo] = useState<Image | null>(null)
  const [image, setImage] = useState<Image | null>(null)
  const [unit, setUnit] = useState<ChallengeUnit | null>(null)
  const [companies, setCompanies] = useState<Company[]>([])
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const challengeDetailsResponse =
          await ChallengeService.getChallengeDetails(parseInt(id))
        if (challengeDetailsResponse.data) {
          setChallengeDetails(challengeDetailsResponse.data)

          if (isSuperAdmin(currentUser) || isCentralAdmin(currentUser)) {
            const companyListResponse = await CompanyService.getCompanyList()

            if (
              companyListResponse.data.companies &&
              challengeDetailsResponse.data.companies
            ) {
              const selectedCompanies: Company[] = []
              challengeDetailsResponse.data.companies.forEach((company) => {
                const foundCompany = companyListResponse.data.companies.find(
                  (c) => c.id === company.id,
                )
                if (foundCompany) {
                  selectedCompanies.push(foundCompany)
                }
              })
              setCompanies(selectedCompanies)
            }
          }
          const manufacturerListResponse =
            await ManufacturerService.getManufacturerList()

          if (manufacturerListResponse.data.manufacturers) {
            setManufacturers(manufacturerListResponse.data.manufacturers)
          }
          if (challengeDetailsResponse.data.logoId) {
            const logoResponse = await ImageService.getImageContent(
              challengeDetailsResponse.data.logoId,
            )

            if (logoResponse) {
              setLogo(logoResponse.data)
            }
          }
          if (challengeDetailsResponse.data.imageId) {
            const imageResponse = await ImageService.getImageContent(
              challengeDetailsResponse.data.imageId,
            )

            if (imageResponse) {
              setImage(imageResponse.data)
            }
          }
          if (challengeDetailsResponse.data.challengeUnitId) {
            const unitListResponse =
              await ChallengeService.getChallengeUnitList()

            if (unitListResponse.data.challengeUnits) {
              setUnit(
                unitListResponse.data.challengeUnits.find(
                  (unit) =>
                    unit.id === challengeDetailsResponse.data.challengeUnitId,
                ) || null,
              )
            }
          }
        }
      } catch (error) {
        const _content =
          // (error.response && error.response.data) ||
          (error as Error).message || (error as Error).toString()

        console.warn(_content)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [id, currentUser])

  if (!challengeDetails) return null

  return (
    <ChallengeContainer>
      {loading && <LoadingSpinner />}
      {!loading && (
        <Grid className="challenges-main-grid" container spacing={2}>
          <Grid className="challenges-main-grid_item" item xs={12}>
            <Stack
              spacing={2}
              direction="row"
              justifyContent="space-between"
              width="100%"
              className="buttons-container"
              sx={{ marginBottom: 2 }}
            >
              <SecondaryButton
                onClick={() => handleNavigationClick('challenges')}
              >
                {t('common.back')}
              </SecondaryButton>
            </Stack>
            <div className="header">
              <div className="header_number">1</div>
              <div className="header_txt">
                {t('pages.challenge.step1.title')}
              </div>
            </div>
            <Grid container sx={{ marginBottom: 5 }}>
              <Grid item xs={12} sm={4}>
                <strong>{t('pages.challenge.step1.id')}:</strong>{' '}
                {challengeDetails.challengeId}
              </Grid>
              <Grid item xs={12} sm={4}>
                <strong>{t('pages.challenge.step1.period')}:</strong>{' '}
                {challengeDetails.periodId}
              </Grid>
              <Grid item xs={12} sm={4}>
                <strong>{t('pages.challenge.step1.name')}:</strong>{' '}
                {challengeDetails.name}
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item xs={12} sm={4}>
                {(isSuperAdmin(currentUser) || isCentralAdmin(currentUser)) &&
                  companies && (
                    <>
                      <strong>{t('pages.challenge.step1.companies')}:</strong>
                      <List
                        sx={{
                          width: '100%',
                          bgcolor: 'background.paper',
                          position: 'relative',
                          overflow: 'auto',
                          maxHeight: 200,
                          '& ul': { padding: 0 },
                        }}
                      >
                        <ul>
                          {companies.map((company) => (
                            <li>{company.name}</li>
                          ))}
                        </ul>
                      </List>
                    </>
                  )}
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  flexDirection: 'column',
                }}
              >
                <strong>{t('pages.challenge.step1.logo')}:</strong>
                {logo && (
                  <img
                    src={`data:${logo.imageMimeType};base64,${logo.imageContent}`}
                    alt={logo.name}
                    style={{
                      width: '50px',
                      height: '50px',
                      objectFit: 'contain',
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <strong>{t('pages.challenge.step1.availableFrom')}:</strong>
                <br />
                {challengeDetails.availableFrom}
                <br />
                <strong>{t('pages.challenge.step1.availableTo')}</strong>:<br />
                {challengeDetails.availableTo}
                <br />
                <strong>{t('pages.challenge.step1.validFrom')}</strong>:<br />
                {challengeDetails.validFrom}
                <br />
                <strong>{t('pages.challenge.step1.validTo')}</strong>:<br />
                {challengeDetails.validTo}
                <br />
              </Grid>
            </Grid>
          </Grid>
          <Grid className="challenges-main-grid_item" item xs={12}>
            <div className="header">
              <div className="header_number">2</div>
              <div className="header_txt">
                {t('pages.challenge.step2.title')}
              </div>
            </div>
            <Grid container>
              <Grid item md={8}>
                {challengeDetails.description && (
                  <>
                    <strong>{t('pages.challenge.step2.description')}:</strong>{' '}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: challengeDetails.description,
                      }}
                    />
                  </>
                )}
              </Grid>
              <Grid
                item
                md={4}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                }}
              >
                <strong>{t('pages.challenge.step2.image')}:</strong>{' '}
                {image && (
                  <img
                    src={`data:${image.imageMimeType};base64,${image.imageContent}`}
                    alt={image.name}
                    style={{
                      width: '150px',
                      height: '150px',
                      objectFit: 'contain',
                    }}
                  />
                )}
              </Grid>
              <br />
            </Grid>
          </Grid>
          <Grid className="challenges-main-grid_item" item xs={12}>
            <div className="header">
              <div className="header_number">3</div>
              <div className="header_txt">
                {t('pages.challenge.step3.title')}
              </div>
            </div>
            {unit && (
              <Grid container>
                <Grid item sm={12}>
                  <strong>{t('pages.challenge.step4.unit')}:</strong>{' '}
                  {unit.name}
                </Grid>
                <Grid item xs={12}>
                  <strong>{t('pages.challenge.step3.description')}:</strong>
                  <br />
                  {unit.description}
                </Grid>
                {['SM', 'AV'].includes(unit.code) && (
                  <>
                    <Grid item xs={12}>
                      <ul>
                        {challengeDetails.manufacturers.map((manufacturer) => (
                          <li>
                            {manufacturers.map((m) => (
                              <span key={m.id}>
                                {m.id === manufacturer.manufacturerId && (
                                  <span>
                                    <strong>
                                      {t(
                                        'pages.challenge.step3.manufacturers.label',
                                      )}
                                      :{' '}
                                    </strong>
                                    {m.name},{' '}
                                  </span>
                                )}
                              </span>
                            ))}
                            <strong>
                              {t(
                                'pages.challenge.step3.manufacturers.requiredValue',
                              )}
                              :
                            </strong>{' '}
                            {manufacturer.requiredAmount}.
                          </li>
                        ))}
                      </ul>
                    </Grid>
                  </>
                )}
                {['SP', 'QV'].includes(unit.code) && (
                  <>
                    <Grid item xs={12}>
                      <ul>
                        {challengeDetails.products.map((product) => (
                          <li>
                            <strong>
                              {t('pages.challenge.step3.products.ean')}:
                            </strong>{' '}
                            {product.productCode},{' '}
                            <strong>
                              {t('pages.challenge.step3.products.productName')}:
                            </strong>{' '}
                            {product.productName},{' '}
                            <strong>
                              {t(
                                'pages.challenge.step3.products.requiredValue',
                              )}
                              :
                            </strong>{' '}
                            {product.requiredAmount}.
                          </li>
                        ))}
                      </ul>
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <strong>
                    {t('pages.challenge.step3.minimalTotalAmount')}
                  </strong>
                  :{challengeDetails.minimalTotalAmount}
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid className="challenges-main-grid_item" item xs={12}>
            <div className="header">
              <div className="header_number">4</div>
              <div className="header_txt">
                {t('pages.challenge.step4.title')}
              </div>
            </div>
            <Grid container>
              {challengeDetails.amateur && (
                <Grid item sm={12} md={4}>
                  <Grid container>
                    <Grid item sm={12}>
                      <strong>{t(`pages.challenge.step4.amateur`)}:</strong>
                    </Grid>
                    <Grid item sm={12} md={3}>
                      + {t(`pages.challenge.step4.xp`)}:{' '}
                      {challengeDetails.amateurXpPlus}
                    </Grid>
                    <Grid item sm={12} md={3}>
                      + {t(`pages.challenge.step4.vp`)}:{' '}
                      {challengeDetails.amateurVpPlus}
                    </Grid>
                    <Grid item sm={12} md={3}>
                      - {t(`pages.challenge.step4.xp`)}:{' '}
                      {challengeDetails.amateurXpMinus}
                    </Grid>
                    <Grid item sm={12} md={3}>
                      - {t(`pages.challenge.step4.vp`)}:{' '}
                      {challengeDetails.amateurVpMinus}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {challengeDetails.expert && (
                <Grid item sm={12} md={4}>
                  <Grid container>
                    <Grid item sm={12}>
                      <strong>{t(`pages.challenge.step4.expert`)}:</strong>
                    </Grid>
                    <Grid item sm={12} md={3}>
                      + {t(`pages.challenge.step4.xp`)}:{' '}
                      {challengeDetails.expertXpPlus}
                    </Grid>
                    <Grid item sm={12} md={3}>
                      + {t(`pages.challenge.step4.vp`)}:{' '}
                      {challengeDetails.expertVpPlus}
                    </Grid>
                    <Grid item sm={12} md={3}>
                      - {t(`pages.challenge.step4.xp`)}:{' '}
                      {challengeDetails.expertXpMinus}
                    </Grid>
                    <Grid item sm={12} md={3}>
                      - {t(`pages.challenge.step4.vp`)}:{' '}
                      {challengeDetails.expertVpMinus}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {challengeDetails.master && (
                <Grid item sm={12} md={4}>
                  <Grid container>
                    <Grid item sm={12}>
                      <strong>{t(`pages.challenge.step4.master`)}:</strong>
                    </Grid>
                    <Grid item sm={12} md={3}>
                      + {t(`pages.challenge.step4.xp`)}:{' '}
                      {challengeDetails.masterXpPlus}
                    </Grid>
                    <Grid item sm={12} md={3}>
                      + {t(`pages.challenge.step4.vp`)}:{' '}
                      {challengeDetails.masterVpPlus}
                    </Grid>
                    <Grid item sm={12} md={3}>
                      - {t(`pages.challenge.step4.xp`)}:{' '}
                      {challengeDetails.masterXpMinus}
                    </Grid>
                    <Grid item sm={12} md={3}>
                      - {t(`pages.challenge.step4.vp`)}:{' '}
                      {challengeDetails.masterVpMinus}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </ChallengeContainer>
  )
}

export default ChallengeShow
