import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import {
  FormControl,
  Select,
  MenuItem,
  Stack,
  SelectChangeEvent,
} from '@mui/material'
import { Period } from '../../../../store/Period/types'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import { StorePlanResult } from '../../../../store/StorePlan/types'

interface Legend extends StorePlanResult {
  visible: boolean
}

interface StorePlansToolbarProps {
  clearSearch: () => void
  onChange: (event: { target: { value: string } }) => void
  filterPeriod: (event: SelectChangeEvent) => void
  filterIdType: (event: SelectChangeEvent) => void
  togglePlanVisibility: (planId: number) => void
  legend: Legend[]
  value: string
  periodValue: string
  idType: string
  periods: Period[]
}

export default function StorePlansToolbar(props: StorePlansToolbarProps) {
  const { t } = useTranslation()
  const idTypes = [
    { value: 'id', name: t('pages.storePlans.toolbar.idTypes.id') },
    {
      value: 'centralId',
      name: t('pages.storePlans.toolbar.idTypes.centralId'),
    },
    {
      value: 'internalId',
      name: t('pages.storePlans.toolbar.idTypes.internalId'),
    },
  ]

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 15,
        marginTop: 10,
        alignItems: 'flex-end',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormControl size="small" sx={{ marginRight: 1 }}>
          <label>{t('pages.storePlans.toolbar.filters.period')}</label>
          <Select
            id="period-select"
            onChange={props.filterPeriod}
            value={props.periodValue}
            defaultValue={props.periodValue}
          >
            {props.periods.map((period) => (
              <MenuItem value={period.id} key={period.id}>
                {period.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ marginRight: 1 }}>
          <label>{t('pages.storePlans.toolbar.filters.idType')}</label>
          <Select
            id="period-select"
            onChange={props.filterIdType}
            value={props.idType}
            defaultValue={props.idType}
          >
            {idTypes.map((type) => (
              <MenuItem value={type.value} key={type.value}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Stack flexDirection="row" mt={2}>
          {props.legend.length > 0 &&
            props.legend.map((el) => (
              <Stack key={el.id} flexDirection="row" alignItems="center" mr={2}>
                <IconButton
                  onClick={(e) => {
                    localStorage.setItem(
                      `plans-tooltips-${el.id}`,
                      JSON.stringify(!el.visible),
                    )
                    props.togglePlanVisibility(el.id)
                  }}
                  size="small"
                >
                  {el.visible ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
                <div
                  style={{
                    width: '12px',
                    height: '12px',
                    backgroundColor: `${el.colorHexValue}`,
                    borderRadius: 2,
                    marginRight: 5,
                  }}
                />
                {el.name}
              </Stack>
            ))}
        </Stack>
      </div>
      <TextField
        variant="outlined"
        size="small"
        value={props.value}
        onChange={props.onChange}
        placeholder={t('pages.storePlans.table.search')}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  )
}
