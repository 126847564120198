import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAIL,
} from './types'

import AuthService from '../services/auth.service'
import { AppDispatch } from '../store'

export const login =
  (username: string, password: string) => (dispatch: AppDispatch) => {
    return AuthService.login(username, password).then(
      (data) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        })

        return Promise.resolve()
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()

        dispatch({
          type: LOGIN_FAIL,
          payload: null,
        })

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        })

        return Promise.reject()
      },
    )
  }

export const logout = () => (dispatch: AppDispatch) => {
  AuthService.logout()

  dispatch({
    type: LOGOUT,
    payload: null,
  })
}

export const refreshToken =
  (currentToken: string) => (dispatch: AppDispatch) => {
    return AuthService.refreshToken(currentToken).then(
      (data) => {
        dispatch({
          type: REFRESH_TOKEN_SUCCESS,
          payload: { user: data },
        })

        return Promise.resolve()
      },
      () => {
        dispatch({
          type: REFRESH_TOKEN_FAIL,
          payload: null,
        })

        return Promise.reject()
      },
    )
  }
