import axios from 'axios'
import {
  AddUserResponse,
  EditUserResponse,
  UserAddParams,
  UserChangePointsParams,
  UserChangePointsResponse,
  UserDetailsResponse,
  UserEditParams,
  UserImportListReponse,
  UsersResponse,
} from '../store/User/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getUserImportList = (): Promise<UserImportListReponse> => {
  return axios.get(API_URL + '/api/admin/user/import', {
    headers: authHeader(),
  })
}

const getUsers = async (
  companies: { id: number }[],
  isActive: boolean | null,
  userTypeName: string,
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
  validFrom: string | null,
  validTo: string | null,
): Promise<UsersResponse> => {
  return await axios.post(
    API_URL + '/api/admin/user/list',
    {
      companies,
      isActive,
      userTypeName,
      search,
      sortBy,
      sortOrder,
      per,
      page,
      validFrom,
      validTo,
    },
    {
      headers: authHeader(),
    },
  )
}

const addUser = async (params: UserAddParams): Promise<AddUserResponse> => {
  return await axios.post(
    API_URL + '/api/admin/user',
    {
      ...params,
    },
    {
      headers: authHeader(),
    },
  )
}

const editUser = async (params: UserEditParams): Promise<EditUserResponse> => {
  return await axios.post(
    API_URL + '/api/admin/user/edit',
    {
      ...params,
    },
    {
      headers: authHeader(),
    },
  )
}

const getUserDetails = async (userId: number): Promise<UserDetailsResponse> => {
  return await axios.get(API_URL + `/api/admin/user/preview/${userId}`, {
    headers: authHeader(),
  })
}

const userChangePoints = async (
  params: UserChangePointsParams,
): Promise<UserChangePointsResponse> => {
  return await axios.post(
    API_URL + '/api/admin/user/account-operation',
    {
      ...params,
    },
    {
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  getUserImportList,
  getUsers,
  addUser,
  editUser,
  getUserDetails,
  userChangePoints,
}

export default exportedObject
