import React, { FunctionComponent } from 'react'
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import plLocale from 'date-fns/locale/pl'
import { FormControl, FormHelperText, Grid, TextField } from '@mui/material'
import moment from 'moment'
import { FormError } from '../../store/types'

type DateTimePickerProps = {
  label: string
  date: Date | null
  time: Date | null
  onDateChange: (date: Date | null, keyboardInputValue?: string) => void
  onTimeChange: (date: Date | null, keyboardInputValue?: string) => void
  maxDate?: Date
  minDate?: Date
  error?: FormError
  style?: React.CSSProperties
}

const DateTimePicker: FunctionComponent<DateTimePickerProps> = ({
  label,
  date,
  time,
  onDateChange,
  onTimeChange,
  maxDate,
  minDate,
  error,
  style,
}) => {
  if (maxDate) {
    maxDate = moment(maxDate).subtract(1, 'day').toDate()
  }
  if (minDate) {
    minDate = moment(minDate).add(1, 'day').toDate()
  }

  return (
    <FormControl error={error && error.error} style={style}>
      <label>{label}</label>
      <div style={{ display: 'flex' }}>
        <Grid container>
          <Grid item xs={7}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={plLocale}
            >
              <DatePicker
                value={date}
                onChange={onDateChange}
                renderInput={(params) => <TextField {...params} size="small" />}
                maxDate={maxDate}
                minDate={minDate}
                mask="__.__.____"
                inputFormat="dd.MM.yyyy"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={5}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={plLocale}
            >
              <TimePicker
                value={time}
                onChange={onTimeChange}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </div>
      {error && error.error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  )
}

export default DateTimePicker
