import React, { useEffect, useState } from 'react'
import { Alert, Stack, AlertColor } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../../helpers/errorHandler'
import PeriodPlanService from '../../../../../services/userPlan.service'
import * as XLSX from 'xlsx'
import { toast } from 'react-toastify'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'

type PeriodPlanImportPlanStep2Props = {
  plan: string
  company: string
  acceptMessage: {
    type: AlertColor
    message: string
  } | null
  addedResults: { userId: string }[]
  skippedResults: { userId: string }[]
  handleSetAcceptMessage: (acceptMessage: {
    type: AlertColor
    message: string
  }) => void
  handleSetSkippedResults: (skippedResults: { userId: string }[]) => void
  handleSetAddedResults: (addedResults: { userId: string }[]) => void
}

const PeriodPlanImportPlanStep2: React.FunctionComponent<
  PeriodPlanImportPlanStep2Props
> = ({
  plan,
  company,
  acceptMessage,
  addedResults,
  skippedResults,
  handleSetAcceptMessage,
  handleSetSkippedResults,
  handleSetAddedResults,
}) => {
  const { t } = useTranslation()
  const [acceptingImport, setAcceptingImport] = useState<boolean>(false)
  const [isStepVisible, setIsStepVisible] = useState<boolean>(false)

  const downloadXLSX = (data: any, name: string) => {
    const fileName = `${name}.xlsx`
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, {
      skipHeader: true,
    })
    const wb: XLSX.WorkBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, name)

    XLSX.writeFile(wb, fileName)
  }

  const acceptImport = async () => {
    if (plan) {
      try {
        setAcceptingImport(true)
        const importResponse = await PeriodPlanService.importAcceptPlan(
          parseInt(plan),
          company === 'default' ? null : parseInt(company),
        )

        if (importResponse.data.success) {
          handleSetAcceptMessage({
            type: 'success',
            message: t('pages.userPlanImportPlan.messages.afterAcceptMessage'),
          })
        } else {
          toast.error(t('messages.error.generalError'))
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setAcceptingImport(false)
        handleSetSkippedResults([])
        handleSetAddedResults([])
      }
    }
  }

  useEffect(() => {
    setIsStepVisible(true)
  }, [acceptMessage])

  useEffect(() => {
    setIsStepVisible(false)
  }, [plan, company])

  return (
    <>
      {isStepVisible && (
        <>
          {acceptMessage && (
            <Alert
              severity={acceptMessage.type}
              sx={{ my: 2 }}
              style={{ whiteSpace: 'pre-line' }}
            >
              {acceptMessage.message}
              {skippedResults.length > 0 && (
                <SecondaryButton
                  onClick={() => downloadXLSX(skippedResults, 'pominiete')}
                  size="small"
                  style={{ display: 'block', marginTop: '10px' }}
                >
                  {t('pages.userPlanImportPlan.step2.downloadSkippedResults')}
                </SecondaryButton>
              )}
            </Alert>
          )}

          <Stack
            className="buttons-container"
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            width="100%"
          >
            {acceptMessage && addedResults.length > 0 && (
              <PrimaryButton onClick={acceptImport} disabled={acceptingImport}>
                {t('common.confirm')}
              </PrimaryButton>
            )}
          </Stack>
        </>
      )}
    </>
  )
}

export default PeriodPlanImportPlanStep2
