import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Typography,
  Grid,
  IconButton,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { errorHandler } from '../../../../helpers/errorHandler'
import UserProfileImage from '../../../shared/UserProfileImage'
import ClearIcon from '@mui/icons-material/Clear'
import UserService from '../../../../services/user.service'
import { UserDetails } from '../../../../store/User/types'
import { green, red } from '@mui/material/colors'

type UserAddDialogProps = {
  userId: number
  open: boolean
  handleClose: (refresh: boolean) => void
  type: string
}

const UserAddDialog: React.FunctionComponent<UserAddDialogProps> = ({
  userId,
  open,
  handleClose,
  type,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const userDetailsResponse = await UserService.getUserDetails(userId)
        setUserDetails(userDetailsResponse.data)
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (open) {
      fetchData()
    }
  }, [open, t, userId])

  const renderUserDetailsRow = (
    label: string,
    value: JSX.Element | JSX.Element[] | string,
  ) => {
    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={4} textAlign="right">
          <Typography
            variant="body2"
            gutterBottom
            fontWeight="bold"
            style={{ wordBreak: 'break-all' }}
          >
            {label}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2" gutterBottom>
            {value}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleClose(false)}
    >
      {loading && <LoadingSpinner />}
      {!loading && userDetails && (
        <>
          <DialogTitle style={{ height: '90px' }}>
            <Stack flexDirection="row">
              <UserProfileImage
                userId={userId}
                firstname={userDetails.firstname}
                lastname={userDetails.lastname}
                style={{
                  width: 50,
                  height: 50,
                }}
              />
              <Stack justifyContent="center" style={{ marginLeft: '15px' }}>
                <Typography variant="caption">
                  {t('pages.users.userDetailsDialog.userId')}: {userId}
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  component="div"
                >
                  {userDetails.firstname} {userDetails.lastname}
                </Typography>
              </Stack>
              <IconButton
                style={{ marginLeft: 'auto', height: '50px', width: '50px' }}
                onClick={() => handleClose(false)}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {renderUserDetailsRow(
                t('pages.users.userDetailsDialog.userCentralId'),
                userDetails.userCentralId,
              )}
              {renderUserDetailsRow(
                t('pages.users.userDetailsDialog.userInternalId'),
                userDetails.userInternalId,
              )}
              {renderUserDetailsRow(
                t('pages.users.userDetailsDialog.firstname'),
                userDetails.firstname,
              )}
              {renderUserDetailsRow(
                t('pages.users.userDetailsDialog.lastname'),
                userDetails.lastname,
              )}
              {renderUserDetailsRow(
                t('pages.users.userDetailsDialog.username'),
                userDetails.username,
              )}
              {renderUserDetailsRow(
                t('pages.users.userDetailsDialog.phoneNumber'),
                userDetails.phoneNumber ? userDetails.phoneNumber : '-',
              )}
              {renderUserDetailsRow(
                t('pages.users.userDetailsDialog.bankAccount'),
                userDetails.bankAccount ? userDetails.bankAccount : '-',
              )}
              {renderUserDetailsRow(
                t('pages.users.userDetailsDialog.companyName'),
                userDetails.companyName,
              )}
              {renderUserDetailsRow(
                t('pages.users.userDetailsDialog.regionName'),
                userDetails.regionName,
              )}
              {renderUserDetailsRow(
                t('pages.users.userDetailsDialog.isActive'),
                userDetails.isActive ? (
                  <span style={{ color: green[500] }}>
                    {t('pages.users.status.active')}
                  </span>
                ) : (
                  <span style={{ color: red[500] }}>
                    {t('pages.users.status.inactive')}
                  </span>
                ),
              )}
              {renderUserDetailsRow(
                t('pages.users.userDetailsDialog.lastLoginDate'),
                userDetails.lastLoginDate ? userDetails.lastLoginDate : '-',
              )}
              {renderUserDetailsRow(
                t('pages.users.userDetailsDialog.chatBoxAllowed'),
                userDetails.chatBoxAllowed ? (
                  <span style={{ color: green[500] }}>{t('common.yes')}</span>
                ) : (
                  <span style={{ color: red[500] }}>{t('common.no')}</span>
                ),
              )}
              {renderUserDetailsRow(
                t('pages.users.userDetailsDialog.eduBoxAllowed'),
                userDetails.eduBoxAllowed ? (
                  <span style={{ color: green[500] }}>{t('common.yes')}</span>
                ) : (
                  <span style={{ color: red[500] }}>{t('common.no')}</span>
                ),
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack flexDirection="row" justifyContent="flex-end" width="100%">
              <SecondaryButton onClick={() => handleClose(false)}>
                {t('common.close')}
              </SecondaryButton>
            </Stack>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default UserAddDialog
