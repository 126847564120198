import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import plTranslations from './pl-PL.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      pl: {
        translation: plTranslations,
      },
    },
    lng: 'pl', // if you're using a language detector, do not define the lng option
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
