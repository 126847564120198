import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Typography,
  FormControl,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  FormHelperText,
  CircularProgress,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { errorHandler } from '../../../../helpers/errorHandler'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { FormError } from '../../../../store/types'
import { UserEditErrors, UserEditParams } from '../../../../store/User/types'
import UserService from '../../../../services/user.service'
import { validateEmail } from '../../../../helpers/utils'
import { toast } from 'react-toastify'
import theme from '../../../../theme'

type UserEditDialogProps = {
  userId: number
  open: boolean
  handleClose: (refresh: boolean) => void
  type: string
}

const UserEditDialog: React.FunctionComponent<UserEditDialogProps> = ({
  userId,
  open,
  handleClose,
  type,
}) => {
  const passwordRegex: RegExp = /^(?=.*\d)(?=.*[a-z])(?=.*)[0-9A-Za-z]{8,15}$/g
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [saving, setSaving] = useState<boolean>(false)
  const [username, setUsername] = useState<string>('')
  const [firstname, setFirstname] = useState<string>('')
  const [lastname, setLastname] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null)
  const [chatBoxAllowed, setChatBoxAllowed] = useState<boolean>(false)
  const [eduBoxAllowed, setEduBoxAllowed] = useState<boolean>(false)
  const [password, setPassword] = useState<string | null>(null)
  const [passwordVerify, setPasswordVerify] = useState<string | null>(null)

  // errors
  const defaultError: FormError = {
    error: false,
    message: '',
  }
  const defaultFormErrors: UserEditErrors = {
    username: defaultError,
    firstname: defaultError,
    lastname: defaultError,
    phoneNumber: defaultError,
    chatBoxAllowed: defaultError,
    eduBoxAllowed: defaultError,
    password: defaultError,
    passwordVerify: defaultError,
  }
  const [formErrors, setFormErrors] =
    useState<UserEditErrors>(defaultFormErrors)

  const handleFirstnameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFirstname(event.target.value)
  }

  const handleLastnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastname(event.target.value)
  }

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value)
  }

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPhoneNumber(event.target.value)
  }

  const handleEduBoxAllowedChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEduBoxAllowed(event.target.checked)
  }

  const handleChatBoxAllowedChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setChatBoxAllowed(event.target.checked)
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const handlePasswordVerifyChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPasswordVerify(event.target.value)
  }

  const resetForm = () => {
    setFormErrors(defaultFormErrors)
    setFirstname('')
    setLastname('')
    setUsername('')
    setPhoneNumber('')
    setEduBoxAllowed(false)
    setChatBoxAllowed(false)
    setPassword(null)
    setPasswordVerify(null)
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const userDetailsResponse = await UserService.getUserDetails(userId)

        setFirstname(userDetailsResponse.data.firstname)
        setLastname(userDetailsResponse.data.lastname)
        setUsername(userDetailsResponse.data.username)
        setPhoneNumber(userDetailsResponse.data.phoneNumber)
        setEduBoxAllowed(userDetailsResponse.data.eduBoxAllowed)
        setChatBoxAllowed(userDetailsResponse.data.chatBoxAllowed)
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (open) {
      fetchData()
    }
  }, [open, t, userId])

  const onSave = async () => {
    setFormErrors(defaultFormErrors)
    const step1Errors = defaultFormErrors

    if (firstname.trim() === '') {
      setFormErrors({
        ...step1Errors,
        firstname: {
          error: true,
          message: t('pages.users.userEditDialog.errors.firstnameRequired'),
        },
      })
      return
    } else if (lastname.trim() === '') {
      setFormErrors({
        ...step1Errors,
        lastname: {
          error: true,
          message: t('pages.users.userEditDialog.errors.lastnameRequired'),
        },
      })
      return
    } else if (username.trim() === '') {
      setFormErrors({
        ...step1Errors,
        username: {
          error: true,
          message: t('pages.users.userEditDialog.errors.usernameRequired'),
        },
      })
      return
    } else if (!validateEmail(username)) {
      setFormErrors({
        ...step1Errors,
        username: {
          error: true,
          message: t('pages.users.userEditDialog.errors.usernameInvalid'),
        },
      })
      return
    } else if (
      password &&
      password?.trim() !== '' &&
      password !== passwordVerify
    ) {
      setFormErrors({
        ...step1Errors,
        password: {
          error: true,
          message: t('pages.users.userEditDialog.errors.passwordMatch'),
        },
        passwordVerify: {
          error: true,
          message: t('pages.users.userEditDialog.errors.passwordMatch'),
        },
      })
      return
    } else if (
      password &&
      password?.trim() !== '' &&
      !password.match(passwordRegex)
    ) {
      setFormErrors({
        ...step1Errors,
        password: {
          error: true,
          message: t('pages.users.userEditDialog.errors.passwordInvalid'),
        },
        passwordVerify: {
          error: true,
          message: t('pages.users.userEditDialog.errors.passwordInvalid'),
        },
      })
      return
    }

    const formParams: UserEditParams = {
      userId,
      username,
      firstname,
      lastname,
      phoneNumber,
      chatBoxAllowed,
      eduBoxAllowed,
      password,
    }

    try {
      setSaving(true)
      const addUserResponse = await UserService.editUser(formParams)
      if (addUserResponse.data.success) {
        toast.success(t('messages.success.savedSuccessfully'))
        resetForm()

        handleClose(true)
      } else {
        toast.error(t('messages.error.generalError'))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setSaving(false)
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return
        handleClose(false)
      }}
    >
      <DialogTitle>
        <Typography variant="body1" fontWeight="bold">
          {t('pages.users.userEditDialog.title', {
            userTypeName: type,
          })}
        </Typography>
      </DialogTitle>
      {loading && <LoadingSpinner />}
      {!loading && (
        <DialogContent>
          <DialogContentText>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>{t('pages.users.userEditDialog.firstname')} *</label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={firstname}
                    onChange={handleFirstnameChange}
                    error={formErrors.firstname.error}
                    helperText={
                      formErrors.firstname.error && formErrors.firstname.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>{t('pages.users.userEditDialog.lastname')} *</label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={lastname}
                    onChange={handleLastnameChange}
                    error={formErrors.lastname.error}
                    helperText={
                      formErrors.lastname.error && formErrors.lastname.message
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>{t('pages.users.userEditDialog.username')} *</label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={username}
                    onChange={handleUsernameChange}
                    error={formErrors.username.error}
                    helperText={
                      formErrors.username.error && formErrors.username.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>{t('pages.users.userEditDialog.phoneNumber')}</label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    error={formErrors.phoneNumber.error}
                    helperText={
                      formErrors.phoneNumber.error &&
                      formErrors.phoneNumber.message
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={eduBoxAllowed}
                    onChange={handleEduBoxAllowedChange}
                  />
                }
                label={t('pages.users.userEditDialog.eduBoxAllowed')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={chatBoxAllowed}
                    onChange={handleChatBoxAllowedChange}
                  />
                }
                label={t('pages.users.userEditDialog.chatBoxAllowed')}
              />
            </FormGroup>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>{t('pages.users.userEditDialog.password')}</label>
                  <TextField
                    variant="outlined"
                    type="password"
                    fullWidth
                    size="small"
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                    value={password}
                    onChange={handlePasswordChange}
                    error={formErrors.password.error}
                    helperText={
                      formErrors.password.error && formErrors.password.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>
                    {t('pages.users.userEditDialog.passwordVerify')}
                  </label>
                  <TextField
                    variant="outlined"
                    type="password"
                    fullWidth
                    size="small"
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                    value={passwordVerify}
                    onChange={handlePasswordVerifyChange}
                    error={formErrors.password.error}
                    helperText={
                      formErrors.password.error && formErrors.password.message
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={12} alignSelf="flex-end" mb={1}>
                <FormHelperText id="component-error-text">
                  {t('pages.users.userEditDialog.passwordInfo')}
                </FormHelperText>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Stack flexDirection="row" justifyContent="space-between" width="100%">
          <SecondaryButton
            onClick={() => {
              resetForm()
              handleClose(false)
            }}
          >
            {t('common.cancel')}
          </SecondaryButton>
          <PrimaryButton onClick={onSave} disabled={saving || loading}>
            {t('common.save')}
            {saving && (
              <CircularProgress
                size={18}
                style={{
                  marginLeft: '1rem',
                  color: theme.buttons.primary.preloaderColor,
                }}
              />
            )}
          </PrimaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default UserEditDialog
