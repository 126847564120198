import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Typography,
  FormControl,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { errorHandler } from '../../../../helpers/errorHandler'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { FormError } from '../../../../store/types'
import {
  PeriodDetails,
  PeriodEditErrors,
  PeriodEditParams,
} from '../../../../store/Period/types'
import PeriodService from '../../../../services/period.service'
import DateTimePicker from '../../../shared/DateTimePicker'
import { toast } from 'react-toastify'
import moment from 'moment'

type PeriodEditDialogProps = {
  periodId: number
  open: boolean
  handleClose: (refresh: boolean) => void
}

const PeriodEditDialog: React.FunctionComponent<PeriodEditDialogProps> = ({
  periodId,
  open,
  handleClose,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [saving, setSaving] = useState<boolean>(false)
  const [periodDetails, setPeriodDetails] = useState<PeriodDetails | null>(null)
  const [name, setName] = useState<string>('')
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [validFromDate, setValidFromDate] = useState<Date | null>(null)
  const [validFromTime, setValidFromTime] = useState<Date | null>(
    new Date(new Date().setHours(0, 0, 0, 0)),
  )
  const [validToDate, setValidToDate] = useState<Date | null>(null)
  const [validToTime, setValidToTime] = useState<Date | null>(
    new Date(new Date().setHours(23, 59, 59, 0)),
  )

  // errors
  const defaultError: FormError = {
    error: false,
    message: '',
  }
  const defaultFormErrors: PeriodEditErrors = {
    name: defaultError,
    isVisible: defaultError,
    validFromDate: defaultError,
    validFromTime: defaultError,
    validToDate: defaultError,
    validToTime: defaultError,
  }
  const [formErrors, setFormErrors] =
    useState<PeriodEditErrors>(defaultFormErrors)

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleIsVisibleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsVisible(event.target.checked)
  }

  const resetForm = () => {
    setFormErrors(defaultFormErrors)
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const periodDetailsResponse = await PeriodService.getPeriodDetails(
          periodId,
        )
        setPeriodDetails(periodDetailsResponse.data)
        setName(periodDetailsResponse.data.name)
        setValidFromDate(
          new Date(periodDetailsResponse.data.validFrom.slice(0, 10)),
        )
        setValidFromTime(
          new Date(
            new Date().setHours(
              parseInt(periodDetailsResponse.data.validFrom.slice(11, 13)),
              parseInt(periodDetailsResponse.data.validFrom.slice(14, 16)),
            ),
          ),
        )
        setValidToDate(
          new Date(periodDetailsResponse.data.validTo.slice(0, 10)),
        )
        setValidToTime(
          new Date(
            new Date().setHours(
              parseInt(periodDetailsResponse.data.validTo.slice(11, 13)),
              parseInt(periodDetailsResponse.data.validTo.slice(14, 16)),
            ),
          ),
        )
        setIsVisible(periodDetailsResponse.data.isVisible)
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (open) fetchData()
  }, [open, t, periodId])

  const onSave = async () => {
    setFormErrors(defaultFormErrors)
    const periodEditErrors = defaultFormErrors

    if (name.trim() === '') {
      setFormErrors({
        ...periodEditErrors,
        name: {
          error: true,
          message: t('pages.periods.periodEditDialog.errors.name'),
        },
      })
      return
    } else if (name.trim().length > 20) {
      setFormErrors({
        ...periodEditErrors,
        name: {
          error: true,
          message: t('pages.periods.periodEditDialog.errors.nameLimit'),
        },
      })
      return
    } else if (
      !validFromDate ||
      !validFromTime ||
      validFromDate.toString() === 'Invalid Date' ||
      validFromTime.toString() === 'Invalid Date'
    ) {
      setFormErrors({
        ...periodEditErrors,
        validFromDate: {
          error: true,
          message: t('pages.periods.periodEditDialog.errors.validFromRequired'),
        },
      })
      return
    } else if (
      !validToDate ||
      !validToTime ||
      validToDate.toString() === 'Invalid Date' ||
      validToTime.toString() === 'Invalid Date'
    ) {
      setFormErrors({
        ...periodEditErrors,
        validToDate: {
          error: true,
          message: t('pages.periods.periodEditDialog.errors.validToRequired'),
        },
      })
      return
    }

    const validFrom =
      moment(validFromDate).format('YYYY-MM-DD') +
      ' ' +
      moment(validFromTime).format('HH:mm') +
      ':00'

    const validTo =
      moment(validToDate).format('YYYY-MM-DD') +
      ' ' +
      moment(validToTime).format('HH:mm') +
      ':59'

    const formParams: PeriodEditParams = {
      periodId,
      name,
      validFrom,
      validTo,
      isVisible,
    }
    try {
      setSaving(true)

      const editPeriodResponse = await PeriodService.editPeriod(formParams)

      if (editPeriodResponse.data.success) {
        toast.success(t('messages.success.savedSuccessfully'))
        resetForm()
        handleClose(true)
      } else {
        toast.error(t('messages.error.generalError'))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setSaving(false)
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return
        handleClose(false)
      }}
    >
      <DialogTitle>
        <Typography component={'span'} variant="body1" fontWeight="bold">
          {t('pages.periods.periodEditDialog.title')}
        </Typography>
      </DialogTitle>
      {loading && <LoadingSpinner />}
      {!loading && (
        <DialogContent>
          <DialogContentText>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth margin="dense">
                  <label>{t('pages.periods.periodEditDialog.name')} *</label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={name}
                    onChange={handleNameChange}
                    error={formErrors.name.error}
                    helperText={
                      formErrors.name.error && formErrors.name.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}></Grid>
            </Grid>
            <Grid container columnSpacing={2}>
              <Grid item xs={6} md={6}>
                <DateTimePicker
                  label={t('pages.periods.periodEditDialog.validFrom')}
                  date={validFromDate}
                  time={validFromTime}
                  onDateChange={setValidFromDate}
                  onTimeChange={setValidFromTime}
                  maxDate={validToDate || undefined}
                  error={formErrors.validFromDate}
                  style={{
                    width: 260,
                    marginRight: 8,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <DateTimePicker
                  label={t('pages.periods.periodEditDialog.validTo')}
                  date={validToDate}
                  time={validToTime}
                  onDateChange={setValidToDate}
                  onTimeChange={setValidToTime}
                  minDate={validFromDate || undefined}
                  error={formErrors.validToDate}
                  style={{
                    width: 260,
                    marginRight: 8,
                  }}
                />
              </Grid>
            </Grid>
            {periodDetails?.isActivated && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isVisible}
                      onChange={handleIsVisibleChange}
                    />
                  }
                  label={t('pages.periods.periodEditDialog.isVisible')}
                />
              </FormGroup>
            )}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Stack flexDirection="row" justifyContent="space-between" width="100%">
          <SecondaryButton
            onClick={() => {
              resetForm()
              handleClose(false)
            }}
          >
            {t('common.cancel')}
          </SecondaryButton>
          <PrimaryButton onClick={onSave} disabled={saving || loading}>
            {t('common.save')}
          </PrimaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default PeriodEditDialog
