import jwtDecode from 'jwt-decode'
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAIL,
} from '../actions/types'
import { User } from '../store/Auth/types'

const userStorage = localStorage.getItem('user')
const user: User | null = userStorage ? JSON.parse(userStorage) : null

const initialState = user
  ? {
      isLoggedIn: jwtDecode<any>(user.token).exp * 1000 > Date.now(),
      user: {
        ...user,
        roles: jwtDecode<any>(user.token).roles,
        exp: jwtDecode<any>(user.token).exp,
      },
    }
  : { isLoggedIn: false, user: null }

export default function auth(
  state = initialState,
  action: { type: any; payload: any },
) {
  const { type, payload } = action

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: {
          ...payload.user,
          roles: jwtDecode<any>(payload.user.token).roles,
          exp: jwtDecode<any>(payload.user.token).exp,
        },
      }
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      }
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      }
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        user: {
          ...payload.user,
          roles: jwtDecode<any>(payload.user.token).roles,
          exp: jwtDecode<any>(payload.user.token).exp,
        },
      }
    case REFRESH_TOKEN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      }
    default:
      return state
  }
}
