import React, { useEffect, useRef, useState } from 'react'
import { Alert, AlertColor, Box, Collapse, Stack } from '@mui/material'
import ChallengeService from '../../../../../services/challenge.service'
import { ChallengeLevels } from '../../../../../store/Challenge/types'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../../helpers/errorHandler'
import { toast } from 'react-toastify'

import * as XLSX from 'xlsx'
import {
  ChallengeLevelImport,
  ChallengeLevelsData,
  ChallengeLevelsImportParams,
} from '../../../../../store/Challenge/types'
import LoadingSpinner from '../../../../shared/LoadingSpinner'
import { omit } from 'lodash'
const { read, utils } = XLSX

const Input = styled('input')({
  display: 'none',
})

const StyledLabel = styled('label')(({ theme }) => ({
  color: theme.colorsPalette.gray.gray6,
  fontWeight: 'bold',
  fontSize: '.75rem',
  display: 'block',
  marginBottom: '0.5em',
}))

type ChallengeImportFormProps = {
  challengeId: number | null
  challengeLevels: ChallengeLevels
  isImportPossible: boolean
  isStep1Touched: boolean
  isStep4Touched: boolean
}

const ChallengeImportForm: React.FunctionComponent<
  ChallengeImportFormProps
> = ({
  challengeId,
  challengeLevels,
  isImportPossible,
  isStep1Touched,
  isStep4Touched,
}) => {
  const { t } = useTranslation()
  const [loadingImport, setLoadingImport] = useState<boolean>(false)
  const [savingImport, setSavingImport] = useState<boolean>(false)
  const [userChallengeLevelsImport, setChallengeLevelsImport] =
    useState<ChallengeLevelsImportParams | null>(null)
  const [helperImportData, setHelperImportData] = useState<
    Partial<ChallengeLevelImport>[]
  >([])

  const [dataObj, setDataObj] = useState<any[]>([])
  const [alertOpen, setAlertOpen] = useState<boolean>(true)
  const [alertData, setAlertData] = useState({
    type: 'error' as AlertColor,
    message: '',
  })

  const uploadInputRef = useRef<HTMLInputElement>(null)

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    if (e && e.target && e.target.files && e.target.files.length > 0) {
      var files = e.target.files,
        f = files[0]
      var reader = new FileReader()
      reader.onload = function (e) {
        if (e && e.target) {
          var data = e.target.result
          let readedData = read(data, { type: 'binary' })
          const wsname = readedData.SheetNames[0]
          const ws = readedData.Sheets[wsname]

          /* Convert array to json*/
          const dataParse = utils.sheet_to_json<any>(ws, {
            blankrows: false,
            defval: null,
          })
          setDataObj(dataParse)
        }
      }
      reader.readAsBinaryString(f)
      e.target.value = ''
    }
  }

  const exportToExcel = () => {
    const fileName = `${t('pages.challenge.import.exampleFilename')}.xlsx`

    const translatedHeaders = {
      userId: t('excel.challengeImport.userId'),
      userCentralId: t('excel.challengeImport.userCentralId'),
      userInternalId: t('excel.challengeImport.userInternalId'),
      username: t('excel.challengeImport.username'),
      firstname: t('excel.challengeImport.firstname'),
      lastname: t('excel.challengeImport.lastname'),
      companyName: t('excel.challengeImport.companyName'),
      regionName: t('excel.challengeImport.regionName'),
      stores: t('excel.challengeImport.stores'),
      planTotalValue: t('excel.challengeImport.planTotalValue'),
      amateur: t('excel.challengeImport.amateur'),
      expert: t('excel.challengeImport.expert'),
      master: t('excel.challengeImport.master'),
    }

    const headers = [
      Object.keys(helperImportData[0]).map(
        (key) => (translatedHeaders as any)[key],
      ),
    ]

    //Had to create a new workbook and then add the header
    const ws: XLSX.WorkSheet = XLSX.utils.book_new()
    XLSX.utils.sheet_add_aoa(ws, headers)

    //Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(ws, helperImportData, {
      origin: 'A2',
      skipHeader: true,
    })

    const wb: XLSX.WorkBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      t('pages.challenge.import.exampleFilename'),
    )

    XLSX.writeFile(wb, fileName)
  }

  const saveImport = async () => {
    if (userChallengeLevelsImport) {
      try {
        setSavingImport(true)
        const importResponse = await ChallengeService.importUserChallengeLevels(
          userChallengeLevelsImport,
        )

        if (importResponse.data.success) {
          toast.success(
            t('messages.success.challengeLevelsImportSuccessful', {
              added: importResponse.data.addedUsers?.length,
              skipped: importResponse.data.skippedUsers?.length,
            }),
          )
        } else {
          toast.error(t('messages.error.generalError'))
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setChallengeLevelsImport(null)
        setAlertOpen(false)
        setSavingImport(false)
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (challengeId) {
          setLoadingImport(true)
          const importTemplateResponse =
            await ChallengeService.getChallengeImportTemplate(challengeId)

          if (importTemplateResponse.data.users) {
            const formattedData = importTemplateResponse.data.users.map(
              (user) => {
                return {
                  userId: user.userId,
                  userCentralId: user.userCentralId,
                  userInternalId: user.userInternalId,
                  username: user.username,
                  firstname: user.firstname,
                  lastname: user.lastname,
                  companyName: user.companyName,
                  regionName: user.regionName,
                  stores: user.stores,
                  planTotalValue: user.planTotalValue,
                  amateur: null,
                  expert: null,
                  master: null,
                }
              },
            )
            const levelsToRemove: string[] = []
            if (!challengeLevels.amateur) {
              levelsToRemove.push('amateur')
            }
            if (!challengeLevels.expert) {
              levelsToRemove.push('expert')
            }
            if (!challengeLevels.master) {
              levelsToRemove.push('master')
            }

            setHelperImportData(
              formattedData.map((d) => omit(d, levelsToRemove)),
            )
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoadingImport(false)
      }
    }
    if (challengeId) {
      fetchData()
    }
  }, [t, challengeId, challengeLevels])

  useEffect(() => {
    let userChallengeLevelsData: ChallengeLevelsData[] = []
    var BreakException = {}
    let fileError = {
      type: 'error' as AlertColor,
      message: '',
    }

    setAlertOpen(false)
    setAlertData(fileError)
    setChallengeLevelsImport(null)

    try {
      dataObj.forEach((element, i) => {
        if (!element.hasOwnProperty('sys_ID')) {
          fileError = {
            type: 'error',
            message: t('pages.challenge.import.messages.columnMissing', {
              column: 'sys_ID',
              line: i + 1,
            }),
          }
          throw BreakException
        } else {
          userChallengeLevelsData.push({
            userId: element['sys_ID'],
            amateur: challengeLevels.amateur
              ? element[t('excel.challengeImport.amateur')]
              : null,
            expert: challengeLevels.expert
              ? element[t('excel.challengeImport.expert')]
              : null,
            master: challengeLevels.master
              ? element[t('excel.challengeImport.master')]
              : null,
          })
        }
      })
    } catch (e) {
      if (e !== BreakException) {
        throw e
      } else {
        userChallengeLevelsData = []
        setAlertOpen(true)
        setAlertData(fileError)
      }
    } finally {
      if (userChallengeLevelsData.length > 0 && challengeId) {
        setAlertOpen(true)
        setAlertData({
          type: 'info' as AlertColor,
          message: t('pages.challenge.import.messages.fileReadyToImport', {
            length: userChallengeLevelsData.length,
          }),
        })
        setChallengeLevelsImport({
          challengeId,
          data: userChallengeLevelsData,
        })
      }
    }
  }, [dataObj, challengeId, t, challengeLevels])

  return (
    <>
      {loadingImport && <LoadingSpinner />}
      {!loadingImport && (
        <div>
          {!isImportPossible && (
            <Alert
              severity="info"
              sx={{ mt: 2 }}
              style={{ whiteSpace: 'pre-line' }}
            >
              {t('pages.challenge.import.messages.completeStep4Form')}
            </Alert>
          )}
          {isStep1Touched && isImportPossible && (
            <Alert
              severity="warning"
              sx={{ mt: 2 }}
              style={{ whiteSpace: 'pre-line' }}
            >
              {t('pages.challenge.import.messages.unsavedStep1Form')}
            </Alert>
          )}
          {isStep4Touched && isImportPossible && (
            <Alert
              severity="warning"
              sx={{ mt: 2 }}
              style={{ whiteSpace: 'pre-line' }}
            >
              {t('pages.challenge.import.messages.unsavedStep4Form')}
            </Alert>
          )}
          {isImportPossible && !isStep1Touched && !isStep4Touched && (
            <>
              <Box my={2}>
                <StyledLabel>{t('pages.challenge.import.title')}</StyledLabel>
                <PrimaryButton
                  onClick={exportToExcel}
                  size="small"
                  disabled={helperImportData.length === 0}
                >
                  {t('pages.challenge.import.downloadExampleXLSX')}
                </PrimaryButton>
              </Box>
              <Input
                ref={uploadInputRef}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
                id="contained-button-file"
                multiple
                type="file"
                onChange={onFileChange}
                value={undefined}
              />
              <label htmlFor="contained-button-file">
                <SecondaryButton
                  onClick={() => {
                    if (challengeId) {
                      uploadInputRef.current && uploadInputRef.current.click()
                    }
                  }}
                >
                  {t('pages.challenge.import.selectFile')}
                </SecondaryButton>
              </label>
              <Collapse in={alertOpen}>
                <Alert
                  severity={alertData.type}
                  sx={{ mt: 2 }}
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {alertData.message}
                </Alert>
              </Collapse>
              {userChallengeLevelsImport && (
                <Stack
                  className="buttons-container"
                  spacing={2}
                  direction="row"
                  justifyContent="flex-end"
                  width="100%"
                >
                  <PrimaryButton
                    onClick={saveImport}
                    disabled={savingImport || !challengeId}
                  >
                    {t('common.import')}
                  </PrimaryButton>
                </Stack>
              )}
            </>
          )}
        </div>
      )}
    </>
  )
}

export default ChallengeImportForm
