import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../helpers/errorHandler'
import ImageService from '../../services/image.service'
import { UserImage } from '../../store/Image/types'
import { Avatar, CircularProgress } from '@mui/material'

type UserProfileImageProps = {
  userId: number
  firstname: string
  lastname: string
  style: React.CSSProperties
}

const UserProfileImage: React.FunctionComponent<UserProfileImageProps> = ({
  userId,
  firstname,
  lastname,
  style,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [profileImage, setProfileImage] = useState<UserImage | null>(null)

  function stringAvatar(name: string) {
    return {
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userProfileImageResponse = await ImageService.getUserProfileImage(
          userId,
        )
        if (userProfileImageResponse.data.imageContent) {
          setProfileImage(userProfileImageResponse.data)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t, userId])

  return (
    <>
      {loading && (
        <Avatar sx={style}>
          <CircularProgress />
        </Avatar>
      )}
      {!loading && profileImage && (
        <Avatar
          src={`data:${profileImage.imageMimeType};base64,${profileImage.imageContent}`}
          sx={style}
        />
      )}
      {!loading && !profileImage && (
        <Avatar
          {...stringAvatar(`${firstname.trim()} ${lastname.trim()}`)}
          sx={style}
        />
      )}
    </>
  )
}

export default UserProfileImage
